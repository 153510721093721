import BaseModel from './BaseModel';
import Crop from './Crop';
import Config from '../config';

export enum PictureType {
  LogoImage = 'logoImage',
  CoverImage = 'coverImage'
}

export enum PictureSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Original = 'original'
}

export enum PictureStatus {
  Added = 'added',
  Active = 'active',
  Banned = 'banned',
  Disabled = 'disabled',
  Processing = 'processing'
}

const defaultValues = {
  creatorId: undefined,
  mimeType: undefined,
  type: PictureType.CoverImage,
  filename: undefined,
  preview: undefined,
  crop: undefined,
  status: undefined,
  file: undefined
};

export default class Picture extends BaseModel(defaultValues)<Picture> {
  creatorId: number;
  mimeType: string;
  filename: string;
  preview: string;
  status: PictureStatus;
  type: PictureType;
  crop: Crop;
  file: File;

  constructor(js?) {
    super(js);
  }

  fromJS(js): Picture {

    let picture = new Picture(js);

    if (js.crop) {
      picture = picture.setCrop(js.crop);
    }

    return picture;
  }

  fromFile(file) {
    return new Picture({type: this.type, mimeType: file.type, filename: file.name, file});
  }

  setFile(file) {
    let picture = this.set('file', file);

    if (file) {
      picture = picture
        .set('mimeType', file.type)
        .set('filename', file.name);
    }

    return picture as Picture;
  }

  setCrop(crop): Picture {
    return this.set('crop', new Crop(crop)) as Picture;
  }

  setPreviewImage(preview): Picture {
    return this.set('preview', preview) as Picture;
  }

  getPictureURL(size?: string) {

    if (this.type && this.id) {

      return `${Config.API_URL}/picture/${this.type}/${this.id}${size ? '?size=' + size : ''}`;

    }
  }
}
