import * as React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import ConfirmButton from '../ConfirmButton/ConfirmButton';

import './RoundButton.less';

interface Props {
  onClick?: (event?: any) => void;
  type?: 'primary' | 'success' | 'clear';
  shape?: 'round' | 'circle';
  size?: 'normal' | 'small';
  active?: boolean;
  iconSrc?: string;
  className?: string;
  disabled?: boolean;
  iconPosition?: 'left' | 'right' | 'center';
  hideTextOnMobile?: boolean;
  hideIconOnWeb?: boolean;
  tooltip?: string;
  children?;
  confirm?: boolean;
}

const RoundButton: React.FunctionComponent<Props> = (props) => {

  const {disabled, iconSrc, hideIconOnWeb, iconPosition,
    className, children, type, size, active,
    onClick, hideTextOnMobile, shape, tooltip, confirm} = props;

  const getButtonTypeClassName = () => {
    switch (type) {
      case 'primary':
        return 'round-button--primary';
      case 'success':
        return 'round-button--success';
      default:
        return 'round-button--clear';
    }
  };

  const getButtonSizeClassName = () => {
    switch (size) {
      case 'small':
        return 'round-button--small';
      case 'normal':
        return 'round-button--normal';
      default:
        return 'round-button--normal';
    }
  };

  const getButtonShapeClassName = () => {

    if (shape === 'circle') {
      return 'round-button--circle';
    }

    if (hideTextOnMobile && iconSrc) {

      if (iconSrc) {
        return 'round-button--dynamic';
      }
    }

    return 'round-button--round';
  };

  const getTextStateClassName = () => {

    if (hideTextOnMobile && iconSrc) {

      if (iconSrc) {
        return 'round-button__text--hidden';
      }
    }
  };

  const getIconStateClassName = () => {
    if(hideIconOnWeb && iconSrc) {
      return 'round-button__icon--hidden';
    }
    if (iconPosition === 'right') {
      return 'round-button__icon--right';
    } else if (iconPosition === 'left') {
      return 'round-button__icon--left';
    }
  };

  const renderImage = () => {

    return (iconSrc
      ? <img className={
        classnames('round-button__icon',
          getIconStateClassName())}
      src={iconSrc}
      alt='Button Icon'
      />
      : null
    );
  };

  const renderChildren = () => {

    return (children
      ? <div className={
        classnames('round-button__text',
          getTextStateClassName())} >
        {children}
      </div>
      : null
    );
  };

  const renderTooltip = () => {

    return tooltip && <ReactTooltip/>;
  };

  const classNameList = classnames(
    'round-button',
    {'round-button--active': active},
    getButtonTypeClassName(),
    getButtonShapeClassName(),
    getButtonSizeClassName(),
    className
  );

  if (confirm) {

    return (
      <ConfirmButton
        onClick={onClick}
        disabled={disabled}
        className={classNameList}
      >
        {renderImage()}
        {renderChildren()}
        {renderTooltip()}
      </ConfirmButton>
    );
  }

  return (
    <button
      type='button'
      onClick={onClick}
      disabled={disabled}
      data-tip={tooltip}
      className={classNameList}
    >
      {renderImage()}
      {renderChildren()}
      {renderTooltip()}
    </button>
  );
};

RoundButton.defaultProps = {
  type: 'primary',
  disabled: false,
  iconPosition: 'center',
  hideTextOnMobile: true,
  hideIconOnWeb: false,
  shape: 'round',
  size: 'normal'
};

export default React.memo(RoundButton);
