import * as _ from 'lodash';
import ModelModule from './ModelModule';
import QuestionnaireAnswer from '../models/QuestionnaireAnswer';
import {bind, destructUrl} from '../util';
import {add} from './ActionsCommon';
import {createAction} from 'redux-actions';
import {stringify, parse} from 'querystring';
import {navigate} from './Location';

const initialState = new QuestionnaireAnswer();

export class QuestionnaireAnswerModule extends ModelModule<QuestionnaireAnswer> {
  private sendContactDetailsRequestType: string;
  private sendContactDetailsSuccessType: string;
  private sendContactDetailsFailedType: string;
  private sendContactDetailsRequestAction: any;
  private sendContactDetailsSuccessAction: any;
  private sendContactDetailsFailedAction: any;

  private checkEligibilityRequestType: string;
  private checkEligibilitySuccessType: string;
  private checkEligibilityFailedType: string;
  private checkEligibilityRequestAction: any;
  private checkEligibilitySuccessAction: any;
  private checkEligibilityFailedAction: any;

  constructor() {
    super('questionnaireAnswer', initialState, 'questionnaireAnswer');
    bind(this, this.resetQuestionnaireAnswer, this.sendContactDetails, this.checkEligibilityForStudy);
  }

  sendContactDetails(answer, contact) {
    return dispatch => {
      dispatch(this.sendContactDetailsRequestAction());

      return dispatch(add('questionnaire', 'submit-contact', null, {answer, contact}))
        .then(() => dispatch(this.sendContactDetailsSuccessAction()))
        .catch(error => dispatch(this.handleError(this.sendContactDetailsFailedAction, error)));
    };
  }

  checkEligibilityForStudy(questionnaireAnswer: QuestionnaireAnswer)  {
    return dispatch => {
      dispatch(this.checkEligibilityRequestAction());

      const payload = {
        questionnaire: {
          rules: questionnaireAnswer.questionnaire.getRules().toArray()
        },
        answers: questionnaireAnswer.answers.toArray()
      };

      return dispatch(add('questionnaire', 'check', null, payload))
        .then(results => {
          dispatch(this.checkEligibilitySuccessAction({questionnaireAnswer, results}));
        })
        .catch(error => {
          dispatch(this.handleError(this.checkEligibilityFailedAction, error));
        });
    };
  }

  resetQuestionnaireAnswer(questionnaire) {
    return (dispatch, getState) => {
      const personId = getState().authenticatedUser.id;

      const payload = {
        questionnaireId: questionnaire ? questionnaire.id : undefined,
        questionnaire,
        personId
      };

      dispatch(this.resetModelAction(payload));
    };
  }

  navigateToPage(page: number) {
    return (dispatch, getState) => {

      const {location: {currentPage}} = getState();

      const {searchParam, pathName} = destructUrl(currentPage);

      const query = parse(searchParam);
      const updatedQuery = stringify(_.merge({}, query, {page}));

      dispatch(navigate(`${pathName}?${updatedQuery}`));
    };
  }

  protected initializeTypes() {
    super.initializeTypes();

    this.sendContactDetailsRequestType = `${this.name}.SEND_CONTACT_DETAILS_REQUEST`;
    this.sendContactDetailsSuccessType = `${this.name}.SEND_CONTACT_DETAILS_SUCCESS`;
    this.sendContactDetailsFailedType = `${this.name}.SEND_CONTACT_DETAILS_FAILED`;

    this.checkEligibilityRequestType = `${this.name}.CHECK_ELIGIBILITY_REQUEST`;
    this.checkEligibilitySuccessType = `${this.name}.CHECK_ELIGIBILITY_SUCCESS`;
    this.checkEligibilityFailedType = `${this.name}.CHECK_ELIGIBILITY_FAILED`;
  }

  protected initializeActions() {
    super.initializeActions();

    this.sendContactDetailsRequestAction = createAction(this.sendContactDetailsRequestType);
    this.sendContactDetailsSuccessAction = createAction(this.sendContactDetailsSuccessType);
    this.sendContactDetailsFailedAction = createAction(this.sendContactDetailsFailedType);

    this.checkEligibilityRequestAction = createAction(this.checkEligibilityRequestType);
    this.checkEligibilitySuccessAction = createAction(this.checkEligibilitySuccessType);
    this.checkEligibilityFailedAction = createAction(this.checkEligibilityFailedType);
  }

  /**
   * @override
   */
  protected getAdditionalActionHandlers() {
    return {
      [this.sendContactDetailsRequestType]: state => state.startOfLoading(),
      [this.sendContactDetailsSuccessType]: state => state.setContactSent().endOfLoading(),
      [this.sendContactDetailsFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.checkEligibilityRequestType]: state => state.startOfLoading(),
      [this.checkEligibilitySuccessType]: (state, {payload}) => {
        return state.fromJS(payload.questionnaireAnswer).setEligibilityResults(payload.results).endOfLoading();
      },
      [this.checkEligibilityFailedType]: (state , {payload}) => state.endOfLoading(payload),
      [this.resetModelType]: (_state, {payload}) => new QuestionnaireAnswer(payload)
    };
  }
}

export default new QuestionnaireAnswerModule();
