import * as React from 'react';
import {Component} from 'react';

import {connect} from 'react-redux';
import AuthenticatedUser from '../../../modules/AuthenticatedUser';
import User from '../../../models/User';

interface Props {
  user: User;
  logout: () => any;
}

interface State {}

class LogoutView extends Component<Props, State> {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <div />;
  }
}

const mapActionCreators = {
  logout: AuthenticatedUser.logout
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(
  mapStateToProps,
  mapActionCreators
)(LogoutView);
