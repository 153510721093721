import * as React from 'react';
import {PureComponent} from 'react';
import StudySiteResults from './StudySiteResults';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {navigate} from '../../../modules/Location';
import StudySitesModule from '../../../modules/StudySites';
import StudySiteModule from '../../../modules/StudySite';
import Criteria from '../../../models/Criteria';
import StudySitesModel from '../../../models/StudySites';
import {WithNamespaces, withNamespaces} from 'react-i18next';
import {PaginationContext} from '../../../models/Pagination';
import User from '../../../models/User';

import './StudySites.less';

interface Props extends WithNamespaces {
  user: User;
  studySites: StudySitesModel;
  criteria: Criteria;
  location: string;
  updateCriteria: (location, criteria) => any;
  getStudySites: (queryParams?, reset?) => any;
  deleteStudySite: (model) => any;
  navigate: (url: string, silent?: boolean) => any;
  pagination: any;
}

interface State {}

export class StudySites extends PureComponent<Props, State> {

  componentDidMount() {

    const {getStudySites, criteria} = this.props;
    getStudySites(criteria.getQueryParams());
  }

  onCriteriaChange = criteria => {

    const {updateCriteria, location} = this.props;
    updateCriteria(location, criteria);
  };

  loadMore = () => {
    const {getStudySites, studySites, criteria, pagination} = this.props;
    if (!studySites.isLoading && pagination.hasMore) {
      getStudySites(criteria.getQueryParams());
    }
  };

  render() {

    const {user, criteria, studySites, deleteStudySite, navigate, t, pagination} = this.props;

    return (
      <div key='study-sites-view-key' className='study-sites-view'>
        <h1 className='view-title__list'>
          {t('studySitesView.title')}
        </h1>
        <StudySiteResults
          user={user}
          ref='results'
          studySites={studySites.list}
          criteria={criteria}
          deleteModel={deleteStudySite}
          onCriteriaChange={this.onCriteriaChange}
          navigate={navigate}
          hasMore={pagination.hasMore}
          loadMore={this.loadMore}
          loading={studySites.isLoading}
          t={t}
        />
      </div>
    );
  }
}

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '');

  return hasParams
    ? Criteria.fromQuery(query)
    : Criteria.getInitialCriteriaWithoutDateRange();
};

const mapStateToProps = ({studySites, authenticatedUser, pagination}, ownProps) => {
  const criteria = getCriteria(ownProps.location);

  return {
    user: authenticatedUser,
    criteria,
    studySites,
    pagination: pagination.getPagination(PaginationContext.STUDY_SITE)
  };
};

const mapActionToProps = {
  updateCriteria: StudySitesModule.updateCriteria,
  getStudySites: StudySitesModule.getModels,
  deleteStudySite: StudySiteModule.deleteModel,
  navigate
};

export default withNamespaces(['admin'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(StudySites));
