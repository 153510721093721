import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import {WithNamespaces, withNamespaces} from 'react-i18next';
import StudySiteView from '../../../components/StudySiteView';

interface Props extends WithNamespaces {
  studySiteId: number;
  duplicate: boolean;
}

interface State {}

class StudySite extends Component<Props, State> {

  render() {

    return <StudySiteView key='study-site-view-key' {...this.props}/>;
  }
}

const mapStateToProps = (_state, ownProps) => {

  const {location: {query: {duplicate, id}}, params: {id: sid}} = ownProps;
  const studySiteId = sid ? parseInt(sid, 10) : id ? parseInt(id, 10) : undefined;

  return {
    studySiteId,
    duplicate
  };
};

const mapActionToProps = {};

export default withNamespaces(['admin'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(StudySite));
