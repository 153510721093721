/**
 * Created by Mauritz Untamala on 22/09/15.
 */
import * as React from 'react';
import {PureComponent} from 'react';
import {WithNamespaces, withNamespaces} from 'react-i18next';

import {connect} from 'react-redux';
import UserModule from '../../../modules/User';
import User from '../../../models/User';

import UserView from '../../../components/UserView';

interface Props extends WithNamespaces {
  user: User;

  getModel: (id, queryParams?) => any;
  saveModel: (model, pathComponents?, queryParams?) => any;
  resetModel: () => any;
  navigate: (url: string, silent?: boolean) => any;
}

interface State {
}

export class UserCreate extends PureComponent<Props, State> {

  render() {
    const {user, getModel, saveModel, resetModel, t} = this.props;

    return (
      <div>
        <UserView
          key='user-view'
          newUser={true}
          user={user}
          minimalDetails={true}
          clearAfterSave={true}
          saveModel={saveModel}
          getModel={getModel}
          resetModel={resetModel}
          t={t}
        />
      </div>
    );
  }
}

const mapActionCreators = {
  saveModel: UserModule.saveModel,
  getModel: UserModule.getModel,
  resetModel: UserModule.resetModel
};

const mapStateToProps = ({user}, _ownProps) => {

  return {
    user
  };
};

export default withNamespaces(['admin'], {wait: true})(connect(
  mapStateToProps,
  mapActionCreators
)(UserCreate));
