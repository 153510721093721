import BaseModel from './BaseModel';

const constraints = {
  studyId: {
    presence: true
  },
  siteNumber: {
    presence: true
  },
  siteName: {
    presence: true
  },
  address: {
    presence: false
  },
  info: {
    presence: false
  },
  email: {
    presence: true,
    email: true
  }
};

const interestedKeys = [
  'siteNumber',
  'siteName',
  'address',
  'info',
  'email'
];

const defaultValues = {
  studyId: undefined,
  studyNumber: undefined,
  studyName: undefined,
  siteNumber: undefined,
  siteName: undefined,
  address: undefined,
  info: undefined,
  email: undefined
};

export default class StudySite extends BaseModel(defaultValues, interestedKeys, constraints)<StudySite> {
  studyId: number;
  studyNumber: string;
  studyName: string;
  siteNumber: string;
  siteName: string;
  address: string;
  info: string;
  email: string;

  fromJS(js): StudySite {

    return new StudySite(js);
  }
}
