/**
 * Created by Mauritz Untamala on 10/12/15.
 */
import * as React from 'react';
import {withRouter} from 'react-router';
import ModelView, {BaseProps, BaseState} from './ModelView';
import SaveButton from './SaveButton';
import StudyDetails from './StudyDetail/StudyDetails';
import {PaginationContext} from '../models/Pagination';
import {List} from 'immutable';
import {connect} from 'react-redux';
import {navigate} from '../modules/Location';
import StudyModule from '../modules/Study';
import UsersModule from '../modules/Users';
import Study from '../models/Study';
import Users from '../models/Users';

import {UserRole} from '../models/UserRole';

const applicableRoles = UserRole.getValues();

interface Props extends BaseProps<Study> {
  availableLanguages: List<any>;
  users: Users;
  pagination: any;
  getUsers: (role?, reset?, studyIds?) => any;
}

interface State extends BaseState<Study> {
  showError: boolean;
}

class StudyView extends ModelView<Study, Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,
      showError: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    const {getUsers} = this.props;

    getUsers(applicableRoles, true);
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    const {model, showError} = this.state;

    if (showError && model.isValid()) {
      this.setState({showError: false});
    }
  }

  onModelChange = (model) => {
    this.updateModel(model);
  };

  onSaveStudy = () => {
    this.setState({showError: true});
    this.save();
  };

  loadMore = () => {
    const {getUsers, users, pagination} = this.props;
    if (!users.isLoading && pagination.hasMore) {
      getUsers(applicableRoles, false);
    }
  };

  getContent() {
    const model = this.state.model;
    const {t, users, availableLanguages, pagination} = this.props;
    const {showError} = this.state;

    return (
      <>
        <StudyDetails
          key='study-details'
          study={model}
          users={users}
          availableLanguages={availableLanguages}
          onChange={this.onModelChange}
          showError={showError}
          hasMore={pagination.hasMore}
          loadMore={this.loadMore}
          t={t}
        />
        <SaveButton
          key='save-button'
          model={model}
          save={this.onSaveStudy}
          onModelSaved={this.onModelSaved}
          t={t}
        />
      </>
    );
  }
}

const mapActionCreators = {
  getUsers: UsersModule.getUsers,
  getModel: StudyModule.getModel,
  saveModel: StudyModule.saveStudy,
  resetModel: StudyModule.resetModel,
  navigate
};

const mapStateToProps = (
  {users, study, studies, picture, app: {availableFeatures, availableLanguages}, pagination}
  , ownProps) => {

  const studyId = parseInt(ownProps.studyId, 10);

  const resolveModel = () => {

    if (study.id === studyId || !studyId) {
      return study;
    }

    const model = studies.getModelById(studyId);

    return model ? model : new Study();
  };

  return {
    users,
    model: resolveModel(),
    picture,
    availableFeatures,
    availableLanguages,
    pagination: pagination.getPagination(PaginationContext.USER)
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {

  const path = '/admin/studies';

  const additionalProps = {
    navigateBackOnSaved: true,
    backHref: path,
    editUrl: path,
    modelId: ownProps.studyId,
    modelName: 'study',
    confirmUnsavedEntries: true
  };

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps);
};

export default withRouter(connect(
  mapStateToProps,
  mapActionCreators,
  mergeProps
)(StudyView));
