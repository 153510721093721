import * as React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './SliderInput.less';

interface Props {
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  dots?: boolean;
  onChange?: any;
  defaultValue?: number;
  value?: number;
}

const SliderInput: React.FunctionComponent<Props> = (props) => {

  const {
    min, max, defaultValue, step, value,
    onChange, disabled, dots
  } = props;

  const handle = (props) => {

    const Handle = Slider.Handle;

    const {value, ...restProps} = props;

    return (
      <>
        <Handle className='slider-input__handle' value={value} {...restProps}>
          <span className='slider-input__value'>{value}</span>
        </Handle>
      </>
    );
  };

  return (
    <div>
      <Slider
        className='slider-input'
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        disabled={disabled}
        dots={dots}
        handle={handle}
      />
    </div>
  );
};

export default React.memo(SliderInput);
