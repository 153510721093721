/**
 * Created by Mauritz Untamala on 27/09/15.
 */
import * as React from 'react';
import {Component} from 'react';

interface Props {
  data: string;
  t?: (key, params?) => any;
}

const invitationSent = require('../images/round-invitation-sent.svg');
const invitationNotSent = require('../images/round-invitation-notsent.svg');
const invitationAccepted = require('../images/round-active.svg');
const invitationNotAccepted = require('../images/round-inactive.svg');


export default class StatusColumn extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <view className='actions-wrapper'>
        <img className='status-icon' src={this.props.data === 'active' ? invitationSent : invitationNotSent}/>
        <img src={this.props.data === 'active' ? invitationAccepted : invitationNotAccepted}/>
      </view>);
  }
}
