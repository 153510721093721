import AppLayout from '../layouts/AppLayout';
import AdminRoute from './Admin';
import SubjectRoute from './Subject';
import Error from './Error';
import CookiePolicy from './CookiePolicy';

export const createRoutes = (store) => {
  return {
    path: '/',
    childRoutes: [
      {
        component: AppLayout,
        indexRoute: {
          onEnter: (_nextState, replace) => {
            const user = store.getState().authenticatedUser;

            if (user.authenticated) {
              return replace('/admin/start');
            }
          }
        }
      },
      AdminRoute,
      SubjectRoute,
      {path: '/study/:id/privacy-policy', component: CookiePolicy},
      {path: 'error/:id', component: Error}
    ]
  };
};

export default createRoutes;
