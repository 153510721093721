import ModelsModule from './ModelsModule';
import {PaginationContext} from '../models/Pagination';
import Studies from '../models/Studies';
import * as _ from 'lodash';
import Study from './Study';
import {bind} from '../util';

const initialState = new Studies();

export class StudiesModule extends ModelsModule {
  constructor() {
    super('study', initialState, PaginationContext.STUDY);
    bind(this, this.fetchStudies);
  }

  public fetchStudies(ids: number[]) {
    let queryParams;

    if (!_.isEmpty(ids)) {
      queryParams = {};

      const result = ids.join(',');

      if (result !== '') {
        queryParams.studyId = result;
      }
    }

    return this.getModels(queryParams, true);
  }

  protected getAdditionalActionHandlers() {
    return {
      [Study.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Study.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Study.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    };
  }
}

export default new StudiesModule();
