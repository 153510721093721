import * as React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';
import {Component} from 'react';
import Question from '../models/Question';
import QuestionAnswer from '../models/QuestionAnswer';
import QuestionnaireRule from '../models/QuestionnaireRule';
import QuestionComponent, {Type} from '../models/QuestionComponent';
import Datetime from './Question/Datetime';
import Number from './Question/Number';
import Text from './Question/Text';
import DropDown from './Question/DropDown';
import Select from './Question/Select';
import Instruction from './Question/Instruction';
import MultiSelect from './Question/MultiSelect';
import FormRulesNumber from './Question/FormRulesNumber';
import {getFieldError} from '../util';
import Bmi from './Question/Bmi';
import FormRulesBmi from './Question/FormRulesBmi';

export const getError = error => {
  if (error) {
    return <p className='error-text'>{error}</p>;
  }
};

export interface ComponentProps {
  isPersonnel: boolean;
  component: any;
  fieldError: any;
  disabled: boolean;
  answer: QuestionAnswer;
  onChange: (answer) => any;
  language: string;
  t: (key, args?) => any;
}

export interface ComponentState {
}

export enum QuestionContext {
  Normal,
  Rule
}

interface Props {
  isPersonnel: boolean;
  showError: boolean;
  question: Question;
  questionContext: QuestionContext;
  from: string;
  answer: QuestionAnswer;
  onChange: (questionAnswer) => any;
  language: string;
  t: (key, args?) => any;
}

interface State {
  errors: any;
}

export default class QuestionView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {errors: props.answer.validateWithQuestion(props.question)};
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {

    const {isPersonnel, questionContext, showError, question, answer, language} = this.props;

    return isPersonnel !== nextProps.isPersonnel
      || questionContext !== nextProps.questionContext
      || showError !== nextProps.showError
      || question !== nextProps.question
      || answer !== nextProps.answer
      || language !== nextProps.language
      || this.state !== nextState;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.answer !== this.props.answer) {
      this.setState({errors: this.props.answer.validateWithQuestion(this.props.question)});
    }
  }

  isRuleContext = () => {
    const {questionContext} = this.props;

    return questionContext === QuestionContext.Rule;
  };

  renderComponentType = (component: QuestionComponent) => {
    const {answer, showError, from, ...rest} = this.props;
    const componentType = component.getType();
    const ruleContext = this.isRuleContext();
    const disabled = ruleContext && !QuestionnaireRule.getSupportedComponentTypes().find(c => c === componentType);
    const field = component.getField();
    const errors = this.state.errors;
    const value = answer.getFieldValue(field);
    const fieldError = showError && getFieldError(field, errors, answer.error, value);
    const adjustedProps = _.merge(
      {
        fieldError,
        answer,
        field
      },
      rest
    );

    switch (componentType) {
      case Type.datetime:
        return (<Datetime
          key={field}
          component={component}
          disabled={disabled}
          {...adjustedProps}
        />);

      case Type.number:
      case Type.slider:
        if(from === 'formRules') {
          return (<FormRulesNumber
            key={field}
            component={component}
            disabled={disabled}
            {...adjustedProps}
          />);
        }
        return (<Number
          key={field}
          component={component}
          disabled={disabled}
          {...adjustedProps}
        />);
      case Type.text:
        return (<Text
          key={field}
          component={component}
          disabled={disabled}
          {...adjustedProps}
        />);

      case Type.textarea:
        return (<Text
          key={field}
          componentClass='textarea'
          component={component}
          disabled={disabled}
          {...adjustedProps}
        />);

      case Type.select:
        return (<Select
          key={field}
          component={component}
          disabled={disabled}
          resettable={ruleContext}
          allowMultiple={ruleContext}
          {...adjustedProps}
        />);

      case Type.dropdown:
        return (<DropDown
          key={field}
          component={component}
          disabled={disabled}
          {...adjustedProps}
        />);

      case Type.instruction:
        return (<Instruction
          key={field}
          component={component}
          disabled={disabled}
          {...adjustedProps}
        />);

      case Type.multiselect:
        return (<MultiSelect
          key={field}
          component={component}
          disabled={disabled}
          {...adjustedProps}
        />);
      case Type.bmi:
        if(from === 'formRules') {
          return (<FormRulesBmi
            key={field}
            component={component}
            disabled={disabled}
            {...adjustedProps}
          />);
        }
        return (<Bmi
          key={field}
          component={component}
          disabled={disabled}
          {...adjustedProps}
        />);
      default:
        return <div>Unknown</div>;
    }
  };

  renderTitle = () => {

    const {language} = this.props;

    return (
      <h3 className='question-view__title'>{this.props.question.getTitle(language)}</h3>
    );
  };

  renderComponents = () => {
    const {question} = this.props;

    return question.components.map(this.renderComponentType);
  };

  isInstruction = () => {
    const {question} = this.props;

    return question.components.every(c => c.getType() === Type.instruction);
  };

  getError = () => {
    const {showError, answer} = this.props;
    if (this.isInstruction()) {
      return null;
    }

    const {errors} = this.state;
    const error = getFieldError('value', errors, answer.error);
    if (error && showError) {
      return getError(error);
    }
  };

  showDisabled = () => {
    const {question: {components}} = this.props;

    const ruleSupportedComponentTypes = QuestionnaireRule.getSupportedComponentTypes();

    return this.isRuleContext()
      && components.every(component => !ruleSupportedComponentTypes.find(c => c === component.getType()));
  };

  render() {
    const error = this.getError();
    const showDisabled = this.showDisabled();
    const classes = classNames({
      'question-view': true,
      'question-disabled': showDisabled,
      'error-context': !!error,
      'has-error': !!error
    });

    return (
      <div className={classes}>
        {this.renderTitle()}
        {this.renderComponents()}
        {error}
      </div>
    );
  }
}
