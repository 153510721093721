import * as React from 'react';
import * as _ from 'lodash';
import {Component} from 'react';
import {Form} from 'react-bootstrap';
import CheckInput from '../CheckInput/CheckInput';
import {ComponentProps, ComponentState, getError} from '../Question';
import {fromJS, List} from 'immutable';

const compactObject = object => _.pickBy(object, _.negate(val => _.isNull(val) || val === ''));
const compactRecord = record => fromJS(compactObject(record.toJS()));

interface MultiSelectProps extends ComponentProps {
}

interface MultiSelectState extends ComponentState {
}

export default class MultiSelect extends Component<MultiSelectProps, MultiSelectState> {
  render() {
    const {component, answer, fieldError, disabled, language} = this.props;
    const {field, options} = component;
    const value = answer.getFieldValue(field);

    const renderOptions = () => {
      if (_.isEmpty(options)) {
        return null;
      }

      const localization = component.getLanguageLocalization(language);

      const onChange = (option, event) => {
        let updatedValue = null;

        if (event.target.checked) {
          if (value) {
            if (!value.includes(option)) {
              updatedValue = answer.value.set(field, value.push(option));
            }
          } else {
            updatedValue = answer.value.set(field, List.of(option));
          }
        } else {
          if (value.includes(option)) {
            updatedValue = answer.value.set(field, value.delete(value.indexOf(option)));
          }
        }

        if (updatedValue) {
          this.props.onChange(answer.set('value', compactRecord(updatedValue)));
        }
      };

      return options.map(option => {

        const checkInputId = option + component._id;

        return (
          <CheckInput
            type='checkbox'
            key={option}
            disabled={disabled}
            id={checkInputId}
            value={option}
            checked={value ? value.includes(option) : false}
            onChange={event => onChange(option, event)}
            hasError={!!fieldError}
            label={localization[option]}
          />
        );
      });
    };

    return (
      <Form.Group className='multi-select-input'>
        {renderOptions()}
        {getError(fieldError)}
      </Form.Group>
    );
  }
}