import * as React from 'react';
import {PureComponent} from 'react';
import Questionnaire from '../../../models/Questionnaire';
import Study from '../../../models/Study';
import Select from 'react-select';
import * as _ from 'lodash';
import TranslationInput from './TranslationInput';
import Toggle from 'react-toggle';
import {LANGUAGE_STATUS} from '../../../config/constants';

interface Props {
  updateModel: (questionnaire) => any;
  t: (key, params?) => any;
  model: Questionnaire;
  study: Study;
}

interface State {
  options: any[];
  selectedOptions: any[];
  selectedTranslation: any;
}

const colourStyles = {
  multiValue: (styles, {data, isDisabled}) => {

    const backgroundColorByStatus = data.isValid
      ? data.isPublished ? '#ccf6c5' : '#fff8ba'
      : '#f6cfc5';

    return {
      ...styles,
      backgroundColor: (isDisabled || (data.isDefault && data.isValid) || _.isNil(data.isValid))
        ? styles.backgroundColor
        : backgroundColorByStatus
    };
  },
  multiValueRemove: (base, state) => {
    return state.data.isDefault ? {...base, display: 'none'} : base;
  }
};

export default class QuestionnaireTranslations extends PureComponent<Props, State> {

  constructor(props) {
    super(props);

    const options = this.getOptions();
    const firstLanguage = props.model.getAdditionalLanguages().first();
    const selectedTranslation = firstLanguage ? options.find(o => o.value === firstLanguage.language) : undefined;
    const selectedOptions = this.getSelectedOptions(options);

    this.state = {
      options,
      selectedOptions,
      selectedTranslation
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {study, model} = this.props;
    let {options, selectedTranslation} = this.state;

    if (study.languages !== prevProps.study.languages
      || model.getDefaultLanguageCode() !== prevProps.model.getDefaultLanguageCode()) {

      options = this.getOptions();

      this.setState({options});
    }

    if (model !== prevProps.model || options !== prevState.options) {

      const selectedOptions = this.getSelectedOptions(options);

      this.setState({selectedOptions});
    }

    const additionalLanguages = model.getAdditionalLanguages();

    if (!additionalLanguages.equals(prevProps.model.getAdditionalLanguages()) || options !== prevState.options) {

      if (selectedTranslation && !additionalLanguages.some(l => l.language === selectedTranslation.value)
        || !selectedTranslation) {

        const firstLanguage = additionalLanguages.first();
        selectedTranslation = firstLanguage ? options.find(o => o.value === firstLanguage.language) : undefined;

        this.setState({selectedTranslation});
      }
    }
  }

  getOptions = () => {

    const {t, study, model} = this.props;
    const defaultLanguageCode = model.getDefaultLanguageCode();

    return study.languages
      .map(language => {

        const isDefault = language === defaultLanguageCode;

        return {
          value: language,
          label: t(`language.${language}`),
          isDefault
        };
      })
      .toArray();
  };

  getSelectedOptions = (options) => {

    const {model} = this.props;
    const questionnaireLanguages = model.getLanguages().toArray();
    const selectedOptions = options
      .filter(option => !!questionnaireLanguages.find(l => l.language === option.value))
      .map(option => {

        const isValid = !model.validateByLanguage(option.value);
        const isPublished = questionnaireLanguages.find(l => l.language === option.value).isPublished();

        return {
          ...option,
          isValid,
          isPublished
        };
      });

    return selectedOptions
      .filter(o => o.isDefault)
      .concat(selectedOptions.filter(o => !o.isDefault));
  };

  onQuestionnaireLanguagesChange = (value, {action, removedValue}) => {

    const {selectedTranslation} = this.state;

    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isDefault) {
          return;
        }
        break;
      case 'clear':
        value = this.state.options.filter((v) => v.isDefault);
        break;
      default:
        break;
    }

    const {model, updateModel} = this.props;
    const languages = (value || []).map(option => option.value);

    if (selectedTranslation && !languages.some(lang => selectedTranslation.value === lang)) {
      this.setState({selectedTranslation: undefined});
    }

    updateModel(model.setAdditionalLanguages(languages));
  };

  renderQuestionnaireLanguages = () => {

    const {options, selectedOptions} = this.state;

    return (
      <Select
        key='questionnaire-languages'
        name='languages'
        isMulti={true}
        isClearable={selectedOptions.some(v => !v.isDefault)}
        options={options}
        value={selectedOptions}
        onChange={this.onQuestionnaireLanguagesChange}
        styles={colourStyles}
      />
    );
  };

  onTranslationLanguageChange = (selectedTranslation) => this.setState({selectedTranslation});

  renderSelectTranslationLanguage = () => {

    const {t} = this.props;
    const {options, selectedTranslation} = this.state;
    const additionalOptions = options.filter(o => !o.isDefault);

    if (additionalOptions.length === 1 && selectedTranslation) {

      return <div>{t(`language.${selectedTranslation.value}`)}</div>;
    }

    return (
      <Select
        key='translation-language'
        name='translation-language'
        className='translation-language-select'
        isMulti={false}
        isClearable={false}
        options={additionalOptions}
        value={selectedTranslation}
        onChange={this.onTranslationLanguageChange}
      />
    );
  };

  renderTranslationLanguageStatistics = () => {

    const {t, model} = this.props;
    const {selectedTranslation} = this.state;
    const count = model.getLanguageTranslations(selectedTranslation.value).filter(t => !!t.value).length;
    const expected = model.getLanguageTranslations(model.getDefaultLanguageCode()).filter(t => !!t.value).length;

    return (
      <div>{t('questionnaire.translationStatistics', {count, expected})}</div>
    );
  };

  renderTogglePublishLanguage = () => {

    const {t, model, updateModel} = this.props;
    const {selectedTranslation} = this.state;
    const language = model.getLanguage(selectedTranslation.value);

    const onChange = (event) => {
      updateModel(model.updateLanguage(
        language.setStatus(event.target.checked ? LANGUAGE_STATUS.PUBLISHED : LANGUAGE_STATUS.DRAFT
        )
      ));
    };

    return (
      <div className='toggle-publish-language'>
        <label className='form-label' htmlFor='toggle_publish'>
          {t('questionnaire.publishedLanguage')}
        </label>
        <Toggle
          id='toggle_publish'
          key={'toggle_publish'}
          defaultChecked={language.isPublished()}
          onChange={onChange}
        />
      </div>
    );
  };

  renderAdditionalLanguageColumnHeader = () => {

    const {selectedTranslation} = this.state;

    if (!selectedTranslation) {
      return;
    }

    return (
      <div className='translations-column additional-language'>
        {this.renderSelectTranslationLanguage()}
        {this.renderTogglePublishLanguage()}
        {this.renderTranslationLanguageStatistics()}
      </div>
    );
  };

  renderTranslationInput = (language, field, page, order, localizationKey, value, tabIndex, key, showError = false) => {

    const onChange = (updatedValue) => {

      const {model, updateModel} = this.props;
      let updatedModel;

      if (_.isNil(page)) {
        updatedModel = model.setField(field, updatedValue, language);
      } else {

        let question = model.getQuestion(page, order);

        if (field === 'title') {
          question = question.setField(field, updatedValue, language);
        } else {
          const component = question.getComponent(field);
          const localization = component.getLocalization();

          if (localizationKey) {

            if(localization[language]) {

              localization[language][localizationKey] = updatedValue;
            } else {

              localization[language] = {[localizationKey]: updatedValue};
            }
          } else {
            localization[language] = updatedValue;
          }

          question = question.updateComponent(component.setLocalization(localization));
        }

        updatedModel = model.updateQuestion(question);
      }

      updateModel(updatedModel);
    };

    return (
      <TranslationInput key={key} tabIndex={tabIndex} value={value} showError={showError} onChange={onChange}/>
    );
  };

  renderTranslationRows = () => {

    const {model} = this.props;
    const {selectedTranslation} = this.state;
    const defaultLanguageCode = model.getDefaultLanguageCode();
    const defaultLanguageTranslations = model.getLanguageTranslations(defaultLanguageCode).filter(l => l.value);

    if (!selectedTranslation) {

      return (
        defaultLanguageTranslations.map((translationDetail, index) => {

          const {field, page, order, localizationKey, value} = translationDetail;

          return (
            <div key={`translations-row-${index}`} className='translations-row'>
              {
                this.renderTranslationInput(
                  defaultLanguageCode,
                  field,
                  page,
                  order,
                  localizationKey,
                  value,
                  0,
                  'default' + index
                )
              }
            </div>
          );
        })
      );
    }

    const selectedLanguage = selectedTranslation.value;
    const selectedLanguageTranslations = model.getLanguageTranslations(selectedLanguage);
    const rowCount = defaultLanguageTranslations.length;
    const showErrors = model.getLanguage(selectedLanguage).isPublished();

    return (
      defaultLanguageTranslations.map((translationDetail, index) => {

        const {field, page, order, localizationKey, value} = translationDetail;
        const selectedLanguageTranslationDetail = selectedLanguageTranslations
          .find(l => l.field === field
            && l.page === page
            && l.order === order
            && l.localizationKey === localizationKey);

        return (
          <div key={`translations-row-${index}`} className='translations-row'>
            {
              this.renderTranslationInput(
                defaultLanguageCode,
                field,
                page,
                order,
                localizationKey,
                value,
                index + 1,
                'default' + index
              )
            }
            {
              this.renderTranslationInput(
                selectedLanguage,
                field,
                page,
                order,
                localizationKey,
                selectedLanguageTranslationDetail && selectedLanguageTranslationDetail.value,
                rowCount + index + 1,
                'selected' + index,
                showErrors
              )
            }
          </div>
        );
      })
    );
  };

  renderTranslations = () => {

    const {t, model} = this.props;
    const defaultLanguageCode = model.getDefaultLanguageCode();

    if (!defaultLanguageCode) {
      return;
    }

    return (
      <div className='translations'>
        <div className='translations-row translations-header'>
          <div className='translations-column'>{t(`language.${defaultLanguageCode}`)}</div>
          {this.renderAdditionalLanguageColumnHeader()}
        </div>
        {this.renderTranslationRows()}
      </div>
    );
  };

  render() {

    return (
      <div className='questionnaire-translations'>
        {this.renderQuestionnaireLanguages()}
        {this.renderTranslations()}
      </div>
    );
  }
}
