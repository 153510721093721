import {List} from 'immutable';
import * as _ from 'lodash';
import {formatDate} from '../util';
import {validate, validators} from 'validate.js';
import moment from 'moment';
import {i18OptionsCommon} from '../util';
import i18next from 'i18next';

export function registerCustomValidators(i18n: i18next.i18n) {
  validators.isBeforeAttribute = function(value, options, key, attributes) {
    function getMomentValue(dateValue, field, startTime?) {
      const dateOnlyField = field + 'DateOnly';
      const dateOnly = attributes[dateOnlyField];
      const momentValue = moment(dateValue);

      if (dateOnly) {
        return startTime ? momentValue.startOf('day') : momentValue.endOf('day');
      }

      return momentValue;
    }

    if (attributes[options]) {
      const moment1 = getMomentValue(value, key, true);
      const moment2 = getMomentValue(attributes[options], options);

      if (moment1.diff(moment2) >= 0) {
        return i18n.t('validate.dateMustBeBeforeAttribute', {
          attribute: i18n.t('validate.attribute.' + options, i18OptionsCommon()),
          date: formatDate(moment1, attributes[key + 'DateOnly']),
          date2: formatDate(moment2, attributes[options + 'DateOnly']),
          ...i18OptionsCommon()
        });
      }
    }

    return null;
  };

  validators.isBeforeNow = function(value, _options, key, attributes) {
    if (value) {
      const moment1 = moment(value);
      const now = moment().endOf('minute');

      if (moment1.diff(now) >= 0) {
        const dateOnly = attributes[key + 'DateOnly'];

        return i18n.t('validate.dateMustBeBeforeNow', {
          date: formatDate(moment1, dateOnly),
          date2: formatDate(now),
          ...i18OptionsCommon()
        });
      }
    }

    return null;
  };

  validators.listSize = function(value, options, _key, _attributes) {
    if (value) {
      const list = List.isList(value) ? value : List(value);

      if (list.size !== parseInt(options, 10)) {
        return i18n.t('validate.listSize', {length: options, ...i18OptionsCommon()});
      }
    }

    return null;
  };

  validators.inclusion = function(value, options, _key, _attributes) {
    if (value) {
      if (
        (!_.isArray(value) && !_.includes(options, value)) ||
        (_.isArray(value) && _.difference(value, options).length > 0)
      ) {
        return i18n.t('errors.language.invalid', {allowedLanguages: options});
      }
    }

    return undefined;
  };

  validators.numericality.notGreaterThanOrEqualTo = function(_value, _options, key, _attributes) {
    return i18n.t('validate.notGreaterThanOrEqualTo', {limit: key.greaterThanOrEqualTo, ...i18OptionsCommon()});
  };

  validators.numericality.notLessThanOrEqualTo = function(_value, _options, key, _attributes) {
    return i18n.t('validate.notLessThanOrEqualTo', {limit: key.lessThanOrEqualTo, ...i18OptionsCommon()});
  };

  validators.numericality.notGreaterThan = function(_value, _options, key, _attributes) {
    return i18n.t('validate.notGreaterThan', {limit: key.greaterThan, ...i18OptionsCommon()});
  };

  validators.numericality.notLessThan = function(_value, _options, key, _attributes) {
    return i18n.t('validate.notLessThan', {limit: key.lessThan, ...i18OptionsCommon()});
  };

  validators.presence.message = i18n.t('validate.presence', i18OptionsCommon());
  validators.email.message = i18n.t('validate.email', i18OptionsCommon());
  validators.equality.message = i18n.t('validate.equality', i18OptionsCommon());
  validators.length.tooShort = i18n.t('validate.tooShort', i18OptionsCommon());
  validators.numericality.notInteger = i18n.t('validate.notInteger', i18OptionsCommon());

  validators.custom = function<T>(value: T, options: { rule: (value: T) => boolean; message: string }) {
    const {rule, message} = options;
    if (!rule(value)) {
      return i18n.t(message);
    }
    return;
  };

  // @ts-ignore
  validate.options = {fullMessages: false};
}
