/**
 * Created by Mauritz Untamala on 01/10/15.
 */

import * as _ from 'lodash';
import API from '../services/API';
import {stringify} from 'qs';
import {handleGenericError} from './App';

export function getPath(objectName, pathComponents?, queryParams?) {
  let path = `/${objectName}`;

  if (_.isArray(pathComponents)) {
    pathComponents = pathComponents.join('/');
  }
  if (pathComponents) {
    path = `${path}/${pathComponents}`;
  }
  if (queryParams) {
    path = `${path}?${stringify(queryParams)}`;
  }
  return path;
}

export function get(objectName, pathComponents?, queryParams?) {
  return (dispatch, getState) => {
    const {authenticatedUser} = getState();

    return API.get(
      getPath(objectName, pathComponents, queryParams),
      authenticatedUser.accessToken
    ).catch(error => dispatch(handleGenericError(error)));
  };
}

export function add(objectName, pathComponents, queryParams, object) {
  return (dispatch, getState) => {
    const {authenticatedUser} = getState();

    return API.post(
      getPath(objectName, pathComponents, queryParams),
      object,
      authenticatedUser.accessToken
    ).catch(error => dispatch(handleGenericError(error)));
  };
}

export function update(objectName, pathComponents, queryParams?, object?) {
  return (dispatch, getState) => {
    const {authenticatedUser} = getState();

    return API.put(
      getPath(objectName, pathComponents, queryParams),
      object,
      authenticatedUser.accessToken
    ).catch(error => dispatch(handleGenericError(error)));
  };
}

export function remove(objectName, pathComponents, queryParams?) {
  return (dispatch, getState) => {
    const {authenticatedUser} = getState();

    return API.delete(
      getPath(objectName, pathComponents, queryParams),
      authenticatedUser.accessToken
    ).catch(error => dispatch(handleGenericError(error)));
  };
}

function updateOrAdd(objectName, object) {
  return dispatch => {
    if (object.id) {
      return dispatch(update(objectName, object.id, null, object));
    }

    if (object._id) {
      return dispatch(add(objectName, null, null, object));
    }

    return Promise.reject(new Error('Object should have id or _id'));
  };
}

export function updateObject(objectName, object) {
  return updateOrAdd(objectName, object.toJS());
}
