import * as React from 'react';
import {PureComponent} from 'react';
import Input from '../../../components/Input/Input';
import {getFieldError} from '../../../util';

import Question from '../../../models/Question';
import QuestionComponentView from './QuestionComponent';
import RoundButton from '../../../components/RoundButton/RoundButton';

const deleteIcon = require('../../../images/delete.svg');
const duplicateIcon = require('../../../images/duplicate.svg');
const dragIcon = require('../../../images/drag.svg');
const roundAddIcon = require('../../../images/round-add.svg');

interface Props {
  question: Question;
  language: string;
  onChange: (question) => any;
  onDeleteQuestion: (question) => any;
  onDuplicateQuestion: (question) => any;
  t: (key, params?) => any;
  hasError?: boolean;
}

interface State {
}

export default class QuestionView extends PureComponent<Props, State> {

  onFieldChange = (field, value, ignoreLocalization = false) => {

    const {question, language, onChange} = this.props;
    onChange(ignoreLocalization ?
      question.set(field, value) :
      question.setField(field, value, language));
  };

  onTitleChange = (value) => {
    this.onFieldChange('title', value);
  };

  renderQuestionField = () => {
    const {question, language, t, hasError} = this.props;
    const field = 'title';

    const fieldError = getFieldError(
      `${field}.${language}`,
      question.validateByLanguage(language),
      question.error
    );

    return (
      <Input
        groupClassName='input-field col-12'
        key={`input-${field}`}
        error={fieldError}
        hasError={hasError && !!fieldError}
        onChange={this.onTitleChange}
        value={question.getField(field, language)}
        labelClassName='question-label'
        rowLayout={false}
        label={t('questionnaire.form.question')}
        t={t}
      />
    );
  };

  onDeleteQuestion = () => {

    const {question, onDeleteQuestion} = this.props;

    onDeleteQuestion(question);
  };

  onDuplicateQuestion = () => {

    const {question, onDuplicateQuestion} = this.props;

    onDuplicateQuestion(question);
  };

  onComponentChange = (component) => {
    const {question, onChange} = this.props;

    onChange(question.updateComponent(component));
  };

  onComponentDelete = (component) => {

    const {question, onChange} = this.props;

    onChange(question.removeComponent(component));
  };

  renderQuestionComponentRow = (component, key) => {

    const {t, language, hasError} = this.props;

    return (
      <QuestionComponentView
        key={`question-component-${key}`}
        component={component}
        onChange={this.onComponentChange}
        onDelete={this.onComponentDelete}
        language={language}
        hasError={hasError}
        t={t}
      />
    );
  };

  renderQuestionComponents = () => {

    const {question} = this.props;

    return question.getComponents().map(this.renderQuestionComponentRow).toArray();
  };

  addQuestionComponent = () => {

    const {onChange, question, language} = this.props;

    onChange(question.addComponent(language));
  };

  renderAddQuestionComponent = () => {

    const {t} = this.props;

    return (
      <div className='add-question-component'>
        <img className='draggable-icon' src={dragIcon} alt='Drag icon'/>
        <div onClick={this.addQuestionComponent} className='add-button add-answer-button'>
          <img className='add-button__icon' src={roundAddIcon} alt='Add icon'/>
          {t('questionnaire.form.addQuestionComponent')}
        </div>
      </div>
    );
  };

  renderQuestionActions = () => {

    return (
      <div className='question-actions'>
        <RoundButton iconSrc={duplicateIcon}
          shape='circle'
          size='small'
          onClick={this.onDuplicateQuestion}
        />
        <RoundButton iconSrc={deleteIcon}
          shape='circle'
          size='small'
          onClick={this.onDeleteQuestion}
        />
      </div>
    );
  };

  render() {
    const {question: {order}} = this.props;

    return (
      <div className='question-form' key={`question-${order}`}>
        <div className='question-top'>
          <h5 className='question-order'>{order}.</h5>
          {this.renderQuestionActions()}
        </div>
        <div className='title-row'>
          {this.renderQuestionField()}
        </div>
        {this.renderQuestionComponents()}
        {this.renderAddQuestionComponent()}
      </div>
    );
  }
}
