import {getFieldError} from '../../../../util';
import classNames from 'classnames';
import Input from '../../../../components/Input/Input';
import * as React from 'react';

interface Props {
  field: string;
  localization: any;
  component: any;
  validationErrors: any;
  classes: string[];
  onChange: (any) => void;
  language: string;
  t: (string) => string;
  label?: string;
}

export const ComponentLocalization = (
  {
    field,
    localization,
    component,
    validationErrors,
    classes = ['col-12', 'col-sm-6'],
    onChange,
    language,
    t,
    label = field
  }: Props) => {

  const fieldError = getFieldError(
    `localization.${language}.${field}`,
    validationErrors,
    component.error
  );

  const onLocalizationChange = value => {
    const newLocalization = {...localization};
    if (!newLocalization[language]) {
      newLocalization[language] = {};
    }
    newLocalization[language][field] = value;
    onChange(component.setLocalization(newLocalization));
  };

  const groupClassName = classes ? classNames(classes) : undefined;
  const value = localization && localization[language] ? localization[language][field] : '';

  return (
    <Input
      rowLayout={false}
      className={`question-component-localization-${field}`}
      groupClassName={groupClassName}
      key={`input-question-component-localization-${field}`}
      error={fieldError}
      onChange={onLocalizationChange}
      value={value}
      label={t(`questionnaire.form.componentLocalization.${label}`)}
      t={t}
    />
  );
};
