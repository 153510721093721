import * as React from 'react';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { navigate } from '../../../modules/Location';
import StudiesModel from '../../../models/Studies';
import StudiesModule from '../../../modules/Studies';
import RoundButton from '../../../components/RoundButton/RoundButton';

import './StartHere.less';

interface Props extends WithNamespaces {
  getStudies: (queryParams?, reset?) => any;
  studies: StudiesModel;
  navigate: (url: string, silent?: boolean) => any;
}

interface State {
  studiesChecked: boolean;
}

class StartHere extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      studiesChecked: undefined
    };
  }

  componentDidMount() {

    const {getStudies} = this.props;
    getStudies();
  }

  componentDidUpdate() {

    const {studies, navigate} = this.props;
    const {studiesChecked} = this.state;

    if (!studiesChecked && !studies.isLoading) {

      this.setState({ studiesChecked: true });

      if (studies.list.size > 0) {

        navigate('/admin/studies');
      }
    }
  }

  addStudy = () => this.props.navigate('/admin/studies/new');

  renderContentTitle = () => {

    const {t} = this.props;

    return (
      <>
        {t('startHereView.contentTitle')}
      </>
    );
  };

  renderContent = () => {

    const {t} = this.props;

    return (
      <div className='start-here-view__content'>
        <p>
          {t('startHereView.content')}
        </p>
      </div>
    );
  };

  renderButton = () => {

    const {t} = this.props;

    return (
      <RoundButton
        type='clear'
        onClick={this.addStudy}
      >
        {t('button.addStudy')}
      </RoundButton>
    );
  };

  render() {

    const {t} = this.props;
    const {studiesChecked} = this.state;

    if (!studiesChecked) {

      return null;
    }

    return (
      <div className='start-here-view'>
        <h1 className='view-title'>
          {t('startHereView.title')}
        </h1>
        <div className='start-here-view__content-title'>
          {this.renderContentTitle()}
        </div>
        <div className='start-here-view__content-container'>
          {this.renderContent()}
        </div>
        <div className='start-here-view__button-container'>
          {this.renderButton()}
        </div>
      </div>
    );
  }
}

const mapActionCreators = {
  getStudies: StudiesModule.getModels,
  navigate
};

const mapStateToProps = ({ studies }, _ownProps) => {
  return {
    studies
  };
};

export default withNamespaces(['admin'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionCreators
  )(StartHere)
);