import {Record} from 'immutable';

export interface ICrop {
  x: number;
  y: number;
  width: number;
  height: number;
  aspect: number;
}

const defaultValues = {
  x: undefined,
  y: undefined,
  width: undefined,
  height: undefined,
  aspect: undefined
};

class Crop extends Record(defaultValues) implements ICrop {
  x: number;
  y: number;
  width: number;
  height: number;
  aspect: number;
}

export default Crop;
