import * as React from 'react';
import * as _ from 'lodash';
import {Component} from 'react';
import {ComponentProps, ComponentState} from '../Question';

interface InstructionProps extends ComponentProps {
}

interface InstructionState extends ComponentState {
}

export default class Instruction extends Component<InstructionProps, InstructionState> {
  render() {
    const {component, language} = this.props;
    const content = component.getLanguageLocalization(language);

    return (
      <pre className='question-instruction'>{content}</pre>
    );
  }
}