import {enumValues} from '../util';

export enum UserRole {
  admin = 'admin',
  user = 'user'
}

export namespace UserRole {

  const values = enumValues(UserRole);

  export function getValues() {

    return values;
  }
}
