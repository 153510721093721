import * as React from 'react';
import './NavButton.less';

interface Props {
  iconSrc: string;
  onClick: () => void;
  altText?: string;
}
const NavButton: React.FunctionComponent<Props> = (props) => {

  const {iconSrc, children, onClick, altText} = props;

  return (
    <div className='nav-button' onClick={onClick}>
      <img className='nav-button__icon' src={iconSrc} alt={altText}/>
      <p className='nav-button__text'>{children}</p>
    </div>
  );
};

export default NavButton;
