import * as React from 'react';
import {Form} from 'react-bootstrap';
import {PureComponent} from 'react';
import './EditorMCE.less';
import classNames from 'classnames';

interface Props {
  label?: string;
  labelClassName?: string;
  fieldClassName?: string;
  initialValue?: string;
  onEditorChange: (string) => void;
  error?: any;
  hasError?: boolean;
  t?: (key, params?) => any;
}

interface State {
  showError: boolean;
}

export default class EditorMCE extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showError: false,
    };
  }

  onBlur = () => this.setState({showError: true});

  onFocus = () => this.setState({showError: false});

  shouldShowError = () =>
    (this.props.error && this.state.showError) || (this.props.hasError);

  onTextareaChange = (event) => {
    this.props.onEditorChange(event.target.value);
  };

  getError = () => {
    if (this.shouldShowError()) {
      return <div className='error'>{this.props.t(this.props.error)}</div>;
    }
  };

  render() {
    const {label, initialValue, labelClassName, fieldClassName} = this.props;

    const labelClass = classNames(
      labelClassName,
      {'tinymce__label--error': this.shouldShowError()}
    );

    const formGroupClass = classNames(
      'form-group',
      'row',
      {'has-error': this.shouldShowError()}
    );

    return (
      <Form.Group className={formGroupClass}>
        <Form.Label className={labelClass}>{label}</Form.Label>
        <div className={fieldClassName}>
          <textarea className="col-8"
            rows={10}
            onChange={this.onTextareaChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            defaultValue={initialValue}
          ></textarea>
          {this.getError() && <div>{this.getError()}</div>}
        </div>
      </Form.Group>
    );
  }
};
