import {Record} from 'immutable';

const state = Record({
  app: undefined,
  authenticatedUser: undefined,
  download: undefined,
  location: undefined,
  pagination: undefined,
  routing: undefined,
  study: undefined,
  studies: undefined,
  studySite: undefined,
  studySites: undefined,
  user: undefined,
  users: undefined,
  questionnaire: undefined,
  questionnaires: undefined,
  questionnaireAnswer: undefined,
  questionnaireAnswers: undefined,
  picture: undefined
});

export default state;
