import * as React from 'react';
import classnames from 'classnames';
import NavButton from '../NavButton/NavButton';
import i18n from '../../services/I18n';
import './Footer.less';
import {HtmlRender} from '../HtmlRender';

const iconJaa = require('../../images/jaa.svg');

interface Props {
  socialSharing?: boolean;
  footerText?: string;
  className?: string;
}

const Footer: React.FunctionComponent<Props> = (props) => {

  const {className, socialSharing, footerText} = props;

  const onShareClick = () => {
    // TODO: update share feature
  };

  return (
    <footer className={classnames('footer', className)}>
      {!!socialSharing && (
        <div className="footer__button">
          <NavButton iconSrc={iconJaa} onClick={onShareClick}>{i18n.t('button.shareStudy')}</NavButton>
        </div>
      )}
      {!!footerText && (
        <p className="footer__copy-right">
          <HtmlRender html={footerText}/>
        </p>)}
    </footer>
  );
};

export default Footer;
