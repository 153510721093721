import * as React from 'react';
import {Component} from 'react';

import * as _ from 'lodash';
import User from '../../../../models/User';

interface Props {
  model: User;
  onSubmit: (model) => any;
  t: (key, params?) => any;
  navigate: (url: string, silent?: boolean) => any;
}

interface State {
  model: User;
  showError: boolean;
}

export default class ForgotPasswordForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      model: props.model,
      showError: !_.isUndefined(props.model.error)
    };
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    const {model: propModel} = this.props;
    const {model: stateModel} = this.state;

    // Update state if id changes or if user is updated but it's not saving
    // (we want to avoid the jitter of model when updating
    // as the isSaving is set to true while saving)
    if (prevProps.model.id !== propModel.id || prevProps.model !== propModel) {
      let updatedModel = propModel;

      if (propModel.error) {
        updatedModel = stateModel.endOfSaving(propModel.error);
      } else if (propModel.isSaving) {
        updatedModel = stateModel.startOfSaving();
      }

      this.setState({model: updatedModel});
    }

    const {model: prevStateModel} = _prevState;

    if (prevStateModel.email !== stateModel.email && stateModel.error) {
      this.setState({model: stateModel.endOfSaving(undefined), showError: false});
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const {model} = this.state;
    const {onSubmit} = this.props;

    this.setState(
      {showError: true},
      () => {
        if (!model.validateForgotPassword()) {
          onSubmit(model);
        }
      }
    );
  };

  onFieldChange = field => event => {
    const {target: {value}} = event;
    const {model} = this.state;

    this.setState({
      model: model.set(field, value) as User
    });
  };

  render() {
    const {t} = this.props;
    const {model} = this.state;

    return (
      <form className='form-forgot-password' onSubmit={this.handleSubmit}>
        <img src={''} alt=''/>
        <label htmlFor='input-username' className='sr-only'>
          {t('forgotPassword.email')}
        </label>
        <h5>{t('forgotPassword.email')}</h5>
        <input
          id='input-username'
          name='username'
          className='form-control'
          onChange={this.onFieldChange('email')}
          required={true}
          autoFocus={true}
          autoComplete='off'
        />
        <button
          id='forgot-password-button'
          type='submit'
          className='btn btn-lg btn-info btn-block'
          disabled={!!model.validateForgotPassword()}>
          {(model.isLoading) ? <i className="fa fa-spin fa-spinner"/> : t('forgotPassword.resetPassword')}
        </button>
      </form>
    );
  }
}
