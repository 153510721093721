import * as React from 'react';
import {Component} from 'react';
import QuestionComponent from '../../../../models/QuestionComponent';
import {ComponentLocalization} from './ComponentLocalization';
import {ComponentOption} from './ComponentOption';

interface NumberFieldProps {
  language: string;
  component: QuestionComponent;
  onChange: (question: QuestionComponent) => any;
  t: (key, params?) => any;
}

interface NumberFieldState {
}

export default class NumberField extends Component<NumberFieldProps, NumberFieldState> {

  renderLocalizationField = (field, classes) => {

    const {onChange, component, language, t} = this.props;
    const localization = component.getLocalization();
    const validationErrors = component.validateByLanguage(language);

    return (
      <ComponentLocalization
        field={field}
        localization={localization}
        component={component}
        validationErrors={validationErrors}
        classes={classes}
        onChange={onChange}
        language={language}
        t={t}
      />
    );
  };

  renderOptionField = (field, type, classes) => {

    const {language, component, onChange, t} = this.props;
    const options = component.getOptions();
    const validationErrors = component.validateByLanguage(language);

    return (
      <ComponentOption
        field={field}
        type={type}
        options={options}
        component={component}
        validationErrors={validationErrors}
        classes={classes}
        onChange={onChange}
        t={t}
      />
    );
  };

  render() {
    const {component} = this.props;
    const numberType = component.getType() === 'number';
    const sliderType = component.getType() === 'slider';

    return (
      <div className='component-number'>
        {this.renderLocalizationField('label', 'component-number__label')}
        {this.renderLocalizationField('unit', 'component-number__unit')}
        {this.renderOptionField('min', 'number', 'component-number__min')}
        {this.renderOptionField('max', 'number', 'component-number__min')}
        {numberType && this.renderOptionField('decimalDigits', 'number', 'component-number__decimal')}
        {sliderType && this.renderOptionField('steps', 'number', 'component-number__decimal')}
      </div>
    );
  };
}
