import * as React from 'react';
import {PureComponent} from 'react';
import * as _ from 'lodash';
import QuestionnaireListItem from './QuestionnaireListItem';

import Questionnaires from '../../../modules/Questionnaires';
import QuestionnairesModel from '../../../models/Questionnaires';
import Studies from '../../../modules/Studies';
import StudiesModel from '../../../models/Studies';
import Questionnaire from '../../../modules/Questionnaire';
import {navigate} from '../../../modules/Location';
import {connect} from 'react-redux';
import i18n from '../../../services/I18n';
import {WithNamespaces, withNamespaces} from 'react-i18next';

import './Questionnaires.less';
import User from '../../../models/User';
import RoundButton from '../../../components/RoundButton/RoundButton';
import {PaginationContext} from '../../../models/Pagination';

interface Props extends WithNamespaces {
  authenticatedUser: User;
  questionnaires: QuestionnairesModel;
  studies: StudiesModel;
  navigate: (url) => any;
  getQuestionnaires: (id, reset) => any;
  deleteQuestionnaire: (model) => any;
  fetchStudies: (ids: number[]) => any;
  pagination: any;
}

interface State {
}

class QuestionnairesView extends PureComponent<Props, State> {
  questionnaireContainer;

  componentDidMount() {
    const {getQuestionnaires} = this.props;
    getQuestionnaires(null, true);
    this.setupInfiniteScrolling();
  }

  componentDidUpdate(prevProps) {

    const {questionnaires, fetchStudies, studies} = this.props;
    const fetch = prevProps.questionnaires !== questionnaires;

    if (fetch && !questionnaires.isLoading && !studies.isLoading) {
      const ids = _.uniq(questionnaires.list.toArray().map(q => q.studyId));
      fetchStudies(ids);
    }
  }

  getSortedQuestionnaires = () => {
    return this.props.questionnaires.list;
  };

  getListItems = () => {

    const {navigate, deleteQuestionnaire, t, studies} = this.props;

    return this.getSortedQuestionnaires().map((questionnaire, index) => {

      const study = studies.list.find(s => s.id === questionnaire.studyId);

      return (
        <QuestionnaireListItem
          key={`questionnaire_item_${questionnaire.id}`}
          index={index}
          questionnaire={questionnaire}
          study={study}
          navigate={navigate}
          deleteQuestionnaire={deleteQuestionnaire}
          t={t}
        />
      );
    });
  };

  onAddClick = () => {
    const {navigate} = this.props;
    navigate('/admin/questionnaires/new');
  };

  loadMore = () => {
    const {getQuestionnaires, pagination} = this.props;
    if (pagination.hasMore) {
      getQuestionnaires(null, false);
    }
  };

  scrollListener = () => {
    const element = this.questionnaireContainer;
    const scrollTop = (element && element.scrollTop) || document.body.scrollTop;
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight;
    const scrolledToBottom = scrollTop + element.clientHeight >= scrollHeight - 20;
    const hasMore = this.props.pagination.hasMore;
    if ((this.hasScrollBar() && scrolledToBottom && hasMore && this.loadMore)) {
      this.detachScrollListener();
      this.loadMore();
    }
  };

  hasScrollBar = () => {
    const element = this.questionnaireContainer;
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight;
    const clientHeight = (element && element.clientHeight) || document.body.clientHeight;

    return scrollHeight > clientHeight;
  };

  scrollToTop = () => {
    const element = this.questionnaireContainer;
    element.scrollTop = 0;
  };

  attachScrollListener = () => {
    const hasMore = this.props.pagination.hasMore;
    if (!hasMore || !this.isInfiniteScroll()) {
      return;
    }

    if (this.questionnaireContainer) {
      this.questionnaireContainer.addEventListener('scroll', this.scrollListener);
      this.questionnaireContainer.addEventListener('resize', this.scrollListener);
      this.scrollListener();
    }
  };

  setupInfiniteScrolling = () => {
    if (!this.isInfiniteScroll()) {
      return;
    }

    this.attachScrollListener();
    const hasMore = this.props.pagination.hasMore;
    const loading = this.props.questionnaires.isLoading;
    // Load as much data until view get's scroll bar if there is data to be loaded.
    if (hasMore && !this.hasScrollBar() && loading) {
      this.loadMore();
    }
  };

  detachScrollListener = () => {
    if (!this.isInfiniteScroll()) {
      return;
    }
    if (this.questionnaireContainer) {
      this.questionnaireContainer.removeEventListener('scroll', this.scrollListener);
      this.questionnaireContainer.removeEventListener('resize', this.scrollListener);
    }
  };

  isInfiniteScroll = () => !!this.loadMore;

  loadingRow = () => {
    if (this.props.questionnaires.isLoading) {
      return (
        <div className='loading'>
          <span>{i18n.t('loading')}</span>
        </div>
      );
    }
  };

  render() {

    const {t} = this.props;

    return (
      <div className='questionnaire-management-view'>
        <h1 className='view-title__list'>
          {t('questionnairesView.title')}
        </h1>
        <div className='questionnaire-management-content' ref={scroll => {
          this.questionnaireContainer = scroll;
        }}>
          <ul className='list-group'>
            {this.getListItems()}
          </ul>
          {this.loadingRow()}
        </div>
        <div className='questionnaire-management-button'>
          <RoundButton
            className='add-questionnaire'
            type='clear'
            onClick={this.onAddClick}>
            {t('questionnaire.add')}</RoundButton>
        </div>
      </div>
    );
  }
}

const mapActionToProps = {
  getQuestionnaires: Questionnaires.getModels,
  deleteQuestionnaire: Questionnaire.deleteModel,
  navigate,
  changeOrder: Questionnaires.changeOrder,
  fetchStudies: Studies.fetchStudies
};

const mapStateToProps = ({questionnaires, studies, studySites, authenticatedUser, pagination}, _ownProps) => {
  return {
    questionnaires,
    studies,
    studySites,
    authenticatedUser,
    pagination: pagination.getPagination(PaginationContext.QUESTIONNAIRE)
  };
};

export default withNamespaces(['admin'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionToProps
  )(QuestionnairesView)
);
