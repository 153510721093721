import QuestionnaireAnswer from './QuestionnaireAnswer';
import Collection from './ImmutableCollection';

export default class QuestionnaireAnswers extends Collection(QuestionnaireAnswer)<
QuestionnaireAnswer
> {
  getAnswerForQuestionnaire(questionnaireId: number) {
    return this.list.find((answer) => answer.questionnaireId === questionnaireId);
  }
}
