import {combineReducers} from 'redux-immutable';
import authenticatedUser from '../modules/AuthenticatedUser';
import app from '../modules/App';
import download from '../modules/Download';
import pagination from '../modules/Pagination';
import routing from '../modules/Routing';
import study from '../modules/Study';
import studies from '../modules/Studies';
import studySite from '../modules/StudySite';
import studySites from '../modules/StudySites';
import user from '../modules/User';
import users from '../modules/Users';
import questionnaire from '../modules/Questionnaire';
import questionnaires from '../modules/Questionnaires';
import questionnaireAnswer from '../modules/QuestionnaireAnswer';
import questionnaireAnswers from '../modules/QuestionnaireAnswers';
import picture from '../modules/Picture';
import location from '../modules/Location';

export const makeRootReducer = (asyncReducers?: any) => {
  return combineReducers({
    authenticatedUser: authenticatedUser.getReducer(),
    app,
    download,
    location,
    pagination,
    picture,
    routing,
    study: study.getReducer(),
    studies: studies.getReducer(),
    studySite: studySite.getReducer(),
    studySites: studySites.getReducer(),
    user: user.getReducer(),
    users: users.getReducer(),
    questionnaire: questionnaire.getReducer(),
    questionnaires: questionnaires.getReducer(),
    questionnaireAnswer: questionnaireAnswer.getReducer(),
    questionnaireAnswers: questionnaireAnswers.getReducer(),
    ...asyncReducers
  });
};

export const injectReducer = (store: any, {key, reducer}: any) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
