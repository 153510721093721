import * as React from 'react';
import {Component} from 'react';
import classNames from 'classnames';
import Input from '../../../../components/Input/Input';
import QuestionComponent from '../../../../models/QuestionComponent';
import {ComponentLocalization} from './ComponentLocalization';
import {ComponentOption} from './ComponentOption';

interface BmiFieldProps {
  language: string;
  component: QuestionComponent;
  onChange: (question: QuestionComponent) => any;
  t: (key, params?) => any;
}

interface BmiFieldState {
}

export default class BmiField extends Component<BmiFieldProps, BmiFieldState> {

  renderText = (
    label,
    value,
    classes
  ) => {

    const {t} = this.props;
    const groupClassName = classes ? classNames(classes) : undefined;

    return (
      <Input
        rowLayout={false}
        groupClassName={groupClassName}
        key={value}
        value={value}
        label={t(label)}
        t={t}
        readOnly={true}
      />
    );
  };

  renderLocalizationField = (field, classes, label?) => {

    const {component, language, onChange, t} = this.props;
    const localization = component.getLocalization();
    const validationErrors = component.validateByLanguage(language);

    return (
      <ComponentLocalization
        field={field}
        localization={localization}
        component={component}
        validationErrors={validationErrors}
        classes={classes}
        onChange={onChange}
        language={language}
        t={t}
        label={label}
      />
    );
  };

  renderOptionField = (field, type, classes, label?) => {

    const {language, component, onChange, t} = this.props;
    const options = component.getOptions();
    const validationErrors = component.validateByLanguage(language);

    return (
      <ComponentOption
        field={field}
        type={type}
        options={options}
        component={component}
        validationErrors={validationErrors}
        classes={classes}
        onChange={onChange}
        t={t}
        label={label}
      />
    );
  };

  render() {

    return (
      <div className='component-bmi'>
        <div className='component-number'>
          {this.renderLocalizationField('labelHeight', 'component-number__label', 'label')}
          {this.renderText('questionnaire.form.componentLocalization.unit', 'cm', 'component-number__unit')}
          {this.renderOptionField('minHeight', 'number', 'component-number__min', 'min')}
          {this.renderOptionField('maxHeight', 'number', 'component-number__min', 'max')}
          {this.renderOptionField('decimalDigitsHeight', 'number', 'component-number__decimal', 'decimalDigits')}
        </div>
        <div className='component-number'>
          {this.renderLocalizationField('labelWeight', 'component-number__label', 'label')}
          {this.renderText('questionnaire.form.componentLocalization.unit', 'kg', 'component-number__unit')}
          {this.renderOptionField('minWeight', 'number', 'component-number__min', 'min')}
          {this.renderOptionField('maxWeight', 'number', 'component-number__min', 'max')}
          {this.renderOptionField('decimalDigitsWeight', 'number', 'component-number__decimal', 'decimalDigits')}
        </div>
      </div>
    );
  };
}
