import * as React from 'react';
import classNames from 'classnames';

import './Header.less';

interface Props {
  header: string;
  logoUrl?: string;
  hideLogo?: boolean;
  className?: string;
}

const Header: React.FunctionComponent<Props> = (props) => {

  const {header, hideLogo, className, logoUrl} = props;

  const getLogoStateClassName = () => {

    if (hideLogo || !logoUrl) {
      return 'header__logo--hidden';
    }
  };

  return (
    <div className={classNames(`header ${className}`)}>
      <img className={classNames('header__logo', getLogoStateClassName())} src={logoUrl} alt='CliniScout Recruit logo'/>
      <h1 className='header__heading'>{header}</h1>
    </div>
  );
};

export default Header;
