import * as React from 'react';
import {Component} from 'react';
import UserView from '../../components/UserView';

import {connect} from 'react-redux';
import AuthenticatedUser from '../../modules/AuthenticatedUser';
import UserModel from '../../models/User';
import {WithNamespaces, withNamespaces} from 'react-i18next';

interface Props extends WithNamespaces {
  user: UserModel;
  getModel: (id, queryParams?) => any;
  saveModel: (model, pathComponents?, queryParams?) => any;
  resetModel: () => any;
}

class Profile extends Component<Props, any> {
  render() {
    const {user: {id: userId}} = this.props;

    return (
      <UserView
        key='user-view'
        userId={userId}
        profile={true}
        {...this.props}
      />
    );
  }
}

const mapActionCreators = {
  getModel: AuthenticatedUser.getModel,
  saveModel: AuthenticatedUser.saveModel,
  resetModel: AuthenticatedUser.resetModel
};

const mapStateToProps = ({authenticatedUser}, _ownProps) => ({
  user: authenticatedUser
});

export default withNamespaces(['admin'], {wait: true})(connect(
  mapStateToProps,
  mapActionCreators
)(Profile));
