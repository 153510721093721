import {createAction} from 'redux-actions';
import User from '../models/User';
import API from '../services/API';
import {bind} from '../util';
import {teardown} from './App';
import {navigate} from './Location';
import ModelModule from './ModelModule';
import i18n from '../services/I18n';
import {destroyToken, retrieveToken, storeToken} from '../services/localStorage';
import {LOGIN_URL} from '../config/constants';

const initialState = new User();

export class AuthenticatedUserModule extends ModelModule<User> {
  private loginRequestType: string;
  private loginSuccessType: string;
  private loginFailedType: string;

  private logoutRequestType: string;
  private logoutSuccessType: string;

  private validateTokenRequestType: string;
  private validateTokenSuccessType: string;
  private validateTokenFailureType: string;

  private resetPasswordRequestType: string;
  private resetPasswordSuccessType: string;
  private resetPasswordFailureType: string;

  private forgotPasswordRequestType: string;
  private forgotPasswordSuccessType: string;
  private forgotPasswordFailureType: string;

  private loginRequestAction: any;
  private loginSuccessAction: any;
  private loginFailedAction: any;

  private logoutRequestAction: any;
  private logoutSuccessAction: any;

  private validateTokenRequestAction: any;
  private validateTokenSuccessAction: any;
  private validateTokenFailureAction: any;

  private resetPasswordRequestAction: any;
  private resetPasswordSuccessAction: any;
  private resetPasswordFailureAction: any;

  private forgotPasswordRequestAction: any;
  private forgotPasswordSuccessAction: any;
  private forgotPasswordFailureAction: any;

  constructor() {
    super('profile', initialState, 'authenticatedUser', 'user');
    bind(
      this,
      this.login,
      this.logout,
      this.getAuthenticatedUser,
      this.validateToken,
      this.resetPassword,
      this.forgotPassword
    );
  }

  public login(username, password, redirect?) {
    return dispatch => {
      dispatch(this.loginRequestAction());

      return API.post('/auth/local', {identifier: username, password})
        .then(response => {

          return dispatch(this.handleLoginSuccessResponse(
            response,
            response.token,
            redirect
          ));
        })
        .catch(error => dispatch(this.loginFailedAction(error)));
    };
  }

  public validateToken(token) {
    return dispatch => {
      dispatch(this.validateTokenRequestAction());

      return API.post('/reset-token-validate', {token})
        .then(user => dispatch(this.validateTokenSuccessAction({passwordResetToken: token, ...user})))
        .catch(error => dispatch(this.validateTokenFailureAction(error && error.data || error)));
    };
  }

  public resetPassword(model) {
    return dispatch => {

      dispatch(this.resetPasswordRequestAction());

      const {passwordResetToken: token, password} = model;
      const request = {token, password};

      return API.post('/reset-password', request)
        .then(response => {

          dispatch(this.resetPasswordSuccessAction());
          storeToken(response.token);

          return dispatch(this.getAuthenticatedUser());
        })
        .catch(error => dispatch(this.resetPasswordFailureAction(error && error.data || error)));
    };
  }

  public forgotPassword(model) {
    return dispatch => {

      dispatch(this.forgotPasswordRequestAction(model));

      const {email} = model;

      return API.post('/forgot-password', {email})
        .then(() => {

          dispatch(this.forgotPasswordSuccessAction());

          return dispatch(this.getAuthenticatedUser());
        })
        .catch(error => dispatch(this.forgotPasswordFailureAction(error && error.data || error)));
    };
  }

  /**
   * Overridden to set user language if needed
   *
   * @param id
   * @param queryParams
   *
   * @override
   */
  public getModel(id, queryParams?) {
    return dispatch => {
      return dispatch(super.getModel(id, queryParams)).then(() =>
        dispatch(this.checkAndChangeLanguage())
      );
    };
  }

  public logout() {

    return (dispatch) => {

      dispatch(this.logoutRequestAction());

      return Promise.resolve(destroyToken())
        .then(() => {

          dispatch(teardown());

          dispatch(this.logoutSuccessAction());

          return dispatch(navigate(LOGIN_URL));
        })
        .catch(error => dispatch(this.loginFailedAction(error)));
    };
  }

  public getAuthenticatedUser(redirectAfterAuth?) {

    return (dispatch) => {

      dispatch(this.loginRequestAction());

      const accessToken = retrieveToken();

      if (accessToken) {

        return API.post('/auth/bearer', {}, accessToken)
          .then(response => {

            return dispatch(this.handleLoginSuccessResponse(
              response,
              accessToken,
              redirectAfterAuth
            ));
          })
          .catch(error => {

            destroyToken();

            return dispatch(this.loginFailedAction(error));
          });

      } else {

        return Promise.resolve(dispatch(this.loginFailedAction()));
      }
    };
  }

  public resetAuthenticatedUser() {
    return dispatch => dispatch(this.logoutSuccessAction());
  }

  public updateSelectedStudyId(siteStudyId) {
    return (dispatch, getState) => {
      const {authenticatedUser} = getState();

      return dispatch(this.updateModel(authenticatedUser.set('selectedStudyId', siteStudyId)))
        .then(() => dispatch(this.getAuthenticatedUser()));
    };
  }

  public getLogoutSuccessType() {
    return this.logoutSuccessType;
  }

  protected onUpdateSuccess(model) {
    return dispatch => {
      dispatch(super.onUpdateSuccess(model));
      dispatch(this.checkAndChangeLanguage());
    };
  }

  /**
   * @override
   */
  protected getAdditionalActionHandlers() {

    const setAuthenticatedUser = (state, {payload}) => {

      // Set access token if present in current model
      const authenticatedUser = {accessToken: state.accessToken, ...payload};

      return state.fromJS(authenticatedUser).setAuthenticated(true);
    };

    return {
      [this.loginRequestType]: state => state.startOfLoading(),
      [this.loginSuccessType]: setAuthenticatedUser,
      [this.loginFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.logoutRequestType]: state => state.startOfLoading(),
      [this.logoutSuccessType]: () => initialState,
      [this.getModelSuccessType]: setAuthenticatedUser,
      [this.updateModelSuccessType]: setAuthenticatedUser,
      [this.validateTokenRequestType]: state => state.startOfValidatingToken(),
      [this.validateTokenSuccessType]: (state, {payload}) => state.fromJS(payload),
      [this.validateTokenFailureType]: (state, {payload}) => state.endOfValidatingToken(payload),
      [this.resetPasswordRequestType]: state => state.startOfLoading(),
      [this.resetPasswordSuccessType]: state => state.setPasswordResetToken(null).endOfLoading(),
      [this.resetPasswordFailureType]: (state, {payload}) => state.endOfLoading(payload),
      [this.forgotPasswordRequestType]: (state, {payload}) => state.startOfForgotPassword(payload.account),
      [this.forgotPasswordSuccessType]: state => state.endOfForgotPassword(),
      [this.forgotPasswordFailureType]: (state, {payload}) => state.endOfForgotPassword(payload)
    };
  }

  protected initializeTypes() {
    super.initializeTypes();

    this.loginRequestType = `${this.name}.LOGIN_REQUEST`;
    this.loginSuccessType = `${this.name}.LOGIN_SUCCESS`;
    this.loginFailedType = `${this.name}.LOGIN_FAILURE`;
    this.logoutRequestType = `${this.name}.LOGOUT_REQUEST`;
    this.logoutSuccessType = `${this.name}.LOGOUT_SUCCESS`;
    this.validateTokenRequestType = `${this.name}.VALIDATE_TOKEN_REQUEST`;
    this.validateTokenSuccessType = `${this.name}.VALIDATE_TOKEN_SUCCESS`;
    this.validateTokenFailureType = `${this.name}.VALIDATE_TOKEN_FAILURE`;
    this.resetPasswordRequestType = `${this.name}.RESET_PASSWORD_REQUEST`;
    this.resetPasswordSuccessType = `${this.name}.RESET_PASSWORD_SUCCESS`;
    this.resetPasswordFailureType = `${this.name}.RESET_PASSWORD_FAILURE`;
    this.forgotPasswordRequestType = `${this.name}.FORGOT_PASSWORD_REQUEST`;
    this.forgotPasswordSuccessType = `${this.name}.FORGOT_PASSWORD_SUCCESS`;
    this.forgotPasswordFailureType = `${this.name}.FORGOT_PASSWORD_FAILURE`;
  }

  protected initializeActions() {
    super.initializeActions();

    this.loginRequestAction = createAction(this.loginRequestType);
    this.loginSuccessAction = createAction(this.loginSuccessType);
    this.loginFailedAction = createAction(this.loginFailedType);
    this.logoutRequestAction = createAction(this.logoutRequestType);
    this.logoutSuccessAction = createAction(this.logoutSuccessType);
    this.validateTokenRequestAction = createAction(this.validateTokenRequestType);
    this.validateTokenSuccessAction = createAction(this.validateTokenSuccessType);
    this.validateTokenFailureAction = createAction(this.validateTokenFailureType);
    this.resetPasswordRequestAction = createAction(this.resetPasswordRequestType);
    this.resetPasswordSuccessAction = createAction(this.resetPasswordSuccessType);
    this.resetPasswordFailureAction = createAction(this.resetPasswordFailureType);
    this.forgotPasswordRequestAction = createAction(this.forgotPasswordRequestType);
    this.forgotPasswordSuccessAction = createAction(this.forgotPasswordSuccessType);
    this.forgotPasswordFailureAction = createAction(this.forgotPasswordFailureType);
  }

  private checkAndChangeLanguage() {

    return (_dispatch, getState) => {

      const {authenticatedUser: {language}} = getState();

      if (language && i18n.language !== language) {
        i18n.changeLanguage(language);
      }
    };
  }

  private postAuthenticateSuccess(_response, redirect?) {
    return (dispatch) => {

      dispatch(this.checkAndChangeLanguage());

      if (redirect) {
        return dispatch(navigate(redirect));
      }
    };
  }

  private handleLoginSuccessResponse(response, accessToken, redirect) {

    return dispatch => {

      storeToken(accessToken);

      const userWithAccessToken = {
        accessToken,
        ...response.user
      };

      dispatch(this.loginSuccessAction(userWithAccessToken));

      return dispatch(this.postAuthenticateSuccess(response, redirect));
    };
  }
}

export default new AuthenticatedUserModule();
