import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {navigate} from '../modules/Location';
import ModelView, {BaseProps, BaseState} from './ModelView';
import {PaginationContext} from '../models/Pagination';
import SaveButton from './SaveButton';
import StudySiteDetails from './StudySiteDetails/StudySiteDetails';
import StudySiteModule from '../modules/StudySite';
import StudiesModule from '../modules/Studies';
import StudySite from '../models/StudySite';
import Studies from '../models/Studies';

interface Props extends BaseProps<StudySite> {
  studies: Studies;
  duplicate: boolean;
  pagination: any;
  getStudies: () => any;
}

interface State extends BaseState<StudySite> {
  touched: boolean;
  showError: boolean;
  errors: any;
}

class StudySiteView extends ModelView<StudySite, Props, State> {

  constructor(props: Props) {
    super(props);

    const errors = !this.props.model.validate();

    this.state = {

      ...this.state,
      touched: false,
      showError: false,
      errors
    };
  }

  componentDidMount() {

    super.componentDidMount();

    const {getStudies} = this.props;

    getStudies();
  }

  loadMore = () => {
    const {getStudies, studies, pagination} = this.props;
    if (!studies.isLoading && pagination.hasMore) {
      getStudies();
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {

    super.componentDidUpdate(prevProps, prevState);

    const {duplicate} = this.props;
    const {model, touched, showError} = this.state;

    if (duplicate && model.id && !touched) {

      this.updateModel(model
        .set('id', undefined)
        .set('siteNumber', undefined));
    }

    if (showError && model.isValid()) {
      this.setState({showError: false});
    }

    const getValidationErrors = () => {

      if (prevState.model !== model) {

        return model.validate();
      }

      return this.state.errors;
    };

    const errors = getValidationErrors();

    if (this.state.errors !== errors) {
      this.setState({errors});
    }

    if (showError && !errors) {
      this.setState({showError: false});
    }
  }

  onModelChange = model => {

    this.setState({
      touched: true
    });

    this.updateModel(model);
  };

  hasErrors = () => !!this.state.errors;

  onSaveStudySite = () => {
    if (this.hasErrors()) {
      this.setState({
        showError: true
      });
    }
    this.save();
  };

  getContent() {

    const {model, showError} = this.state;
    const {t, studies, pagination} = this.props;

    return (
      <>
        <StudySiteDetails
          key='study-site-details-key'
          studies={studies}
          studySite={model}
          onChange={this.onModelChange}
          showError={showError}
          hasMore={pagination.hasMore}
          loadMore={this.loadMore}
          t={t}
        />
        <SaveButton
          key='save-button'
          model={model}
          save={this.onSaveStudySite}
          onModelSaved={this.onModelSaved}
          t={t}
        />
      </>
    );
  }
}

const mapActionCreators = {
  getModel: StudySiteModule.getModel,
  saveModel: StudySiteModule.saveModel,
  resetModel: StudySiteModule.resetModel,
  getStudies: StudiesModule.getModels,
  navigate
};

const mapStateToProps = ({studySite, studySites, studies, pagination}, ownProps) => {

  const studySiteId = parseInt(ownProps.studySiteId, 10);

  const resolveModel = () => {

    if (studySite.id === studySiteId || !studySiteId) {
      return studySite;
    }

    const model = studySites.getModelById(studySiteId);

    return model ? model : new StudySite();
  };

  return {
    model: resolveModel(),
    studies,
    pagination: pagination.getPagination(PaginationContext.STUDY)
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {

  const path = '/admin/study-sites';

  const additionalProps = {
    navigateBackOnSaved: true,
    backHref: path,
    editUrl: path,
    modelId: ownProps.studySiteId,
    modelName: 'studySite',
    confirmUnsavedEntries: true
  };

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps);
};

export default withRouter(connect(
  mapStateToProps,
  mapActionCreators,
  mergeProps
)(StudySiteView));
