import * as React from 'react';
import {PureComponent} from 'react';
import Questionnaire from '../../../models/Questionnaire';
import QuestionnaireLanguage from '../../../models/QuestionnaireLanguage';
import RoundButton from '../../../components/RoundButton/RoundButton';
import {composeUrlWithStudyInfo} from '../../../util';
import classNames from 'classnames';
import Study from '../../../models/Study';

const duplicateIcon = require('../../../images/duplicate.svg');
const deleteIcon = require('../../../images/delete.svg');
const linkIcon = require('../../../images/link.svg');

interface Props {
  questionnaire: Questionnaire;
  study: Study;
  index: number;
  navigate: (url) => any;
  deleteQuestionnaire: (model) => any;
  t: (key, params?) => any;
}

interface State {
}

export default class QuestionnaireListItem extends PureComponent<Props, State> {
  onEditClick = () => {
    const {navigate, questionnaire} = this.props;
    navigate(`/admin/questionnaires/${questionnaire.id}`);
  };

  onDeleteClick = () => {
    const {questionnaire, deleteQuestionnaire} = this.props;

    deleteQuestionnaire(questionnaire);
  };

  onDuplicateClick = () => {
    const {navigate, questionnaire} = this.props;
    navigate(`/admin/questionnaires/new?duplicate=true&id=${questionnaire.id}`);
  };

  onLinkClick = () => {

    const {questionnaire, study} = this.props;
    const url = composeUrlWithStudyInfo('/questionnaires', study, `${questionnaire.id}`);

    window.open(url, '_blank');
  };

  renderLanguageStatus = (ql: QuestionnaireLanguage) => {

    const {language} = ql;
    const displayStatus = this.getLanguageDisplayStatus(ql);

    const className = classNames([
      'language-status',
      `language-status-${displayStatus}`,
      ql.default && 'default-language'
    ]);

    return (
      <span key={`${language}-language-status`} className={className}>
        {language}
      </span>
    );
  };

  getLanguageDisplayStatus = (ql: QuestionnaireLanguage) => {

    const {questionnaire} = this.props;
    const hasTranslationErrors = !!questionnaire.validateByLanguage(ql.language);

    if (!hasTranslationErrors) {

      return ql.isPublished() ? 'published-valid' : 'not-published-valid';
    }

    return 'not-published-invalid';
  };

  getLanguageStatuses = () => {

    const languages = this.props.questionnaire.getSortedLanguages();

    return languages.map(this.renderLanguageStatus);
  };

  render() {
    const {questionnaire, t, study} = this.props;

    return (
      <li className='questionnaire-list-item list-group-item'>
        <span onClick={this.onEditClick}>
          <span className='questionnaire-list-item__text'>
            {questionnaire.getDisplayName()}
          </span>
          <span className='questionnaire-list-item__text'>
            {study ? `- ${study.studyName}` : ''}
          </span>
        </span>

        <RoundButton
          iconSrc={deleteIcon}
          shape='circle'
          size='small'
          className='questionnaire-list-item__delete pull-right'
          tooltip={t('questionnaire.delete')}
          onClick={this.onDeleteClick}
          confirm={true}
        />

        <RoundButton
          iconSrc={duplicateIcon}
          shape='circle'
          size='small'
          className='questionnaire-list-item__duplicate pull-right'
          tooltip={t('questionnaire.duplicate')}
          onClick={this.onDuplicateClick}
        />

        <RoundButton
          iconSrc={linkIcon}
          shape='circle'
          size='small'
          className='questionnaire-list-item__link pull-right'
          tooltip={t('questionnaire.link')}
          onClick={this.onLinkClick}
        />

        <div className='questionnaire-list-item__lang pull-right language-statuses'>
          {this.getLanguageStatuses()}
        </div>

      </li>
    );
  }
}
