import {Type} from '../../../../models/QuestionComponent';
import {getFieldError} from '../../../../util';
import classNames from 'classnames';
import Input from '../../../../components/Input/Input';
import * as React from 'react';

interface Props {
  field: string;
  type: Type;
  options: any;
  component: any;
  validationErrors: any;
  classes: string[];
  onChange: (any) => void;
  t: (string) => string;
  readOnly?: boolean;
  label?: string;
}

export const ComponentOption = (
  {
    field,
    type,
    options,
    component,
    validationErrors,
    classes = ['col-12', 'col-sm-6'],
    onChange,
    t,
    label = field
  }: Props) => {

  const fieldError = getFieldError(`options.${field}`, validationErrors, component.error);

  const onOptionChange = value => {
    const newOptions = {...options, [field]: value};
    onChange(component.setOptions(newOptions));
  };

  const groupClassName = classes ? classNames(classes) : undefined;
  const value = options[field];

  return (
    <Input
      rowLayout={false}
      className={`question-component-option-${field}`}
      groupClassName={groupClassName}
      key={`input-question-component-option-${field}`}
      error={fieldError}
      onChange={onOptionChange}
      value={value}
      type={type}
      label={t(`questionnaire.form.componentOption.${label}`)}
      t={t}
    />
  );
};
