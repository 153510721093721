/**
 * Created by Mauritz Untamala on 08/03/2017.
 */
import {List} from 'immutable';
import * as _ from 'lodash';
import ModelInterface from './ModelInterface';
import BaseModel from './BaseModel';

const baseDefaults = {
  list: List()
};

export default function ImmutableCollection(Model, defaultValues?, allowDuplicateIds = false) {
  const withBaseDefault = _.merge({Model, modelInstance: new Model()}, baseDefaults, defaultValues);

  class ImmutableCollection<T extends ModelInterface<any>>
    extends BaseModel(withBaseDefault)<ImmutableCollection<T>> {
    Model: any;
    modelInstance: T;
    list: List<T>;

    constructor(js = {}) {
      super(_.merge({Model, modelInstance: new Model()}, js));
    }

    fromJS(_js): ImmutableCollection<T> {
      throw new Error('Unsupported. Use set, add, update model(s)');
    }

    setModelsFromJS(modelsJS) {
      const models = this.modelsJSToModels(modelsJS);

      return this.setList(List(models));
    }

    addModelFromJS(modelJS) {
      return this.addModelsFromJS([modelJS]);
    }

    addModelsFromJS(modelsJS) {
      return this.addModels(this.modelsJSToModels(modelsJS));
    }

    updateModelFromJS(modelJS) {
      const model = this.createModel(modelJS);

      return this.addModels([model], true);
    }

    getModelById(modelId) {
      return this.list.find((model) => model.hasIdentity(modelId));
    }

    removeModelsWithIds(modelIds) {
      return this.setList(this.list.filter((model) => !_.includes(modelIds, model.getId())) as List<
      T
      >);
    }

    removeModelWithId(modelId) {
      const model = this.getModelById(modelId);

      if (!model) {
        return this;
      }

      const index = this.list.indexOf(model);

      return this.setList(this.list.delete(index));
    }

    getModelByIndex(index: number): T {
      return this.list.get(index);
    }

    protected setList(list: List<T>) {
      return this.set('list', list);
    }

    private addModels(models: [T], update = false) {
      let list = this.list;

      models.forEach((model) => {

        if (allowDuplicateIds && !update) {

          list = list.push(model);

        } else {

          const current = list.find((m) => model.identityEquals(m));

          if (current) {

            const index = list.indexOf(current);

            list = list.set(index, model.setIdentityFrom(current));

          } else if (update) {

            throw new Error('No existing model found with id ' + model.getId());

          } else {

            list = list.push(model);
          }
        }
      });

      return this.setList(list);
    }

    private createModel(modelJS) {
      return _.isFunction(this.modelInstance.fromJS)
        ? this.modelInstance.fromJS(modelJS)
        : new this.Model(modelJS);
    }

    private modelsJSToModels(modelsJS) {
      if (!_.isArray(modelsJS)) {
        return [] as any;
      }

      return modelsJS.map((modelJS) => this.createModel(modelJS)) as any;
    }
  }

  return ImmutableCollection;
}
