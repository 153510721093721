import {Record} from 'immutable';
import * as _ from 'lodash';

export enum PaginationContext {
  STUDY_SITE = 'STUDY_SITE',
  STUDY = 'STUDY',
  USER = 'USER',
  QUESTIONNAIRE = 'QUESTIONNAIRE'
}

const defaultOffset = 0;
const defaultLimit = 10;
const defaultPagination = {
  offset: defaultOffset,
  limit: defaultLimit,
  hasMore: false
};

const paginationRecord = Object.keys(PaginationContext).reduce((accu, key) => {
  const obj = {};
  obj[key] = defaultPagination;
  return _.merge(accu, obj);
}, {});

export default class Pagination extends Record(paginationRecord) {
  offset: number;
  limit: number;
  hasMore: boolean;

  setPagination(
    key: PaginationContext,
    offset = defaultOffset,
    limit = defaultLimit,
    hasMore = false
  ) {
    return this.set(key, {offset, limit, hasMore});
  }

  getPagination(key: PaginationContext) {
    if (!key) {
      throw new Error('No pagination context provided');
    }

    const setting = this.get(key);

    return {
      offset: setting.offset || defaultOffset,
      limit: setting.limit || defaultLimit,
      hasMore: setting.hasMore
    };
  }
}
