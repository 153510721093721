import * as React from 'react';
import {FunctionComponent, useCallback, useState} from 'react';
import ReactCrop, {PercentCrop} from 'react-image-crop';
import {Form} from 'react-bootstrap';
import {useDropzone} from 'react-dropzone';
import RoundButton from '../RoundButton/RoundButton';
import './DropZone.less';
import Picture, {PictureType} from '../../models/Picture';
import classNames from 'classnames';
import * as _ from 'lodash';

const deleteIcon = require('../../images/delete.svg');

interface NaturalSize {
  naturalWidth: number;
  naturalHeight: number;
}

// we need our custom Crop type, because ReactCrop.Crop has all the values as optional
interface Crop {
  aspect: number;
  x: number;
  y: number;
  width: number;
  height: number;
  unit?: ReactCrop.Crop['unit'];
}

interface DropTargetProps {
  type: PictureType;
  zoneText: string;
  zoneTextSmall: string;
  maxSize: number;
  onDrop: (file: Picture) => void;
  t: (str: string) => string;
}

const DropTarget: FunctionComponent<DropTargetProps> = (props) => {
  const onDropAccepted = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const picture = new Picture({type: props.type}).setFile(file).setPreviewImage(reader.result as string);
      props.onDrop(picture);
    };
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDropAccepted,
    multiple: false,
    accept: ['image/png', 'image/jpg', 'image/jpeg'],
    maxSize: props.maxSize
  });

  return (
    <div className='drop-zone__input' {...getRootProps()}>
      <input {...getInputProps()} />
      <div className='drop-zone__text'>
        <p>{props.zoneText}</p>
        <span>{props.zoneTextSmall}</span>
      </div>
    </div>
  );
};

const isImageSizeInvalid = (size: { width: number; height: number }, min: { minWidth: number; minHeight: number }) =>
  size.width < min.minWidth || size.height < min.minHeight;

const calculateActualCrop = (percentCrop: PercentCrop, naturalSize: NaturalSize): Crop => {
  const {width, x, y, aspect} = percentCrop;
  const {naturalWidth, naturalHeight} = naturalSize;
  const newWidth = width / 100 * naturalWidth;

  return {
    aspect,
    width: newWidth,
    height: newWidth / aspect,
    x: x / 100 * naturalWidth,
    y: y / 100 * naturalHeight
  };
};

const calculatePercentageCrop = (crop: Crop, naturalSize: NaturalSize): Crop => {
  const {x, y, width, height, aspect} = crop;
  const {naturalWidth, naturalHeight} = naturalSize;

  return {
    aspect,
    x: x / naturalWidth * 100,
    y: y / naturalHeight * 100,
    width: width / naturalWidth * 100,
    height: height / naturalHeight * 100,
    unit: '%'
  };
};

interface Props {
  label?: string;
  labelClassName?: string;
  fieldClassName?: string;
  maxSize?: number;
  aspect?: number;
  minWidth: number;
  minHeight: number;
  zoneText: string;
  onChange: (picture: Picture) => void;
  type: PictureType;
  picture?: Picture;
  error?: boolean;
  hasError?: boolean;
  t;
}

const DropZone: FunctionComponent<Props> = (props) => {
  const {picture, onChange, minHeight, minWidth, t, error, hasError} = props;
  const initialCrop: ReactCrop.Crop = {
    aspect: minWidth / minHeight,
    unit: '%',
    x: 0,
    y: 0,
    width: 100
  };

  // we need natural width and height of picture to calculate actual crop sizes, and we get it from ReactCrop on image load
  const [naturalSize, setNaturalSize] = useState<NaturalSize | null>(null);

  const onRemoveImage = () => onChange(undefined);

  const handleCropChange = (crop: Crop, percentCrop: PercentCrop) => {
    if ((crop.width === 0 && crop.height === 0) || !naturalSize || crop.x < 0 || crop.y < 0) {
      return;
    }

    // we need to convert '%' to 'px' because ReactCrop doesn't respect original image size
    const actualCrop = calculateActualCrop(percentCrop, naturalSize);

    if (!_.isEqual(picture.crop, actualCrop)) {

      let updatedPicture = picture.setCrop(actualCrop).set('error', null) as Picture;

      if (isImageSizeInvalid(actualCrop, {minWidth, minHeight})) {
        updatedPicture = picture.setCrop(actualCrop).set('error', 'validate.imageTooSmall') as Picture;
      }

      onChange(updatedPicture);
    }
  };

  let crop = initialCrop;
  if (picture && picture.crop && naturalSize) {
    // when getting image from server, it has 'px' as crop unit, but we want '%'
    crop = calculatePercentageCrop(picture.crop, naturalSize);
  }

  const onImageLoaded = ({naturalWidth, naturalHeight}) =>
    setNaturalSize({naturalWidth, naturalHeight});

  // screenWidth is a way to scale down the height of ReactCrop, using natrualSize of the dropped picture and scaling down to the max height
  let screenWidth = 560;
  if (naturalSize) {
    screenWidth = (560 * naturalSize.naturalWidth) / (2.35 * naturalSize.naturalHeight);
  }

  const renderPreviewImage = () => (
    <>
      <div style={{width: `${screenWidth}px`}}>
        <ReactCrop
          className='drop-zone__preview'
          src={picture.getPictureURL() || picture.preview}
          crop={crop}
          onChange={handleCropChange}
          onImageLoaded={onImageLoaded}/>
      </div>
      <RoundButton
        iconSrc={deleteIcon}
        shape='circle'
        className='drop-zone__delete'
        size='small'
        onClick={onRemoveImage}/>
    </>
  );

  const handleDropPicture = (picture: Picture) => {
    setNaturalSize(null);
    onChange(picture);
  };

  const formGroupClassName = classNames(
    'row',
    'form-group',
    'dropzone-form',
    {'has-error': hasError && !!error}
  );

  const labelClass = classNames(
    props.labelClassName,
    {'drop-zone__label--error': hasError && !!error}
  );

  const dropZoneClassName = classNames(
    'drop-zone',
    {'has-error': hasError && !!error}
  );

  const {label, fieldClassName, zoneText, maxSize, type} = props;
  const shouldRenderPreview = picture && (picture.getPictureURL() || picture.preview);

  return (
    <Form.Group className={formGroupClassName}>
      <Form.Label className={labelClass}>{label}</Form.Label>
      <div className={fieldClassName}>
        {picture && picture.error && <p className='drop-zone__error'>{t(picture.error)}</p>}
        <div className='drop-zone-container'>
          <div className={dropZoneClassName}>
            {shouldRenderPreview ? (
              renderPreviewImage()
            ) : (<DropTarget
              type={type}
              zoneText={zoneText}
              zoneTextSmall={`${minWidth} * ${minHeight} px`}
              maxSize={maxSize}
              onDrop={handleDropPicture} t={t}
            />)
            }
          </div>
        </div>
        {hasError && error && <p className='drop-zone__error'>{t(error)}</p>}
      </div>
    </Form.Group>
  );
};

const defaultMaxSize = 1024 * 1024 * 10;

DropZone.defaultProps = {
  maxSize: defaultMaxSize
};

export default DropZone;
