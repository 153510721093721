import * as React from 'react';
import Header from '../Header/Header';
import NavItems from '../NavItems/NavItems';
import './MainNav.less';

const iconMenu = require('../../images/menu.svg');

interface Props {
  onToggleDrawer: () => any;
  header?: string;
  logoUrl?: string;
}

const MainNav: React.FunctionComponent<Props> = (props) => {

  const {onToggleDrawer, header, logoUrl} = props;

  return (
    <div className='main-nav'>
      <div className='main-nav--mobileOnly'>
        <img className='main-nav__menu' onClick={onToggleDrawer} src={iconMenu} alt='Icon Menu'/>
        <h1 className='main-nav__header'>{header}</h1>
      </div>
      <div className='main-nav--desktopOnly'>
        <Header header={header} logoUrl={logoUrl}/>
        <NavItems className='main-nav__nav' hideIcons={true} type='interactive' onToggleDrawer={onToggleDrawer}/>
      </div>
    </div>
  );
};

export default MainNav;
