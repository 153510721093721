import ModelsModule from './ModelsModule';
import QuestionnaireAnswer from './QuestionnaireAnswer';
import QuestionnaireAnswers from '../models/QuestionnaireAnswers';

const initialState = new QuestionnaireAnswers();

export class QuestionnairesModule extends ModelsModule {
  constructor() {
    super('questionnaireAnswer', initialState);
  }

  protected getAdditionalActionHandlers() {
    return {
      [QuestionnaireAnswer.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [QuestionnaireAnswer.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [QuestionnaireAnswer.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    };
  }
}

export default new QuestionnairesModule();
