import Questionnaires from '../models/Questionnaires';
import ModelsModule from './ModelsModule';
import Questionnaire from './Questionnaire';
import {createAction} from 'redux-actions';
import {update} from './ActionsCommon';
import {bind} from '../util';
import { PaginationContext } from '../models/Pagination';

const initialState = new Questionnaires();

export class QuestionnairesModule extends ModelsModule {

  private changeOrderRequestType;
  private changeOrderSuccessType;
  private changeOrderFailedType;
  private changeOrderRequestAction;
  private changeOrderSuccessAction;
  private changeOrderFailedAction;

  constructor() {
    super('questionnaire', initialState, PaginationContext.QUESTIONNAIRE);
    bind(this, this.changeOrder);
  }

  public changeOrder(questionnaireId: number, srcOrder: number, dstOrder: number, studySiteId: number) {

    return (dispatch, getState) => {

      const orderedQuestionnaires = getState().questionnaires.reOrderQuestionnaires(srcOrder, dstOrder);

      dispatch(this.changeOrderRequestAction(orderedQuestionnaires));

      const payload = {questionnaireId, srcOrder, dstOrder, studySiteId};

      return dispatch(update(this.name, 'order', undefined, payload))
        .then(response => dispatch(this.changeOrderSuccessAction(response)))
        .catch((err) => dispatch(this.changeOrderFailedAction(err)));
    };
  }

  protected getAdditionalActionHandlers() {
    return {
      [Questionnaire.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [Questionnaire.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [Questionnaire.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id),
      [this.changeOrderRequestType]: (_state, {payload}) => payload.startOfSaving(),
      [this.changeOrderSuccessType]: (state, {payload}) => state.endOfSaving().setModelsFromJS(payload),
      [this.changeOrderFailedType]: (state, {payload}) => state.endOfSaving(payload)
    };
  }

  protected initializeTypes() {
    super.initializeTypes();
    this.changeOrderRequestType = `${this.name}.CHANGE_ORDER_REQUEST`;
    this.changeOrderSuccessType = `${this.name}.CHANGE_ORDER_SUCCESS`;
    this.changeOrderFailedType = `${this.name}.CHANGE_ORDER_FAILED`;
  }

  protected initializeActions() {
    super.initializeActions();
    this.changeOrderRequestAction = createAction(this.changeOrderRequestType);
    this.changeOrderSuccessAction = createAction(this.changeOrderSuccessType);
    this.changeOrderFailedAction = createAction(this.changeOrderFailedType);
  }
}

export default new QuestionnairesModule();
