import * as React from 'react';
import QuestionView, {QuestionContext} from '../../../components/Question';
import ModelView, {BaseProps, BaseState} from '../../../components/ModelView';
import PagingControl from '../../../components/PagingControl/PagingControl';

import {withNamespaces} from 'react-i18next';

import {connect} from 'react-redux';
import {navigate} from '../../../modules/Location';
import StudySitesModule from '../../../modules/StudySites';
import QuestionnaireModule from '../../../modules/Questionnaire';
import QuestionnaireAnswerModule from '../../../modules/QuestionnaireAnswer';
import Study from '../../../models/Study';
import StudyModule from '../../../modules/Study';
import QuestionAnswer from '../../../models/QuestionAnswer';
import QuestionnaireAnswer from '../../../models/QuestionnaireAnswer';
import Questionnaire from '../../../models/Questionnaire';
import User from '../../../models/User';
import ContactDetails from '../../../models/ContactDetails';
import ContactDetailsView from './ContactDetailsView';
import ThankYouView from './ThankYouView';
import {composeUrlWithStudyInfo} from '../../../util';

import './Questionnaires.less';
import ReactGA from 'react-ga';
import {AnalyticsCategory} from '../../../config/constants';

interface Props extends BaseProps<QuestionnaireAnswer> {
  location: any;
  page: number;
  authenticatedUser: User;
  questionnaireId: number;
  questionnaire: Questionnaire;
  study: Study;
  getQuestionnaire: (id, queryParams?) => any;
  getQuestionnaireStudy: (id: number) => any;
  getStudy: (id, queryParams?) => any;
  sendContactDetails: (questionnaireAnswer: QuestionnaireAnswer, contact: ContactDetails) => any;
  checkEligibilityForStudy: (questionnaireAnswer: QuestionnaireAnswer) => any;
  navigateToPage: (page: number) => any;
  navigate: (url: string, silent?: boolean) => any;
}

interface State extends BaseState<QuestionnaireAnswer> {
  showError: boolean;
  errors: any;
  showContactPage: boolean;
}

class QuestionnaireAnswerView extends ModelView<QuestionnaireAnswer, Props, State> {

  private readonly myRef: any;

  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
    const {authenticatedUser, model, page} = props;
    const errors = authenticatedUser.authenticated ? !model.validatePersonnelFields() : !model.validatePage(page);
    this.state = {...this.state, showError: false, errors, showContactPage: true};
  }

  componentDidMount() {
    super.componentDidMount();
    const {
      questionnaire,
      questionnaireId,
      getQuestionnaire,
      getQuestionnaireStudy,
      navigate,
      modelId,
      study
    } = this.props;

    if (!questionnaireId) {
      navigate('/questionnaires');
    }

    const questionnaireIsAvailable = questionnaire.id === questionnaireId;

    // Fetch questionnaire and study site if questionnaire answer is new and questionnaire is not available
    if (!modelId && !questionnaireIsAvailable) {

      getQuestionnaire(questionnaireId);
      getQuestionnaireStudy(questionnaireId);

    } else if (questionnaireIsAvailable && questionnaire.studyId !== study.id) {
      // if questionnaire study site is not available fetch it
      getQuestionnaireStudy(questionnaireId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    const {
      questionnaire,
      questionnaireId,
      page,
      modelId,
      resetModel,
      study,
      getStudy,
      navigate
    } = this.props;

    const {model, showError} = this.state;

    // If is a new answer reset the model once questionnaire is fetched from API.
    // Persisted answers have questionnaire model already set
    if (!modelId && questionnaire.id && prevProps.questionnaire !== questionnaire) {
      resetModel(); // Reset call is overloaded in mergeProps to use questionnaire and page for reset
    }

    const {id: studyId, isLoading: studyIsLoading} = study;

    // Update URL only once needed models are loaded.
    if (
      questionnaire.id === questionnaireId &&
      questionnaire.studyId === studyId &&
      !questionnaire.isLoading && !studyIsLoading &&
      (prevProps.questionnaire.isLoading || prevProps.study.isLoading)
    ) {

      const questionnaireUrl = composeUrlWithStudyInfo('/questionnaires', study, `${questionnaire.id}/answer`);

      navigate(questionnaireUrl);
    }

    const getValidationErrors = () => {

      if (prevState.model !== model || prevProps.page !== page) {

        return model.validatePage(page);
      }

      return this.state.errors;
    };

    const errors = getValidationErrors();

    if (this.state.errors !== errors) {
      this.setState({errors});
    }

    if (showError && !errors) {
      this.setState({showError: false});
    }

    if (page !== prevProps.page) {
      this.scrollIntoView();
    }

    const shouldFetchStudy = questionnaire.studyId !== study.id;
    if (shouldFetchStudy && !study.isLoading) {

      getStudy(questionnaire.studyId);
    }
  }

  hasErrors = () => !!this.state.errors;

  scrollIntoView = () => {
    if (this.myRef) {
      this.myRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  };

  updateAnswer = updatedAnswer => this.updateModel(this.state.model.addAnswer(updatedAnswer));

  renderQuestion = question => {

    const {authenticatedUser, t} = this.props;
    const {model, showError} = this.state;
    const {questionnaire} = model;
    const {order, page} = question;
    const answer = model.getQuestionAnswer(question) || new QuestionAnswer({order, page});
    const language = questionnaire.getLanguageByPreference();

    return (
      <QuestionView
        key={`question-${order}-${page}`}
        isPersonnel={authenticatedUser.isPersonnel()}
        question={question}
        from='questionnaireAnswer'
        questionContext={QuestionContext.Normal}
        answer={answer}
        showError={showError}
        onChange={this.updateAnswer}
        language={language}
        t={t}
      />
    );
  };

  renderPageQuestions = () => {

    const {page} = this.props;
    const {model: {questionnaire: {questions}}} = this.state;

    return questions.filter(q => q.page === page)
      .sortBy(q => q.order)
      .map(this.renderQuestion)
      .toArray();
  };

  /**
   * Overridden to ask after submit invalid questionnaire answer
   *
   * @override
   *
   */
  isFormDirty = () => {
    const {model, modelWithoutChanges} = this.state;
    if (modelWithoutChanges !== model || model.error) {
      return true;
    }

    return false;
  };

  sendContactForStudyParticipant = (contact: ContactDetails) => {
    const {model} = this.state;
    const {sendContactDetails} = this.props;

    ReactGA.event({
      category: 'Questionnaire',
      action: 'Send contact for study participant'
    });

    sendContactDetails(model, contact);
  };

  cancelContact = () => {
    ReactGA.event({
      category: AnalyticsCategory.QUESTIONNAIRE,
      action: 'Cancel contact'
    });

    this.setState({showContactPage: false});
  };

  checkEligibilityForStudy() {

    const {model} = this.state;
    const {checkEligibilityForStudy} = this.props;

    checkEligibilityForStudy(model);
  };

  onFinishClicked = () => {

    if (this.hasErrors()) {

      this.setState({showError: true});
    } else {
      this.setState({
        showContactPage: true
      });

      this.checkEligibilityForStudy();
    }
  };

  onPageChange = (nextPage: number) => {
    const {navigateToPage, page} = this.props;

    if (this.hasErrors() && nextPage > page) {
      this.setState({showError: true});
    } else {
      navigateToPage(nextPage);
    }
  };

  getPagingControlHeader() {

    const {model} = this.state;
    const {questionnaire} = model;

    return (
      <div className='questionnaire-paging-header'>
        <h1 className='questionnaire-welcome'>{questionnaire.getWelcome()}</h1>
        <p className='questionnaire-description'>{questionnaire.getDescription()}</p>
        <p className='questionnaire-info'>{questionnaire.getInfo()}</p>
      </div>
    );
  }

  showEligibleThankYou = () => {

    const {model} = this.state;
    const {isEligible, isContactSent} = model;

    return isEligible && isContactSent;
  };

  showIneligibleThankYou = () => {

    const {model} = this.state;
    const {isEligible} = model;

    return isEligible === false;
  };

  showEligibleContact = () => {

    const {model} = this.state;
    const {isEligible, isContactSent} = model;
    return isEligible && !isContactSent;
  };

  getContent() {

    const {model, showContactPage} = this.state;
    const {questionnaire, isEligible} = model;
    const {page, t, study} = this.props;

    if (!questionnaire) {

      return undefined;
    }

    if (this.showEligibleContact() && showContactPage) {

      return (
        <div ref={this.myRef}>
          <ContactDetailsView
            t={t}
            questionnaire={questionnaire}
            study={study}
            onSendContact={this.sendContactForStudyParticipant}
            onCancelContact={this.cancelContact}
          />
        </div>
      );
    }
    if (this.showIneligibleThankYou() || this.showEligibleThankYou()) {

      return (
        <div ref={this.myRef} className='thank-you'>
          <ThankYouView
            socialSharing={study.socialSharing}
            isEligible={isEligible}
            content={
              isEligible
                ? study.thankYou
                : study.thankYouIneligible
            }
            button={t('button.shareStudy')}
          />
        </div>
      );
    }
    return (
      <div ref={this.myRef}>
        <PagingControl
          activePage={page}
          onFinishClicked={this.onFinishClicked}
          onPageChange={this.onPageChange}
          totalPage={questionnaire.getLastPage()}
          finishButtonDisabled={model.isSaving}
          nextButtonDisabled={this.state.showError}
        >
          {() => (
            <>
              {this.getPagingControlHeader()}
              {this.renderPageQuestions()}
            </>
          )}
        </PagingControl>
      </div>
    );
  }
}

const mapActionToProps = {
  getQuestionnaire: QuestionnaireModule.getModel,
  getModel: QuestionnaireAnswerModule.getModel,
  resetModel: QuestionnaireAnswerModule.resetQuestionnaireAnswer,
  sendContactDetails: QuestionnaireAnswerModule.sendContactDetails,
  checkEligibilityForStudy: QuestionnaireAnswerModule.checkEligibilityForStudy,
  getQuestionnaireStudy: StudyModule.getQuestionnaireStudy,
  getStudy: StudyModule.getModel,
  getStudySites: StudySitesModule.getModels,
  navigate,
  navigateToPage: QuestionnaireAnswerModule.navigateToPage
};

const resolveQuestionnaire = (questionnaires, questionnaire, questionnaireId, questionnaireAnswer) => {

  // For a persisted questionnaire answer we have the questionnaire set in model
  if (questionnaireAnswer.id) {
    return questionnaireAnswer.questionnaire;
  }

  if (questionnaire.id !== questionnaireId) {
    return questionnaires.getModelById(questionnaireId) || questionnaire;
  }

  return questionnaire;
};

const mapStateToProps = (
  {authenticatedUser, questionnaire, questionnaires, questionnaireAnswer, study},
  ownProps
) => {
  const {location, params} = ownProps;
  const {qid, id} = params;
  let {page} = location.query;
  const questionnaireId = parseInt(qid, 10);
  const modelId = id ? parseInt(id, 10) : null;
  page = page ? parseInt(page, 10) : 1;

  const resolvedQuestionnaire = resolveQuestionnaire(
    questionnaires,
    questionnaire,
    questionnaireId,
    questionnaireAnswer
  );

  return {
    authenticatedUser,
    questionnaireId,
    modelId,
    model: questionnaireAnswer,
    questionnaire: resolvedQuestionnaire,
    study,
    page,
    wrapperClass: 'questionnaire-answer-view',
    modelName: 'questionnaireAnswer',
    title: false
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {questionnaire} = stateProps;

  return {...ownProps, ...stateProps, ...dispatchProps, resetModel: () => dispatchProps.resetModel(questionnaire)};
};

export default withNamespaces(['subject'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps,
  {withRef: true}
)(QuestionnaireAnswerView));
