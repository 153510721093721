import * as React from 'react';
import {PureComponent} from 'react';
import {stringify} from 'querystring';
import * as _ from 'lodash';
import User from '../../../models/User';
import Questionnaire from '../../../models/Questionnaire';
import QuestionAnswer from '../../../models/QuestionAnswer';
import RoundButton from '../../../components/RoundButton/RoundButton';
import QuestionnaireFormRulesPage from './QuestionnaireFormRulesPage';

interface Props {
  authenticatedUser: User;
  model: Questionnaire;
  updateModel: (questionnaire) => any;
  t: (key, params?) => any;
  page: number;
  location: any;
  navigate: (url: string, silent?: boolean) => any;
}

interface State {}

export default class QuestionnaireFormRules extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (answer: QuestionAnswer) => {
    const { model, updateModel } = this.props;
    updateModel(model.synchronizeRulesByQuestionAnswer(answer));
  };

  renderTitleAndInstruction() {

    const { t } = this.props;

    return (
      <div>
        <label className='questionnaire-form-rules__rules-title'>
          {t('questionnaire.form-rules.title')}
        </label>
        <p className='questionnaire-form-rules__instruction'>
          {t('questionnaire.form-rules.instruction')}
        </p>
      </div>
    );
  }

  renderPageButtons = () => {

    const { model } = this.props;
    const pages = model.getPages();
    const buttons = pages.map(this.renderPageButton);

    return <div className='page-buttons'>{buttons}</div>;
  };

  renderPageButton = (page: number) => {

    const { t, location, navigate, page: currentPage } = this.props;

    const onClick = () => {
      const updatedQuery = stringify(_.merge({}, location.query, {page}));
      navigate(`${location.pathname}?${updatedQuery}`);
    };

    return (
      <RoundButton
        key={`page-button-${page}`}
        className='page-btn'
        size='small'
        active={page === currentPage}
        onClick={onClick}>
        {`${t('questionnaire.form.page')} ${page}`}
      </RoundButton>
    );
  };

  renderHeader() {
    return (
      <div>
        {this.renderTitleAndInstruction()}
        {this.renderPageButtons()}
      </div>
    );
  }

  renderQuestionnaireFormRulesPage = () => {

    const {authenticatedUser, model, page, t} = this.props;
    const defaultLanguage = model.getDefaultLanguageCode();

    return (
      <QuestionnaireFormRulesPage
        authenticatedUser={authenticatedUser}
        model={model}
        onChange={this.onChange}
        page={page}
        language={defaultLanguage}
        t={t}
      />
    );
  };

  render() {
    return (
      <div className='questionnaire-form-rules'>
        {this.renderHeader()}
        {this.renderQuestionnaireFormRulesPage()}
      </div>
    );
  }
}