export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_LONG = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_TIMEZONE = 'YYYY-MM-DD HH:mm:ss Z';
export const DATE_DEFAULT_TIMEZONE = 'Europe/Helsinki';

export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export enum LANGUAGE_STATUS {
  DRAFT = 'draft',
  PUBLISHED = 'published'
}

export const IDLE_TIME_LIMIT = 20; // minutes

export interface SelectOption<ValueType> {
  label: string;
  value: ValueType;
  disabled?: boolean;
  message?: string;
}

export const LOGIN_URL = '/admin/login';

export const FORGOT_PASSWORD_URL = '/admin/forgot-password';

export enum AnalyticsCategory {
  QUESTIONNAIRE = 'Questionnaire'
}
