import * as React from 'react';
import {Component} from 'react';
import App from '../../../../models/App';
import User from '../../../../models/User';
import RoundButton from '../../../../components/RoundButton/RoundButton';

const deleteIcon = require('../../../../images/delete.svg');

interface Props {
  user: User;
  app: App;
  rowData: any;
  deleteModel: (model) => any;
}

interface State {
}

export default class ActionsColumn extends Component<Props, State> {
  remove = event => {
    event.preventDefault();
    event.stopPropagation();
    const {deleteModel, rowData} = this.props;
    deleteModel(rowData._src);
  };

  getActions = () => {
    const {user, rowData} = this.props;

    if (user.isAdmin()) {
      return (<RoundButton iconSrc={deleteIcon}
        key={rowData.id}
        shape='circle'
        className='delete-button'
        size='small'
        confirm={true}
        onClick={this.remove}/>);
    }
  };

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>;
  }
}
