import * as _ from 'lodash';
import ErrorCode from './ErrorCode';
import Config from '../config';
import {parse} from 'content-type';
import {logDebug} from '../util';

class API {

  private static readonly ACCEPT_CONTENT_TYPE = 'application/json, text/plain, */*';
  private static readonly CONTENT_TYPE_JSON = 'application/json; charset=utf-8';
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  get = async (url: string, token?: string) => {
    return this.http('GET', url, token).then(res => res.data);
  };

  post = async (url: string, data?: any, token?: string, form = false) => {
    return this.http('POST', url, token, form ? data : _.omit(data, '_id'), form).then(res => {
      return _.merge(res.data, {_id: data._id});
    });
  };

  put = async (url: string, data?: any, token?: string) => {
    return this.http('PUT', url, token, data).then(res => res.data);
  };

  delete = async (url: string, token: string) => {
    return this.http('DELETE', url, token).then(res => res.data);
  };

  private isJsonResponse = (contentTypeHeader: string): boolean => {
    const type = contentTypeHeader ? parse(contentTypeHeader).type : undefined;
    return type && type.indexOf('json') !== -1;
  };

  private http = async (method: string, url: string, token?: string, data?: any, form = false) => {

    const headers = new Headers();

    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    if (data && !form) {
      headers.append('Content-Type', API.CONTENT_TYPE_JSON);
      headers.append('Accept', API.ACCEPT_CONTENT_TYPE);
    }

    const request: RequestInit = {
      method,
      headers,
      body: data ? (form ? data : JSON.stringify(data)) : undefined, // undefined is required for Edge
      credentials: 'include'
    };

    return fetch(`${this.baseUrl}${url}`, request)
      .then(response => {
        const {status, statusText, headers} = response;
        const success = status >= 200 && status < 300;

        if (this.isJsonResponse(headers.get('Content-Type'))) {
          return response.json().then(body => ({success, status, statusText, body}));
        }

        return response.text().then(body => ({success, status, statusText, body}));
      })
      .catch(error => {

        logDebug(`Exception while fetching. Error: ${error}`);

        const err = {
          code: 0,
          connectivity: false,
          text: ErrorCode.CONNECTION_ERROR,
          data: {errorCode: ErrorCode.CONNECTION_ERROR}
        };

        throw err;
      })
      .then(({success, status, statusText, body}) => {
        let extra = {};

        if (!success) {
          switch (status) {
            case 400:
              if (!body.errorCode) {
                extra = {errorCode: ErrorCode.BAD_REQUEST};
              }
              break;
            case 401:
              extra = {errorCode: ErrorCode.UNAUTHORIZED};
              break;
            case 403:
              if (!body.errorCode) {
                extra = {errorCode: ErrorCode.FORBIDDEN};
              }
              break;
            case 404:
              if (!body.errorCode) {
                extra = {errorCode: ErrorCode.NOT_FOUND};
              }
              break;
            case 409:
              if (!body.errorCode) {
                extra = {errorCode: ErrorCode.CONFLICT};
              }
              break;
            case 500:
              if (!body.errorCode) {
                extra = {errorCode: ErrorCode.INTERNAL_SERVER_ERROR};
              }
              break;
            default:
          }
        }

        return {
          code: status,
          connectivity: status !== 0,
          text: statusText,
          data: _.extend(body, extra)
        };
      })
      .then(res => {

        if (res.code === 200 || res.code === 201 || (res.code >= 300 && res.code < 400)) {

          return res;

        } else {

          throw res;
        }
      });
  };
}

export default new API(Config.API_URL);
