import Users from '../models/Users';
import ModelsModule from './ModelsModule';
import {PaginationContext} from '../models/Pagination';
import * as _ from 'lodash';
import {bind} from '../util';
import User from './User';

const initialState = new Users();

class UsersModule extends ModelsModule {
  constructor() {
    super('user', initialState, PaginationContext.USER);
    bind(this, this.getUsers);
  }

  public getUsers(role?, reset?, studyIds?) {
    let queryParams = {} as any;

    if (role) {
      queryParams = {role};
    }

    if (studyIds) {

      const getIds = () => {

        if (_.isArray(studyIds)) {
          return studyIds.reduce((accu, id) => {
            if (accu !== '') {
              accu = accu + ',';
            }
            return accu + id;
          }, '');
        }

        return studyIds;
      };

      queryParams = _.merge(queryParams, {studies: getIds()});
    }

    return this.getModels(queryParams, reset);
  }

  protected getAdditionalActionHandlers() {
    return {
      [User.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [User.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [User.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    };
  }
}

export default new UsersModule();
