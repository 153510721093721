import * as React from 'react';
import {PureComponent} from 'react';
import * as Loader from 'react-loader';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

import {connect} from 'react-redux';
import User from '../../../models/User';
import AuthenticatedUser from '../../../modules/AuthenticatedUser';
import {navigate} from '../../../modules/Location';
import {WithNamespaces, withNamespaces} from 'react-i18next';

import '../../../styles/components/authentication.less';
import {UserIsNotAuthenticated} from '../../../config/policies';
import {LOGIN_URL} from '../../../config/constants';

interface Props extends WithNamespaces {
  user: User;
  forgotPassword: (request) => any;
  reset: () => any;
  navigate: (url) => any;
}

interface State {
}

@UserIsNotAuthenticated
class ForgotPasswordView extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const {user, t, forgotPassword, navigate} = this.props;

    if (user.isLoading) {
      return (
        <div style={{height: '100%'}}>
          <Loader loaded={false} width={12} radius={35} />
        </div>
      );
    }

    if (user.forgotPasswordSuccess) {

      const onLinkClick = () => {
        navigate(LOGIN_URL);
      };

      return (
        <div className='container forgot-password-success'>
          <img src={''} alt='' />
          <p className='message'>
            {t('forgotPassword.successMessage')}
            <br/>
            {t('forgotPassword.backToLogin')}
            <span className='login-link' onClick={onLinkClick}>
              {t('here')}
            </span>
          </p>
        </div>
      );
    }

    return (
      <div className='container forgot-password'>
        <ForgotPasswordForm
          model={user}
          onSubmit={forgotPassword}
          navigate={navigate}
          t={t}
        />
      </div>
    );
  }
}

const mapActionCreators = {
  forgotPassword: AuthenticatedUser.forgotPassword,
  reset: AuthenticatedUser.resetModel,
  navigate
};

const mapStateToProps = ({authenticatedUser}, _ownProps) => ({
  user: authenticatedUser
});

export default withNamespaces(['admin'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionCreators
  )(ForgotPasswordView)
);
