import * as React from 'react';
import {Component} from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';
import {Form, FormGroup} from 'react-bootstrap';
import {ComponentProps, ComponentState, getError} from '../Question';
import {fromJS} from 'immutable';
import NumberPicker from '../../routes/Subject/Questionnaires/NumberPicker';
import {bmiCalculator} from '../../util';

const compactObject = object => _.pickBy(object, _.negate(val => _.isNull(val) || val === ''));
const compactRecord = record => fromJS(compactObject(record.toJS()));

interface Props extends ComponentProps {
}

interface State extends ComponentState {
}

export default class Bmi extends Component<Props, State> {

  calculateAndSetBMI = (height: number, weight: number) => {
    const {component, answer} = this.props;
    const {field} = component;
    const bmi = bmiCalculator(height, weight);
    const updatedValue = answer.value.set(field, {height, weight, bmi});

    this.props.onChange(answer.set('value', compactRecord(updatedValue)));
  };

  onHeightChange = height => {
    const {component: {field}, answer} = this.props;
    const value = answer.getFieldValue(field);
    const {weight} = value && value.toJS() || {} as any;

    this.calculateAndSetBMI(height, weight);
  };

  onWeightChange = weight => {
    const {component: {field}, answer} = this.props;
    const value = answer.getFieldValue(field);
    const {height} = value && value.toJS() || {} as any;

    this.calculateAndSetBMI(height, weight);
  };

  render() {
    const {component, answer, fieldError, language} = this.props;
    const languageLocalization = component.getLanguageLocalization(language)
                                 || {labelHeight: '', labelWeight: ''};
    const {labelHeight, labelWeight} = languageLocalization;

    const {field, options} = component.toJS();
    const value = answer.getFieldValue(field);
    const {
      minHeight,
      maxHeight,
      format,
      decimalDigitsHeight,
      minWeight,
      maxWeight,
      decimalDigitsWeight
    } = options || {} as Partial<NonNullable<typeof value>>;
    const {height, weight, bmi} = value && value.toJS() || {} as any;

    const parse = (strVal) => {
      strVal = strVal.replace(',', '.');
      return parseFloat(strVal);
    };

    const formGroupClass = classNames(
      'question-input',
      {'has-error': !!fieldError}
    );

    return (
      <div className='question-bmi question-input-row'>
        <FormGroup className={formGroupClass}>
          <Form.Label className='question-input__label'>
            {labelHeight} <span>(cm)</span>
          </Form.Label>
          <NumberPicker
            key={'input_height'}
            min={minHeight}
            max={maxHeight}
            onChange={this.onHeightChange}
            value={height}
            format={`${format ? format : '-##'}`}
            decimalDigits={decimalDigitsHeight || 0}
            parse={parse}
            readOnly={false}
          />
          {getError(fieldError)}
        </FormGroup>
        <FormGroup className={formGroupClass}>
          <Form.Label className='question-input__label'>
            {labelWeight} <span>(kg)</span>
          </Form.Label>
          <NumberPicker
            key={'input_weight'}
            min={minWeight}
            max={maxWeight}
            onChange={this.onWeightChange}
            value={weight}
            format={`${format ? format : '-##'}`}
            decimalDigits={decimalDigitsWeight || 0}
            parse={parse}
            readOnly={false}
          />
          {getError(fieldError)}
        </FormGroup>
        <FormGroup className={formGroupClass}>
          <Form.Label className='question-input__label'>
            BMI
          </Form.Label>
          {bmi || ''}
        </FormGroup>
      </div>
    );
  }
}
