import QuestionComponent, {Type} from '../../../../models/QuestionComponent';
import * as React from 'react';
import {PureComponent} from 'react';
import {getFieldError} from '../../../../util';
import Input from '../../../../components/Input/Input';
import IconCheck from '../../../../components/IconCheck/IconCheck';
import * as _ from 'lodash';


interface SelectOptionFieldProps {
  option: any;
  language: string;
  component: QuestionComponent;
  idx: number;
  onComponentChange: (question: QuestionComponent) => any;
  hasError?: boolean;
  t: (key, params?) => any;
}

interface SelectOptionFieldState {}

export default class SelectOptionField extends PureComponent<SelectOptionFieldProps, SelectOptionFieldState> {
  getOptions = () => {
    const {component} = this.props;
    return component.getOptions() ? component.getOptions().slice() : [];
  };

  onSelectOptionChange = value => {
    const {option, component, idx, onComponentChange, language} = this.props;
    const options = this.getOptions();

    let optionName = option;

    if (!option) {
      optionName = `option${idx}`;
      options.push(optionName);
    }

    const localization = component.getLanguageLocalization(language);

    const newLocalization = {
      [language]: typeof localization === 'object' ? {
        ...localization,
        [optionName]: value
      } : {[optionName]: value}
    };

    const newComponent = component.setLocalization(newLocalization).setOptions(options);

    onComponentChange(newComponent);
  };

  onClickRemoveOption = () => {
    const {option, component, language, onComponentChange} = this.props;
    const options = _.filter(this.getOptions(), o => o !== option);
    const localization = component.getLanguageLocalization(language);
    const newLocalization = {
      [language]: _.pick(localization, options)
    };
    const newComponent = component.setLocalization(newLocalization).setOptions(options);

    onComponentChange(newComponent);
  };

  renderSelectIcon = () => {
    const {component, idx, option, language, hasError} = this.props;
    const type = component.getType();
    const fieldError = getFieldError(
      `localization.${language}.${option}`,
      component.validateByLanguage(language),
      component.error
    );

    switch (type) {
      case Type.select:
        return <IconCheck active={!!option} type='radio' hasError={hasError && !!fieldError}/>;
      case Type.multiselect:
        return <IconCheck active={!!option} type='checkbox' hasError={hasError && !!fieldError}/>;
      default:
        return <div className='dropdown-index'>{idx + 1}.</div>;
    }
  };

  render() {
    const {idx, t, language, option, component} = this.props;
    const localization = component.getLanguageLocalization(language);
    const value = localization && localization[option];
    const fieldError = getFieldError(
      `localization.${language}.${option}`,
      component.validateByLanguage(language),
      component.error
    );

    const afterInput = !option ? null : (
      <div className='select-option__remove'>
        <i className='fa fa-times fa-lg' onClick={this.onClickRemoveOption}/>
      </div>
    );

    return (
      <Input
        key={`input-question-component-option-${option ? option : `option${idx}`}`}
        groupClassName='select-option input-field'
        label={this.renderSelectIcon}
        labelClassName='select-option__label'
        wrapperClassName='select-option__option'
        afterInput={afterInput}
        error={fieldError}
        onChange={this.onSelectOptionChange}
        value={value}
        type='text'
        placeholder={t('questionnaire.form.addOption')}
        t={t}
      />
    );
  }
}