import * as React from 'react';
import RoundButton from '../../../components/RoundButton/RoundButton';
import ReactGA from 'react-ga';
import {AnalyticsCategory} from '../../../config/constants';
import {HtmlRender} from '../../../components/HtmlRender';

const iconShare = require('../../../images/jaa.svg');

interface Props {
  content: string;
  button: string;
  isEligible: boolean;
  socialSharing: boolean;
}

interface State {
}

export default class ThankYouView extends React.PureComponent<Props, State> {

  componentDidMount() {
    ReactGA.event({
      category: AnalyticsCategory.QUESTIONNAIRE,
      action: `Show thank you ${this.props.isEligible ? 'eligible' : 'ineligible'}`
    });
  }

  renderContent = () => {

    const {content} = this.props;

    return (
      <HtmlRender html={content}/>
    );
  };

  renderButton = () => {

    const {button} = this.props;

    return (
      <RoundButton
        type="primary"
        hideTextOnMobile={false}
        iconSrc={iconShare}
        disabled={false}
        className="thank-you__share-button"
      >
        {button}
      </RoundButton>
    );
  };

  render() {
    return (
      <>
        <div className="thank-you__content">
          {this.renderContent()}
        </div>
        {this.props.socialSharing && (
          <div className="thank-you__button-container">
            {this.renderButton()}
          </div>
        )}
      </>
    );
  }
}
