import NavigationLayout from '../../layouts/NavigationLayout';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import Study from './Study';
import QuestionnaireAnswer from '../Subject/Questionnaires/QuestionnaireAnswer';
import PageNotFound from './PageNotFound';

export default {
  component: AppLayout,
  childRoutes: [
    {
      path: '/questionnaires/:studyName?/:studyNumber?/:qid?/:sub?',
      component: NavigationLayout,
      childRoutes: [
        {path: '/questionnaires/:studyName/:studyNumber/:qid', component: Study},
        {path: '/questionnaires/:studyName/:studyNumber/:qid/answer', component: QuestionnaireAnswer},
        {path: '/questionnaires/:studyName/:studyNumber/:qid/extra-info', component: Study},
      ]
    },
    {path: '/questionnaires/*', component: PageNotFound}
  ]
};
