/**
 * Created by Mauritz Untamala on 22/09/15.
 */
import * as React from 'react';
import {PureComponent} from 'react';
import UsersTable from '../UsersTable';
import * as _ from 'lodash';
import {WithNamespaces, withNamespaces} from 'react-i18next';
import {PaginationContext} from '../../models/Pagination';
import {connect} from 'react-redux';
import UsersModule from '../../modules/Users';
import {navigate} from '../../modules/Location';
import App from '../../models/App';
import Criteria from '../../models/Criteria';
import User from '../../models/User';
import Users from '../../models/Users';
import './UsersView.less';
import RoundButton from '../RoundButton/RoundButton';

interface Props extends WithNamespaces {
  app: App;
  criteria: Criteria;
  authenticatedUser: User;
  users: Users;
  location: any;
  params: object;
  pagination: any;
  getUsers: (role?, reset?, studyIds?) => any;
  updateCriteria: (location, criteria) => any;
  navigate: (url: string, silent?: boolean) => any;
}

interface State {
}

export class UsersView extends PureComponent<Props, State> {

  componentDidMount() {
    this.onCriteriaChange(this.props.criteria);
  }

  addPersonnelButtonClick = () => {
    const path = '/admin/users/create';

    this.props.navigate(path);
  };

  loadMore = () => {
    const {getUsers, users, criteria, pagination} = this.props;
    if (!users.isLoading && pagination.hasMore) {
      getUsers(criteria.getQueryParams());
    }
  };

  render() {
    const {users, t, criteria, pagination} = this.props;

    return (
      <div className='users-view-container'>
        <h1 className='view-title__list'>
          {t('usersView.title.personnel')}
        </h1>
        <UsersTable
          users={users}
          onRowClick={this.onRowClick}
          criteria={criteria}
          onCriteriaChange={this.onCriteriaChange}
          t={t}
          hasMore={pagination.hasMore}
          loadMore={this.loadMore}
          loading={users.isLoading}
        />
        <div className='user-button'>
          <RoundButton className='add-user'
            type='clear'
            onClick={this.addPersonnelButtonClick}>
            {t('usersView.addNewUser')}
          </RoundButton>
        </div>
      </div>
    );
  }

  onRowClick = rowData => {
    const {navigate} = this.props;
    const path = '/admin/users/' + rowData.id;

    navigate(path);
  };

  onCriteriaChange = criteria => {
    const {updateCriteria, location} = this.props;
    updateCriteria(location, criteria);
  };
}

const getCriteria = ({location: {query}}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '');

  return hasParams
    ? Criteria.fromQuery(query)
    : Criteria.getInitialCriteriaWithoutDateRange();
};

const mapActionCreators = {
  updateCriteria: UsersModule.updateCriteria,
  getUsers: UsersModule.getModels,
  navigate
};

const mapStateToProps = ({app, users, authenticatedUser, pagination}, ownProps) => {

  return {
    criteria: getCriteria(ownProps),
    app,
    users,
    authenticatedUser,
    pagination: pagination.getPagination(PaginationContext.USER)
  };
};

export default withNamespaces(['admin'], {wait: true})(connect(
  mapStateToProps,
  mapActionCreators
)(UsersView));
