import User from './User';
import Collection from './ImmutableCollection';
import * as _ from 'lodash';

export default class Users extends Collection(User)<User> {

  public getUserKeys() {

    return this.list
      .toJS()
      .map((user) => _.keys(user))
      .reduce((accu, keys) => _.uniq(accu.concat(keys)), []);
  }
}
