import * as React from 'react';
import {Component} from 'react';

import {WithNamespaces, withNamespaces} from 'react-i18next';

import './CookiePolicy.less';
import {connect} from 'react-redux';
import StudyModule from '../../modules/Study';
import Study from '../../models/Study';
import {HtmlRender} from '../../components/HtmlRender';

interface Props extends WithNamespaces {
  getStudy: (id) => any;
  studyId: number;
  study: Study;
}

interface State {
}

class CookiePolicy extends Component<Props, State> {

  componentDidMount() {

    const {studyId, getStudy} = this.props;

    if (studyId) {
      getStudy(studyId);
    }
  }

  render() {

    const {study} = this.props;

    const renderPrivacyPolicy = () => {

      if (!study.id) {
        return undefined;
      }

      return (
        <HtmlRender html={study.privacyPolicy}/>
      );
    };

    return (
      <div className="cookie-policy-view-container">
        <div className="container-fluid">
          <div className="panel panel-default">
            <div className="panel-body">
              <p className="policy-text">
                {renderPrivacyPolicy()}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapActionToProps = {
  getStudy: StudyModule.getModel
};

const mapStateToProps = ({study}, ownProps) => {

  const {params: {id}} = ownProps;
  const studyId = parseInt(id, 10);

  return {
    study,
    studyId
  };
};

export default withNamespaces(['admin'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(CookiePolicy));
