import * as React from 'react';
import InlineConfirmButton from 'react-inline-confirm';
import i18n from '../../services/I18n';

const ConfirmButton = ({className, onClick, children, disabled = false}) => {

  const textValues = ['', ' ' + i18n.t('button.confirm'), ''];

  return (
    <span className={'confirm-button-wrapper'}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
      }}>
      <InlineConfirmButton
        className={className}
        textValues={textValues}
        showTimer={true}
        disabled={disabled}
        onClick={onClick}
        isExecuting={false}>
        {children}
      </InlineConfirmButton>
    </span>
  );
};

export default ConfirmButton;
