import {createAction, handleActions} from 'redux-actions';
import Picture from '../models/Picture';
import API from '../services/API';

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = new Picture();

// ------------------------------------
// Constants
// ------------------------------------
export const SEND_PICTURE = 'Picture.SEND_PICTURE';
export const SEND_PICTURE_SUCCESS = 'Picture.SEND_PICTURE_SUCCESS';
export const SEND_PICTURE_FAILURE = 'Picture.SEND_PICTURE_FAILURE';
export const UPDATE_PICTURE = 'Picture.UPDATE_PICTURE';
export const UPDATE_PICTURE_SUCCESS = 'Picture.UPDATE_PICTURE_SUCCESS';
export const UPDATE_PICTURE_FAILURE = 'Picture.UPDATE_PICTURE_FAILURE';
export const RESET_PICTURE_MODEL = 'Pictures.RESET_PICTURE_MODEL';
export const GET_PICTURE_INFO = 'Picture.GET_PICTURE_INFO';
export const GET_PICTURE_INFO_SUCCESS = 'Picture.GET_PICTURE_INFO_SUCCESS';
export const GET_PICTURE_INFO_FAILURE = 'Picture.GET_PICTURE_INFO_FAILURE';

// ------------------------------------
// Actions
// ------------------------------------
export const sendPicture = createAction(SEND_PICTURE, (payload) => payload);
export const sendPictureSuccess = createAction(SEND_PICTURE_SUCCESS, (payload) => payload);
export const sendPictureFailure = createAction(SEND_PICTURE_FAILURE, (payload) => payload);
export const _updatePicture = createAction(UPDATE_PICTURE, (payload) => payload);
export const updatePictureSuccess = createAction(UPDATE_PICTURE_SUCCESS, (payload) => payload);
export const updatePictureFailure = createAction(UPDATE_PICTURE_FAILURE, (payload) => payload);
export const resetPictureModel = createAction(RESET_PICTURE_MODEL);
export const getPictureInfo = createAction(GET_PICTURE_INFO);
export const getPictureInfoSuccess = createAction(GET_PICTURE_INFO_SUCCESS);
export const getPictureInfoFailure = createAction(GET_PICTURE_INFO_FAILURE);

export const uploadPicture = (picture) => (dispatch, getState) => {
  dispatch(sendPicture);

  const formData = new FormData();
  const { type, crop, file } = picture;

  formData.append('image', file);
  formData.append('type', type);

  if (crop) {
    formData.append('crop', JSON.stringify(crop));
  }

  const {authenticatedUser} = getState();
  const {accessToken} = authenticatedUser;

  return API.post(`/picture/${type}`, formData, accessToken, true)
    .then(result => dispatch(sendPictureSuccess(result)))
    .catch(error => dispatch(sendPictureFailure(error)));
};

export const updatePicture = (picture) => (dispatch, getState) => {
  dispatch(_updatePicture(picture));

  const {authenticatedUser} = getState();
  const {accessToken} = authenticatedUser;
  const {id, type} = picture;

  return API.put(`/picture/${type}/${id}`, picture.toJS(), accessToken)
    .then(result => dispatch(updatePictureSuccess(result)))
    .catch(error => dispatch(updatePictureFailure(error)));
};

export const fetchPictureInfo = (id) => (dispatch, getState) => {
  dispatch(getPictureInfo());

  const {authenticatedUser} = getState();
  const {accessToken} = authenticatedUser;

  return API.get(`/picture/${id}/info`, accessToken)
    .then(res => dispatch(getPictureInfoSuccess(res)))
    .catch(err => dispatch(getPictureInfoFailure(err)));
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [SEND_PICTURE]: (state) => state.startOfLoading(),
  [SEND_PICTURE_SUCCESS]: (state, {payload}) => state.fromJS(payload),
  [SEND_PICTURE_FAILURE]: (state, {payload}) => state.endOfLoading(payload),
  [UPDATE_PICTURE]: (_state, {payload}) => payload.startOfSaving(),
  [UPDATE_PICTURE_SUCCESS]: (state, {payload}) => state.fromJS(payload),
  [UPDATE_PICTURE_FAILURE]: (_state, {payload: {error, model}}) => model.endOfSaving(error),
  [GET_PICTURE_INFO]: (state) => state.startOfLoading(),
  [GET_PICTURE_INFO_SUCCESS]: (state, {payload}) => state.fromJS(payload),
  [GET_PICTURE_INFO_FAILURE]: (state, {payload}) => state.endOfLoading(payload),
  [RESET_PICTURE_MODEL]: () => initialState
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
