/**
 * Created by Mauritz Untamala on 31/12/15.
 */
import {DATE_DEFAULT_TIMEZONE, DATE_FORMAT, DATE_FORMAT_TIMEZONE} from '../config/constants';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import i18n, {DEFAULT_LANGUAGE} from '../services/I18n';
import {formatDate} from '../util';

export function isEventUpdatedAfterEcrf(_value, object) {
  const updateTime = object.updateTime;
  const ecrfTransferTime = object.ecrfTransferTime;

  return !!(ecrfTransferTime && updateTime && moment(updateTime).isAfter(moment(ecrfTransferTime)));
}

export function getBooleanValue(value, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE);
  return l('boolean.' + value);
}

export function getDisabledValue(flag, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE);
  return l('boolean.' + !flag);
}

export function getTimestampFilterValue(value, rowData, field, t) {
  if (value) {
    const dateOnly = !!rowData[field + 'DateOnly'];
    return dateOnly ? formatDate(value, dateOnly) : moment(value).format(DATE_FORMAT_TIMEZONE);
  } else {
    const l = t || i18n.getFixedT(DEFAULT_LANGUAGE);
    return l('unknown');
  }
}

export function getTimestampFilterValueForReports(value, rowData, field, t?) {
  if (value) {
    const dateOnly = !!rowData[field + 'DateOnly'];
    const timezone = rowData.timezone || DATE_DEFAULT_TIMEZONE;
    return dateOnly
      ? moment(value)
        .tz(timezone)
        .format(DATE_FORMAT)
      : moment(value)
        .tz(timezone)
        .format(DATE_FORMAT_TIMEZONE);
  } else {
    const l = t || i18n.getFixedT(DEFAULT_LANGUAGE);
    return l('unknown');
  }
}

export function getTimestampSortValue(value, _rowData, _field) {
  return value
    ? moment(value)
      .toDate()
      .getTime()
    : 0;
}

export function getAdministrationTypeValue(type, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE);
  return type ? l('administrationType.' + type) : l('unknown');
}

export function getRole(value, _rowData, _field, t?) {
  return t(`userRole.${value}`);
}
