import * as React from 'react';
import SanitizedHTML from 'react-sanitized-html';
import {sanitizerAllowedAttributes, sanitizerAllowedTags} from '../util';

export interface IProps {
  html: string;
}

export const HtmlRender = ({html}) => {
  return (
    <SanitizedHTML
      className="html-render-component"
      allowedAttributes={sanitizerAllowedAttributes}
      allowedTags={sanitizerAllowedTags}
      html={html}
    />
  );
};
