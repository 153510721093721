import * as React from 'react';
import classNames from 'classnames';
import './IconCheckbox.less';

interface Props {
  type: 'checkbox' | 'radio';
  active: boolean;
  hasError?: boolean;
}

const IconCheck: React.FunctionComponent<Props> = ({type, active, hasError}) => {

  const getClassName = () => {
    return classNames(
      'icon-check',
      {'icon-check--checkbox': type === 'checkbox'},
      {'icon-check--radio': type === 'radio'},
      {'icon-check--active': active},
      {'icon-check--error': hasError}
    );
  };

  return (
    <div className={getClassName()} />
  );
};

export default IconCheck;
