import * as React from 'react';
import * as _ from 'lodash';
import {Component} from 'react';
import Input from '../../../../components/Input/Input';
import {getFieldError} from '../../../../util';
import QuestionComponent, {Type} from '../../../../models/QuestionComponent';

interface InstructionFieldProps {
  language: string;
  component: QuestionComponent;
  onChange: (question: QuestionComponent) => any;
  t: (key, params?) => any;
}

interface InstructionFieldState {
}

export default class InstructionField extends Component<InstructionFieldProps, InstructionFieldState> {

  onInstructionChange = value => {
    const {onChange, component, language} = this.props;
    const newLocalization = {};
    newLocalization[language] = value;

    onChange(component.setLocalization(newLocalization));
  };

  render() {
    const {component, language, t} = this.props;
    const field = Type.instruction;

    const fieldError = getFieldError(
      `localization.${language}`,
      component.validateByLanguage(language),
      component.error
    );
    return (
      <Input
        rowLayout={false}
        className={`component-${field}`}
        key={`input-textarea-question-component-${field}`}
        error={fieldError}
        onChange={this.onInstructionChange}
        value={component.getLanguageLocalization(language)}
        type='textarea'
        label={t('questionnaire.form.questionType.instruction')}
        t={t}
      />
    );
  };
}