import BaseModel from './BaseModel';
import Question from './Question';
import QuestionnaireRule, {RuleType} from './QuestionnaireRule';
import {Type} from './QuestionComponent';
import QuestionAnswer from './QuestionAnswer';
import I18n from '../services/I18n';
import {List} from 'immutable';
import {accumulate, getBrowserLanguage, prefixKeys, reOrder} from '../util';
import * as _ from 'lodash';
import QuestionnaireLanguage from './QuestionnaireLanguage';
import {LANGUAGE_STATUS} from '../config/constants';
import {ComponentType} from 'react';
import {QuestionnaireStatus} from './QuestionnaireStatus';

const constraints = {
  studyId: {
    presence: {allowEmpty: false}
  },
  studySiteIds: {
    presence: {allowEmpty: false},
    length: {
      minimum: 1,
      message: I18n.t('questionnaire.noStudySites')
    }
  },
  title: {
    presence: {allowEmpty: false}
  },
  status: {
    presence: {allowEmpty: false}
  },
  questions: {
    presence: {allowEmpty: false},
    length: {
      minimum: 1,
      message: I18n.t('questionnaire.noQuestion')
    }
  },
  rules: {
    presence: {allowEmpty: true}
  },
  languages: {
    presence: {allowEmpty: false},
    length: {
      minimum: 1,
      message: I18n.t('questionnaire.noLanguage')
    },
    custom: () => {
      const rule = (value) => {
        const defaultLanguage = value.filter(lang => lang.default === true);
        if (defaultLanguage.length === 0) {
          return true;
        }
        return defaultLanguage[0].status === 'published';
      };
      return {rule, message: 'questionnaire.notPublishedDefaultLanguage'};
    }
  }
};

const equalsKeys = null;

const defaultValues = {
  studyId: undefined,
  studySiteIds: undefined,
  title: undefined,
  welcome: undefined,
  description: undefined,
  info: undefined,
  status: QuestionnaireStatus.draft,
  questions: List<Question>(),
  rules: List<QuestionnaireRule>(),
  languages: List<QuestionnaireLanguage>()
};

const constraintsByLanguage = lang => {
  return _.merge(
    {},
    constraints,
    {
      [`title.${lang}`]: {
        presence: {allowEmpty: false}
      }
    }
  );
};

const commonTranslationFields = ['title', 'welcome', 'description', 'info'];

export interface TranslationDetails {
  field: string;
  value: string;
  page?: number;
  order?: number;
  type?: ComponentType;
  localizationKey?: string;
}

export default class Questionnaire
  extends BaseModel(defaultValues, equalsKeys, constraints)<Questionnaire> {
  studyId: number;
  studySiteIds: number[];
  title: any;
  welcome: any;
  description: any;
  info: any;
  status: QuestionnaireStatus;
  questions: List<Question>;
  rules: List<QuestionnaireRule>;
  languages: List<QuestionnaireLanguage>;

  constructor(js?: any) {
    super(js);

    return this.setListArray(
      [
        {questions: js => new Question(js)},
        {rules: js => new QuestionnaireRule(js)},
        {languages: js => new QuestionnaireLanguage(js)}
      ],
      js
    ) as Questionnaire;
  }

  fromJS(js: any): Questionnaire {
    return new Questionnaire(js);
  }

  getTitle() {
    return this.getLocalizedValueByKey('title');
  }

  getWelcome() {
    return this.getLocalizedValueByKey('welcome');
  }

  getDescription() {
    return this.getLocalizedValueByKey('description');
  }

  getInfo() {
    return this.getLocalizedValueByKey('info');
  }

  getDisplayName() {
    return `${this.getTitle()}`;
  }

  getAnswerQuestion(answer: QuestionAnswer) {
    const {order, page} = answer;

    return this.questions.find(q => q.order === order && q.page === page);
  }

  getQuestions(): List<Question> {
    return this.get('questions') as List<Question>;
  }

  getPageQuestions(page: number): List<Question> {
    return this.getQuestions()
      .filter(q => q.page === page)
      .sort((a, b) => a.order - b.order) as List<Question>;
  }

  getQuestion(page: number, order: number): Question {
    return this.getQuestions()
      .find(q => q.page === page && q.order === order);
  }

  updateQuestion(question: Question) {
    const list = this.getQuestions();
    const current = list.find(q => q.identityEquals(question));

    if (current) {
      const index = list.indexOf(current);

      return this.setQuestions(list.set(index, question));
    }

    throw new Error('No existing model found with id ' + question.getId());
  }

  setQuestions(questions: List<Question>) {
    return this.set('questions', questions) as Questionnaire;
  }

  addQuestion(language: string, page: number) {
    const lastEntry = this.getPageQuestions(page).last();
    const order = lastEntry ? lastEntry.getOrder() + 1 : 1;

    const question = new Question({
      title: {
        [language]: ''
      },
      page,
      order,
      components: []
    });

    return this.setQuestions(this.getQuestions().push(question));
  }

  deleteQuestion(question: Question) {
    const list = this.getQuestions()
      .filter(q => !q.identityEquals(question))
      .map(q => {
        if (q.page !== question.page || q.order < question.order) {
          return q;
        }

        return q.setOrder(q.order - 1);
      }) as List<Question>;

    return this.setQuestions(list);
  }

  duplicateQuestion(question: Question) {
    const list = this.getQuestions().map(q => {
      if (q.page !== question.page || q.order <= question.order) {
        return q;
      }

      return q.setOrder(q.order + 1);
    }) as List<Question>;

    return this.setQuestions(list.push(question.duplicateWithOrder(question.order + 1)));
  }

  getRules(): List<QuestionnaireRule> {
    return this.get('rules') as List<QuestionnaireRule>;
  }

  setRules(rules: List<QuestionnaireRule>): Questionnaire {
    return this.set('rules', rules) as Questionnaire;
  }

  resetRules(): Questionnaire {
    return this.set('rules', List<QuestionnaireRule>()) as Questionnaire;
  }

  // TODO: add support for linear number (range)
  ensureRulesIntegrity(): Questionnaire {

    const currentQuestions = this.getQuestions().toArray();
    const currentRules = this.getRules().toArray();
    let updatedRules = List<QuestionnaireRule>();

    currentQuestions.forEach(question => {

      const currentComponents = question.getComponents().toArray();

      currentComponents.forEach(component => {

        const rule = currentRules.find(rule => rule.getField() === component.getField());

        if (rule) {

          const componentOptions = component.getOptions();
          const ruleOptions = rule.getOptions();

          let updatedOptions;

          if (ruleOptions) {
            switch (component.getType()) {
              case 'slider':
              case 'number':
                updatedOptions = (ruleOptions.min >= componentOptions.min && ruleOptions.max <= componentOptions.max) ? ruleOptions : componentOptions;
                break;
              case 'bmi':
                updatedOptions = ruleOptions; // TODO add possible ranges for check based on max and mins
                break;
              case 'multiselect':
              case 'dropdown':
              case 'select':
                updatedOptions = ruleOptions.filter(o => componentOptions && componentOptions.includes(o));
                break;
              default:
                break;
            }
          }

          updatedRules = updatedRules.push(rule
            .setPage(question.getPage())
            .setOrder(question.getOrder())
            .setOptions(updatedOptions)
          );
        }
      });
    });

    return this.set('rules', updatedRules) as Questionnaire;
  }

  // TODO: add support for linear number (range)
  synchronizeRulesByQuestionAnswer(answer: QuestionAnswer): Questionnaire {

    let list = this.getRules();
    const {page, order, value} = answer;
    const valueObject = value.toObject();
    const ruleSupportedComponentTypes = QuestionnaireRule.getSupportedComponentTypes();

    const fields = _.keys(valueObject)
      .filter(key => ruleSupportedComponentTypes
        .some(type => key.startsWith(type)));

    const matchRule = (rule: QuestionnaireRule, key: string) => {
      return rule.page === page && rule.order === order && rule.field === key;
    };

    for (const field of fields) {

      const ruleExists = list
        .find(rule => matchRule(rule, field));

      if (ruleExists) {

        list = list
          .filter(rule => !matchRule(rule, field)) as List<QuestionnaireRule>;
      }

      const valueForKey = valueObject[field];

      const options = List.isList(valueForKey)
        ? valueForKey.toArray() : typeof valueForKey === 'string' ? [valueForKey]
          : valueForKey;

      if (!_.isEmpty(options)) {
        list = list.push(new QuestionnaireRule({
          page,
          order,
          field,
          type: QuestionnaireRule.resolveType(field),
          options
        }));
      }
    }
    return this.setRules(list);
  }

  // TODO: add support for linear number (range)
  mapQuestionAnswerFromRules(page: number, order: number): QuestionAnswer {

    const rules = this.getRules().filter(r => r.page === page && r.order === order);

    const reducer = (value, currentRule: QuestionnaireRule) => {

      const {field, options, type} = currentRule;

      if (!_.has(value, field)) {

        value[field] = undefined;
      }

      if (type === RuleType.MATCH_ONE_OF) {

        value[field] = [...(options as string[])];
      }

      if (type === RuleType.MATCH_ALL) {

        if (field.includes(Type.multiselect)) {

          value[field] = [...(options as string[])];
        } else {

          value[field] = options[0];
        }
      }

      if (type === RuleType.RANGE) {
        value[field] = options;
      }
      return value;
    };

    const value = rules.reduce(reducer, {});

    return _.keys(value).length > 0
      ? new QuestionAnswer({order, page, value})
      : new QuestionAnswer({order, page});
  }

  getPages(): number[] {
    return Array.from(
      new Set(
        this.getQuestions()
          .map((q: Question): number => q.page)
          .toArray()
      )
    ).sort();
  }

  getLastPage(): number {
    return _.max(this.getPages());
  }

  addPage(language: string) {
    const lastPage = this.getLastPage();
    const newQuestions = this.getQuestions().push(
      new Question({
        page: lastPage ? lastPage + 1 : 1,
        order: 1,
        title: {
          [language]: ''
        },
        components: []
      })
    );

    return this.setQuestions(newQuestions);
  }

  deletePage(page: number) {
    const newQuestions = this.getQuestions()
      .filter(question => question.page !== page)
      .map(question => {
        const pageQuestion = question.page;
        return pageQuestion > page ? question.setPage(pageQuestion - 1) : question;
      }) as List<Question>;

    return this.setQuestions(newQuestions);
  }

  isPublished() {
    return this.status === QuestionnaireStatus.published;
  }

  getDefaultLanguage() {
    return this.languages.find((language) => language.default);
  }

  setDefaultLanguage(language: string) {
    let languages = this.getLanguages();
    const isLanguageExist = languages.some((ql) => (ql.language === language));

    if (!isLanguageExist) {
      languages = languages.push(
        new QuestionnaireLanguage({language, default: true, status: LANGUAGE_STATUS.PUBLISHED})
      );
    }

    languages = languages.map((ql) => {
      return ql.language === language ? ql.setDefault(true) : ql.setDefault(false);
    }) as List<QuestionnaireLanguage>;

    return this.set('languages', languages) as Questionnaire;
  }

  getDefaultLanguageCode() {
    const defaultLanguage = this.getDefaultLanguage();

    return defaultLanguage && defaultLanguage.language;
  }

  getLanguageByPreference() {

    if (!this.id) {

      return '';
    }

    const browserLanguage = getBrowserLanguage();

    const language = this.languages.some(l => l.language === browserLanguage && l.isPublished())
      ? browserLanguage : this.getDefaultLanguage().language;

    return language;
  }

  getLanguages() {
    return this.get('languages');
  }

  hasPublishedLanguage(lang: string) {
    return this.getLanguages().some(l => l.language === lang && l.isPublished());
  }

  setAdditionalLanguages(languages: string[]) {

    const defaultLanguageCode = this.getDefaultLanguageCode();
    const existingLanguages = this.getLanguages()
      .filter(ql => ql.language === defaultLanguageCode || languages.indexOf(ql.language) !== -1);
    const existingLanguageCodes = existingLanguages.map(ql => ql.language);
    const newLanguages = languages
      .filter(language => existingLanguageCodes.indexOf(language) === -1)
      .map(language => {
        return new QuestionnaireLanguage({
          language,
          status: LANGUAGE_STATUS.DRAFT
        });
      });
    const updatedLanguages = existingLanguages.concat(newLanguages);

    return this.set('languages', updatedLanguages);
  }

  getAdditionalLanguages() {
    return this.getLanguages().filter(l => !l.default);
  }

  getLanguage(lang: string) {
    return this.getLanguages().find(l => l.language === lang);
  }

  updateLanguage(language: QuestionnaireLanguage) {

    const list = this.getLanguages();
    const current = list.find((m) => language.identityEquals(m));

    if (current) {

      const index = list.indexOf(current);

      return this.set('languages', list.set(index, language.setIdentityFrom(current)));

    } else {

      throw new Error('No existing model found with id ' + language.getId());
    }
  }

  getLanguageTranslations(language: string): TranslationDetails[] {

    const commonTranslations = commonTranslationFields.map(field => ({
      field,
      value: this.getField(field, language)
    }));

    return this.getQuestions()
      .map(question => question.getLanguageTranslations(language))
      .toArray()
      .reduce(accumulate, commonTranslations);
  }

  getSortedLanguages() {

    return List([this.getDefaultLanguage()])
      .concat(this.getSortedAdditionalLanguages());
  }

  getSortedAdditionalLanguages() {

    return this.getAdditionalLanguages()
      .sortBy(l => l.language);
  }

  getField(key: string, lang: string) {
    const field = this.get(key);

    switch (key) {
      case 'title':
      case 'welcome':
      case 'description':
      case 'info':
        return field && field[lang] ? field[lang] : '';
      default:
        return field;
    }
  }

  setField(key: string, value: any, lang: string) {
    const oldValue = this.get(key);
    let newValue;

    switch (key) {
      case 'title':
      case 'welcome':
      case 'description':
      case 'info':
        newValue = {...oldValue, [lang]: value};
        break;
      default:
        newValue = value;
    }
    return this.set(key, newValue) as Questionnaire;
  }

  reOrderQuestions(page: number, srcOrder: number, dstOrder: number) {

    const newQuestions = this.getQuestions().map((q: Question) => {

      if (q.page !== page) {
        return q;
      }

      return reOrder(q, srcOrder, dstOrder);

    }) as List<Question>;

    return this.setQuestions(newQuestions);
  }

  validateByLanguage(lang: string) {

    const questionnaireErrors = this._validate(lang ? constraintsByLanguage(lang) : constraints);
    const questionErrors = this.validateQuestionsByLanguage(lang);
    const missingTranslationErrors = this.validateMissingTranslations(lang);
    const combinedErrors = _.merge({}, questionnaireErrors, questionErrors, missingTranslationErrors);

    return !_.isEmpty(combinedErrors) ? combinedErrors : undefined;
  }

  validate() {

    const defaultLanguageErrors = this.validateByLanguage(this.getDefaultLanguageCode());
    const additionalLanguageErrors = this.validateAdditionalLanguages();
    const combinedErrors = _.merge({}, defaultLanguageErrors, additionalLanguageErrors);

    return !_.isEmpty(combinedErrors) ? combinedErrors : undefined;
  }

  initializeDefaultLanguageTranslations(initialTranslation: string) {

    let model = this as Questionnaire;
    const defaultLanguage = this.getDefaultLanguageCode();

    commonTranslationFields.forEach(field => {

      const fieldLocalization = model.get(field);

      const initializeTranslation = fieldLocalization && _.isEmpty(fieldLocalization[defaultLanguage]);
      if (initializeTranslation) {

        fieldLocalization[defaultLanguage] = initialTranslation;
        model = model.set(field, fieldLocalization) as Questionnaire;
      }
    });

    return model.setQuestions(
      model.getQuestions().map(q => q.initializeTranslations(defaultLanguage, initialTranslation)) as List<Question>
    );
  }

  cleanupTranslations() {

    const defaultLanguage = this.getDefaultLanguageCode();
    const additionalLanguages = this.getAdditionalLanguages().map(l => l.language).toArray();
    let model = this as Questionnaire;
    const languages = [defaultLanguage].concat(additionalLanguages || []);

    commonTranslationFields.forEach(field => {

      let fieldLocalization = model.get(field);

      if (fieldLocalization) {

        const obsoleteLanguages = Object.keys(fieldLocalization).filter(lang => !_.includes(languages, lang));
        fieldLocalization = _.omit(fieldLocalization, obsoleteLanguages);

        if (_.isEmpty(fieldLocalization[defaultLanguage])) {
          additionalLanguages.forEach(additionalLanguage => {
            fieldLocalization[additionalLanguage] = undefined;
          });
        }

        model = model.set(field, fieldLocalization) as Questionnaire;
      }
    });

    return model.setQuestions(
      model.getQuestions().map(q => q.cleanupTranslations(defaultLanguage, additionalLanguages)) as List<Question>
    );
  }

  private validateMissingTranslations(lang) {

    const defaultLanguage = this.getDefaultLanguageCode();

    if (lang === defaultLanguage) {
      return undefined;
    }

    const defaultLanguageTranslations = this.getLanguageTranslations(defaultLanguage);
    const otherLanguageTranslations = this.getLanguageTranslations(lang);
    const translationErrors = defaultLanguageTranslations
      .filter(l => l.value)
      .map(dl => {

        const otherLanguageTranslation = otherLanguageTranslations.find(ol => ol.field === dl.field
                                                                              && ol.page === dl.page
                                                                              && ol.order === dl.order
                                                                              && ol.localizationKey === dl.localizationKey
        );

        if (!otherLanguageTranslation || _.isEmpty(otherLanguageTranslation.value)) {
          return {[`localization.${lang}`]: [`Localization missing for value ${dl.value}`]};
        }
      })
      .filter(errors => !_.isEmpty(errors))
      .reduce((accu, value) => _.merge(accu, value), {});

    return !_.isEmpty(translationErrors) ? translationErrors : undefined;
  }

  private getLocalizedValueByKey(key) {

    const language = this.getLanguageByPreference();

    if (!language || !this[key]) {

      return '';
    }

    return this[key][language]
      ? this[key][language]
      : I18n.t('questionnaire.noLocalization', {key, language});
  }

  private validateQuestionsByLanguage(lang: string) {

    return this.questions
      .map((question, index) => prefixKeys(`questions[${index}]`, question.validateByLanguage(lang)))
      .filter(errors => !_.isEmpty(errors))
      .toArray()
      .reduce((accu, value) => _.merge(accu, value), {});
  }

  private validateAdditionalLanguages() {

    return this.getAdditionalLanguages()
      .filter(l => l.isPublished())
      .map(l => this.validateByLanguage(l.language))
      .filter(errors => !_.isEmpty(errors))
      .toArray()
      .reduce((accu, value) => _.merge(accu, value), {});
  }
}
