import * as React from 'react';
import {PureComponent} from 'react';
import ActionsColumn from './ActionsColumn';
import TableComponent from '../../../../components/TableComponent';

import * as _ from 'lodash';
import {List} from 'immutable';

import App from '../../../../models/App';
import Criteria from '../../../../models/Criteria';
import Study from '../../../../models/Study';
import User from '../../../../models/User';
import RoundButton from '../../../../components/RoundButton/RoundButton';

interface Props {
  user: User;
  app: App;
  studies: List<Study>;
  criteria: Criteria;
  deleteModel: (model) => any;
  onCriteriaChange: (criteria) => any;
  navigate: (url: string, silent?: boolean) => any;
  ref?: any;
  t: (key, params?) => any;
  hasMore?: boolean;
  loading?: boolean;
  loadMore?: () => any;
}

interface State {
}

export default class Results extends PureComponent<Props, State> {
  getResults = () =>
    this.props.studies.map(study => _.extend(study.toJS(), {_src: study})).toArray();

  getColumnMetadata = () => {
    const {t} = this.props;

    return [
      {
        columnName: 'studyNumber',
        displayName: t('column.studyNumber'),
        order: 1,
        locked: false,
        visible: true
      },
      {
        columnName: 'studyName',
        displayName: t('column.studyName'),
        order: 2,
        locked: false,
        visible: true
      },
      {
        columnName: 'actions',
        displayName: t('column.actions'),
        cssClassName: 'actions-column',
        customComponent: props => <ActionsColumn {...this.props} {...props} />,
        sortable: false
      }
    ];
  };

  showStudy = rowData => this.props.navigate('/admin/studies/' + rowData.id);

  addStudy = () => this.props.navigate('/admin/studies/new');

  onTabCriteriaChanged = tabCriteria => {
    const {criteria, onCriteriaChange} = this.props;
    onCriteriaChange(
      criteria
        .set('columnName', tabCriteria ? tabCriteria.columnName : undefined)
        .set('sort', tabCriteria ? tabCriteria.sort : undefined)
        .set('filter', tabCriteria ? tabCriteria.filter : undefined)
    );
  };

  render() {
    const {criteria, t, hasMore, loadMore, loading} = this.props;

    return (
      <div className='table-panel'>
        <TableComponent
          key='results'
          ref='results'
          data={this.getResults()}
          showFilter={true}
          rowKey={'id'}
          columns={this.getColumnMetadata()}
          useFixedHeader={true}
          initialFilter={criteria ? criteria.filter : undefined}
          initialSortColumn={criteria ? criteria.columnName : undefined}
          initialSortState={criteria ? criteria.sort : undefined}
          onRowClick={this.showStudy}
          onCriteriaChanged={this.onTabCriteriaChanged}
          t={t}
          hasMore={hasMore}
          loadMore={loadMore}
          loading={loading}
        />
        <div className='study-button'>
          <RoundButton className='add-study'
            type='clear'
            onClick={this.addStudy}>
            {this.props.t('button.addStudy')}
          </RoundButton>
        </div>
      </div>
    );
  }
}
