import * as React from 'react';
import classnames from 'classnames';
import NavButton from '../NavButton/NavButton';
import i18n from '../../services/I18n';
import './PersonnelFooter.less';

const iconLogout = require('../../images/log-out.svg');

interface Props {
  className?: string;
  onLogoutClick: () => any;
}

const PersonnelFooter: React.FunctionComponent<Props> = ({className, onLogoutClick}) => {

  return (
    <div className={classnames('personnel-footer', className)}>
      <NavButton
        iconSrc={iconLogout}
        onClick={onLogoutClick}>
        {i18n.t('button.logout')}
      </NavButton>
    </div>
  );
};

export default PersonnelFooter;
