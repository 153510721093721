import ModelModule from './ModelModule';
import Study from '../models/Study';
import {resetPictureModel, updatePicture, uploadPicture} from './Picture';
import {bind} from '../util';
import {get} from './ActionsCommon';

const initialState = new Study();

export class StudyModule extends ModelModule<Study> {

  static sendStudyCoverImage(studyImage) {

    return (dispatch, getState) => {

      return dispatch(uploadPicture(studyImage)).then(() => {

        const {picture} = getState();

        return picture;
      });
    };
  }

  public getQuestionnaireStudy(questionnaireId) {

    return (dispatch, getState) => {
      dispatch(this.getModelRequestAction());

      return dispatch(get('questionnaire', [questionnaireId, 'study']))
        .then(response => {

          const {[this.context]: model} = getState();

          if (model.isLoading) {
            return dispatch(this.getModelSuccessAction(response));
          }

        })
        .catch(error => dispatch(this.handleError(this.getModelFailedAction, error)));
    };
  }

  public saveStudy(study) {

    return async (dispatch) => {

      const {originalStudyImage, studyImage, studyImageId, originalStudyLogo, studyLogo, studyLogoId} = study;

      study = await this.processImage(dispatch, study, 'studyLogo', studyLogoId, studyLogo, originalStudyLogo);
      study = await this.processImage(dispatch, study, 'studyImage', studyImageId, studyImage, originalStudyImage);

      return dispatch(this.saveModel(study));
    };
  }

  constructor() {
    super('study', initialState);
    bind(this, this.saveStudy, this.getQuestionnaireStudy);
  }

  /**
   * @override
   */
  protected deleteSuccessMessageProps({name}) {
    return {name};
  }

  private async processImage(dispatch, study, field, imageId, image, originalImage) {

    if (image && image.file) {

      const result = await dispatch(StudyModule.sendStudyCoverImage(image));
      const imageId = result.id;
      const imageIdField = `${field}Id`;
      study = study.set(field, null).set(imageIdField, imageId);
      await dispatch(resetPictureModel);

    } else if (imageId && originalImage && originalImage.id === imageId && !!image.crop && originalImage.crop !== image.crop) {
      // User updates crop of study image
      await dispatch(updatePicture(image));
    }

    const originalField = `original${field.charAt(0).toUpperCase() + field.slice(1)}`;

    return study.set(originalField, null);
  }
}

export default new StudyModule();
