import {createAction, handleActions} from 'redux-actions';
import Pagination, {PaginationContext} from '../models/Pagination';
// ------------------------------------
// Initial state
// ------------------------------------

const initialState = new Pagination();

// ------------------------------------
// Constants
// ------------------------------------
export const SET_PAGINATION = 'Pagination.SET_PAGINATION';
export const RESET_PAGINATION = 'Pagination.RESET_PAGINATION';

// ------------------------------------
// Actions
// ------------------------------------
const setPaginationAction = createAction(SET_PAGINATION);
const resetPaginationAction = createAction(RESET_PAGINATION);

// ------------------------------------
// Reducer
// ------------------------------------

export const setPagination = (context: PaginationContext, pagination) => {
  return (dispatch) => dispatch(setPaginationAction({context, pagination}));
};

export const resetPagination = (context: PaginationContext) => {
  return (dispatch) => dispatch(resetPaginationAction(context));
};

const ACTION_HANDLERS = {
  [SET_PAGINATION]: (
    state,
    {
      payload: {
        context,
        pagination: {offset, limit, hasMore}
      }
    }
  ) => {
    return state.setPagination(context, offset, limit, hasMore);
  },
  [RESET_PAGINATION]: (state, {payload}) => state.setPagination(payload)
};

export default handleActions(ACTION_HANDLERS, initialState);
