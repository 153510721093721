import * as React from 'react';
import {Component} from 'react';

import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import './AppLayout.less';

interface Props {
  location: any;
  redirectUrl: string;
  push: any;
}

interface State {
}

class AppLayout extends Component<Props, State> {
  componentDidMount() {
    const {redirectUrl, push} = this.props;

    if (redirectUrl) {
      push(redirectUrl);
    }
  }

  render() {
    return <div className='main'>{this.props.children}</div>;
  }
}

const mapActionCreators = {push};

const mapStateToProps = (
  _state,
  {
    location: {
      query: {redirect}
    }
  }
) => {
  return {
    redirectUrl: redirect
  };
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(AppLayout);
