import BaseModel from './BaseModel';
import I18n from '../services/I18n';
import {List} from 'immutable';
import QuestionComponent from './QuestionComponent';
import {accumulate, prefixKeys} from '../util';
import * as _ from 'lodash';
import OrderedModel from './OrderedModel';

const constraints = {
  order: {
    presence: {allowEmpty: false}
  },
  page: {
    presence: {allowEmpty: false}
  },
  title: {
    presence: {allowEmpty: false}
  },
  components: {
    presence: {allowEmpty: true},
    length: {
      minimum: 0
    }
  }
};

const equalsKeys = ['order', 'page'];

const defaultValues = {
  order: undefined,
  page: undefined,
  title: undefined,
  components: List()
};

const constraintsByLanguage = lang => {
  return _.merge(
    {},
    constraints,
    {
      [`title.${lang}`]: {
        presence: {allowEmpty: false}
      }
    }
  );
};

export default class Question
  extends BaseModel(defaultValues, equalsKeys, constraints)<Question>
  implements OrderedModel<Question> {
  page: number;
  order: number;
  title: object;
  components: List<QuestionComponent>;

  constructor(js?: any) {
    super(js);

    return this.setListArray([{components: js => new QuestionComponent(js)}], js) as Question;
  }

  fromJS(js: any): Question {
    return new Question(js);
  }

  getTitle(language: string) {
    return this.getLocalizedValueByKey('title', language);
  }

  getOrder() {
    return this.get('order');
  }

  setOrder(order: number) {
    return this.set('order', order) as Question;
  }

  getPage() {
    return this.get('page');
  }

  setPage(page: number) {
    return this.set('page', page) as Question;
  }

  addComponent(language: string) {
    const component = new QuestionComponent({type: ''})
      .setDefaultOptions()
      .setDefaultLocalization(language);

    return this.set('components', this.getComponents().push(component)) as Question;
  }

  getComponent(field: string): QuestionComponent {
    return this.getComponents().find(c => c.getField() === field);
  }

  getComponents(): List<QuestionComponent> {
    return this.get('components') as List<QuestionComponent>;
  }

  setComponents(components: List<QuestionComponent>) {
    return this.set('components', components) as Question;
  }

  getField(key: string, lang: string) {
    const field = this.get(key);

    switch (key) {
      case 'title':
        return field && field[lang] ? field[lang] : '';
      default:
        return field;
    }
  }

  setField(key: string, value: any, lang: string) {
    const oldValue = this.get(key);
    let newValue;

    switch (key) {
      case 'title':
        newValue = {...oldValue, [lang]: value};
        break;
      default:
        newValue = value;
    }
    return this.set(key, newValue) as Question;
  }

  validateByLanguage(lang: string) {

    const questionErrors = this._validate(lang ? constraintsByLanguage(lang) : constraints);
    const componentErrors = this.validateComponentsByLanguage(lang);
    const combinedErrors = _.merge({}, questionErrors, componentErrors);

    return !_.isEmpty(combinedErrors) ? combinedErrors : undefined;
  }

  updateComponent(model: QuestionComponent): Question {
    const list = this.getComponents();
    const current = list.find(m => model.identityEquals(m));

    if (current) {
      const index = list.indexOf(current);
      return this.set('components', list.set(index, model.setIdentityFrom(current))) as Question;
    }

    throw new Error('No existing model found with id ' + model.getId());
  }

  removeComponent(model: QuestionComponent) {
    const list = this.getComponents();
    const current = list.find(m => model.identityEquals(m));

    if (current) {
      const index = list.indexOf(current);
      return this.set('components', list.remove(index));
    } else {
      throw new Error('No existing model found with id ' + model.getId());
    }
  }

  duplicateWithOrder(order: number): Question {
    return this.duplicate()
      .setOrder(order)
      .setComponents(this.getComponents().map(c => c.duplicate()) as List<QuestionComponent>);
  }

  hasMandatoryFields() {
    return this.getComponents().some(c => c.isMandatory());
  }

  initializeTranslations(language: string, initialTranslation: string) {

    let model = this as Question;
    const titleLocalization = this.get('title');

    const initializeTranslation =  titleLocalization && _.isEmpty(titleLocalization[language]);
    if (initializeTranslation) {

      titleLocalization[language] = initialTranslation;
      model = model.set('title', titleLocalization) as Question;
    }

    return model.setComponents(model.getComponents()
      .map(c => c.initializeTranslations(language, initialTranslation)) as List<QuestionComponent>) as Question;
  }

  cleanupTranslations(defaultLanguage: string, additionalLanguages: string[]) {

    const titleLocalization = this.get('title');
    let model = this as Question;

    if (_.isEmpty(titleLocalization && titleLocalization[defaultLanguage])) {

      model = model.set('title', undefined) as Question;

    } else {

      const languages = [defaultLanguage].concat(additionalLanguages || []);
      const obsoleteLanguages = Object.keys(titleLocalization).filter(lang => !_.includes(languages, lang));

      model = model.set('title', _.omit(titleLocalization, obsoleteLanguages)) as Question;
    }

    return model.setComponents(model.getComponents()
      .map(c => c.cleanupTranslations(defaultLanguage, additionalLanguages)) as List<QuestionComponent>) as Question;
  }

  getLanguageTranslations(language) {

    const page = this.getPage();
    const order = this.getOrder();
    const field = 'title';
    const titleTranslation = {
      field,
      value: this.getField(field, language)
    };

    return this.getComponents()
      .filter(c => c.isLocalized())
      .map(c => c.getLanguageTranslations(language))
      .filter(translations => !_.isEmpty(translations))
      .toArray()
      .reduce(accumulate, [titleTranslation])
      .map(translation => ({page, order, ...translation}));
  }

  private getLocalizedValueByKey(key: string, language: string) {

    if (!this[key]) {

      return '';
    }

    return this[key][language]
      ? this[key][language]
      : I18n.t('questionnaire.noLocalization', {key, language});
  }

  private validateComponentsByLanguage(lang: string) {

    return this.getComponents()
      .map((component, index) => prefixKeys(`components[${index}]`, component.validateByLanguage(lang)))
      .filter(errors => !_.isEmpty(errors))
      .toArray()
      .reduce((accu, value) => _.merge(accu, value), {});
  }
}
