/**
 * Created by Mauritz Untamala on 22/09/15.
 */
import * as React from 'react';
import ModelView, {BaseProps, BaseState} from './ModelView';
import UserDetails from './UserDetails';
import SaveButton from './SaveButton';

import * as _ from 'lodash';

import {connect} from 'react-redux';
import {navigate} from '../modules/Location';
import {withRouter} from 'react-router';
import User from '../models/User';

import '../styles/components/users.less';
import Study from '../models/Study';

interface Props extends BaseProps<User> {
  minimalDetails: boolean;
  profile: boolean;
  newUser: boolean;
  study: Study;
}

interface State extends BaseState<User> {
  showError: boolean;
  errors: any;
}

class UserView extends ModelView<User, Props, State> {
  constructor(props: Props) {
    super(props);
    const errors = !this.props.model.validate();
    this.state = {
      ...this.state,
      showError: false,
      errors
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

    super.componentDidUpdate(prevProps, prevState);
    const {model, showError} = this.state;

    if (showError && model.isValid()) {
      this.setState({showError: false});
    }

    const getValidationErrors = () => {

      if (prevState.model !== model) {

        return model.validate();
      }

      return this.state.errors;
    };

    const errors = getValidationErrors();

    if (this.state.errors !== errors) {
      this.setState({errors});
    }

    if (showError && !errors) {
      this.setState({showError: false});
    }
  }

  hasErrors = () => !!this.state.errors;

  onSaveUser = () => {
    if(this.hasErrors()) {
      this.setState({
        showError: true
      });
    }
    this.save();
  };

  getContent() {
    const {t, minimalDetails, profile} = this.props;
    const {model, showError} = this.state;

    return (
      <>
        <UserDetails
          key={'user-details' + model._id}
          user={model}
          onChange={this.updateModel}
          isProfile={profile}
          minimalDetails={minimalDetails}
          showError={showError}
          t={t}
        />
        <SaveButton
          key='save-button'
          model={model}
          save={this.onSaveUser}
          onModelSaved={this.onModelSaved}
          t={t}
        />
      </>
    );
  }
}

const mapStateToProps = ({study}) => {

  return {study};
};

const mapActionToProps = {
  navigate
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {

  const {profile, minimalDetails, user, userId} = ownProps;

  const backHref = profile || minimalDetails
    ? null
    : '/admin/users';

  const additionalProps = {
    wrapperClass: 'user-view',
    modelId: userId,
    model: user,
    modelName: profile ? 'profile' : 'user',
    navigateBackOnSaved: !!backHref,
    backHref,
    confirmUnsavedEntries: true
  };

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps);
};

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps
)(UserView));
