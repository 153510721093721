import AdminNavigationLayout from '../../layouts/AdminNavigationLayout';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import Profile from './Profile';
import Studies from './Studies';
import StudySites from './StudySites';
import Study from './Study';
import Personnel from './Personnel/Personnel';
import User from './User';
import StudySite from './StudySite';
import Questionnaires from './Questionnaires/Questionnaires';
import Questionnaire from './Questionnaires/Questionnaire';
import AuthenticationRoute from '../Authentication';
import {UserIsAuthenticated} from '../../config/policies';
import UserCreate from './User/UserCreate';
import StartHere from './StartHere';

export default {
  component: AppLayout,
  indexRoute: {onEnter: (_nextState, replace) => replace('/admin/start')},
  path: 'admin',
  childRoutes: [
    AuthenticationRoute,
    {
      component: UserIsAuthenticated(AdminNavigationLayout),
      childRoutes: [
        {path: 'start', component: StartHere},
        {path: 'profile', component: Profile},
        {path: 'studies', component: Studies},
        {path: 'study-sites', component: StudySites},
        {path: 'users', component: Personnel},
        {path: 'questionnaires', component: Questionnaires},
        {path: 'studies/new', component: UserIsAuthenticated(Study)},
        {path: 'studies/:id', component: UserIsAuthenticated(Study)},
        {path: 'study-sites/new', component: UserIsAuthenticated(StudySite)},
        {path: 'study-sites/:id', component: UserIsAuthenticated(StudySite)},
        {path: 'users/create', component: UserCreate},
        {path: 'users/:id', component: UserIsAuthenticated(User)},
        {path: 'questionnaires/new', component: UserIsAuthenticated(Questionnaire)},
        {path: 'questionnaires/:qid', component: UserIsAuthenticated(Questionnaire)}
      ]
    }
  ]
};
