import * as React from 'react';
import './PersonnelHeader.less';

const logo = require('../../images/logo.svg');

const PersonnelHeader = () => {

  return (
    <div className='personnel-header'>
      <img className='personnel-header__logo' src={logo} alt='CliniScout Recruit logo'/>
    </div>
  );
};

export default PersonnelHeader;
