import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';
import PagingIndicator from '../PagingIndicator/PagingIndicator';
import './PagingIndicators.less';

interface Props {
  activeIndex: number;
  total: number;
  className?: string;
}

const PagingIndicators: React.FunctionComponent<Props> = (props) => {

  const {total, className, activeIndex} = props;

  return (
    <div className={classnames('paging-indicators', className)}>
      {_.times(total, (index) =>
        (<PagingIndicator
          key={index}
          className='paging-indicators__indicator'
          active={index === activeIndex}/>))
      }
    </div>
  );
};

export default PagingIndicators;
