import * as React from 'react';
import Input from '../../../components/Input/Input';
import RoundButton from '../../../components/RoundButton/RoundButton';
import ContactDetails from '../../../models/ContactDetails';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import {getFieldError} from '../../../util';
import ReactGA from 'react-ga';
import {AnalyticsCategory} from '../../../config/constants';
import SelectCustom from '../../../components/SelectInput/SelectCustom';
import StudySites from '../../../models/StudySites';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import StudySitesModule from '../../../modules/StudySites';
import {PaginationContext} from '../../../models/Pagination';
import Questionnaire from '../../../models/Questionnaire';
import Study from '../../../models/Study';
import {HtmlRender} from '../../../components/HtmlRender';


interface Props {
  t: any;
  onSendContact: (contact: ContactDetails) => any;
  onCancelContact: () => void;
  questionnaire: Questionnaire;
  study: Study;
  studySites: StudySites;
  fetchStudySites: (ids: number[]) => void;
  hasMoreStudySites: boolean;
}

interface State {
  contact: ContactDetails;
}

class ContactDetailsView extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      contact: new ContactDetails()
    };
  }

  componentDidMount() {
    ReactGA.event({
      category: AnalyticsCategory.QUESTIONNAIRE,
      action: 'Ask contact details'
    });

    const {fetchStudySites, questionnaire} = this.props;

    if (questionnaire.studySiteIds.length > 1) {
      fetchStudySites(questionnaire.studySiteIds);
    } else if (questionnaire.studySiteIds.length) {
      this.onStudySiteIdChange(questionnaire.studySiteIds[0]);
    }
  }

  componentDidUpdate() {
    const {questionnaire} = this.props;
    const {contact} = this.state;

    if (questionnaire.studySiteIds.length === 1 && contact.selectedStudySiteId !== questionnaire.studySiteIds[0]) {
      this.onStudySiteIdChange(questionnaire.studySiteIds[0]);
    }
  }

  onContactFieldChange = (field: string) => (value: string | number) => {
    this.setState((prevState, _) => ({
      contact: prevState.contact.set(field, value) as ContactDetails
    }));
  };

  // tslint:disable: member-ordering
  onStudySiteIdChange = this.onContactFieldChange('selectedStudySiteId');
  onNameChange = this.onContactFieldChange('name');
  onAddressChange = this.onContactFieldChange('address');
  onEmailChange = this.onContactFieldChange('email');
  // tslint:enable: member-ordering

  onPhoneNumberChange = (value: string) => {
    const newValue = value.match(/(\d|\+)+/g).join('');
    this.onContactFieldChange('phoneNumber')(newValue === '+' ? null : newValue);
  };

  renderPhoneNumberField = (field: string, onChange: (value: string) => any) => {

    const {t} = this.props;
    const {contact} = this.state;
    const value = contact.get(field);
    const error = getFieldError(field, contact.validate(), undefined);

    return (
      <PhoneNumberInput
        key="input-phone-number"
        labelClassName="contact-details__form__input-label"
        modelId={1}
        field={`contactField.${field}`}
        value={value || ''}
        error={error}
        onChange={onChange}
        t={t}
        isRow={false}
      />
    );
  };

  renderContactField = (field: string, placeholder: string, onChange: (value: string) => any) => {
    const {t} = this.props;
    const {contact} = this.state;
    const fieldError = getFieldError(field, contact.validate(), undefined);

    return (
      <Input
        rowLayout={false}
        error={fieldError}
        label={t(`contactField.${field}`)}
        labelClassName="contact-details__form__input-label"
        type="text"
        placeholder={placeholder}
        value={contact.get(field) || ''}
        onChange={onChange}
        t={t}
      />
    );
  };

  onSendContact = () => {
    const {contact} = this.state;
    this.props.onSendContact(contact);
  };

  onCancelContact = () => {
    this.props.onCancelContact();
  };

  getStudySiteOptions = () => {

    const {studySites} = this.props;

    return studySites.list
      .map(({id, siteName, siteNumber}) => ({
        label: `${siteName} - ${siteNumber}`,
        value: id
      }))
      .toArray();
  };


  loadMoreStudySites = () => {
    const {fetchStudySites, studySites, hasMoreStudySites, questionnaire} = this.props;

    if (!studySites.isLoading && questionnaire.studySiteIds && hasMoreStudySites) {
      fetchStudySites(questionnaire.studySiteIds);
    }
  };

  renderStudySiteSelectInput = () => {
    const {t, hasMoreStudySites, questionnaire} = this.props;

    if (questionnaire.studySiteIds.length === 1) {
      return undefined; // No selection for single options
    }

    const field = 'studySite';
    const options = this.getStudySiteOptions();
    const value = this.state.contact.selectedStudySiteId
      ? options.find(option => option.value === this.state.contact.selectedStudySiteId)
      : null;

    return (
      <div key="select-study-site" className="form-group">
        <label className="form-label contact-details__form__input-label">
          {t('contactField.selectedStudySiteId')}
        </label>
        <SelectCustom
          key={`select-${field}`}
          name={field}
          value={value}
          options={options}
          onChange={option => this.onStudySiteIdChange(option.value)}
          hasMore={hasMoreStudySites}
          loadMore={this.loadMoreStudySites}
          t={t}
        />
      </div>
    );
  };

  render() {
    const {t, study} = this.props;
    const {contact} = this.state;

    return (
      <div className="contact-details">
        <HtmlRender html={study.qualifiedInfo}/>
        <div className="contact-details__form">
          {this.renderStudySiteSelectInput()}
          {this.renderContactField('name', '', this.onNameChange)}
          {study.askForAddress && this.renderContactField('address', '', this.onAddressChange)}
          {this.renderPhoneNumberField('phoneNumber', this.onPhoneNumberChange)}
          {this.renderContactField('email', '', this.onEmailChange)}
        </div>
        <div className="contact-details__button-container">
          <RoundButton
            type="primary"
            hideTextOnMobile={false}
            disabled={false}
            className="contact-details__cancel-button"
            onClick={this.onCancelContact}>
            {t('button.cancel')}
          </RoundButton>
          <RoundButton
            type="success"
            hideTextOnMobile={false}
            disabled={!contact.isValid(study.askForAddress)}
            className="contact-details__send-button"
            onClick={this.onSendContact}>
            {t('button.send')}
          </RoundButton>
        </div>
      </div>
    );
  }
}

const mapActionToProps = {
  fetchStudySites: StudySitesModule.fetchStudySites
};

const mapStateToProps = ({studySites, pagination}, _ownProps) => {

  return {
    studySites,
    hasMoreStudySites: pagination.getPagination(PaginationContext.STUDY_SITE).hasMore
  };
};

export default withNamespaces(['subject', 'admin', 'common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(ContactDetailsView));
