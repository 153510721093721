import * as React from 'react';
import {PureComponent} from 'react';
import Toggle from 'react-toggle';
import Input from '../../../components/Input/Input';
import QuestionPage from './QuestionPage';
import * as _ from 'lodash';
import classNames from 'classnames';
import {stringify} from 'querystring';
import {enumToValues, getFieldError} from '../../../util';
import {List} from 'immutable';
import {SelectOption} from '../../../config/constants';

import Question from '../../../models/Question';
import Questionnaire from '../../../models/Questionnaire';
import User from '../../../models/User';
import StudyModel from '../../../models/Study';
import StudiesModel from '../../../models/Studies';
import StudySitesModel from '../../../models/StudySites';
import {QuestionnaireStatus} from '../../../models/QuestionnaireStatus';

import './Questionnaires.less';
import RoundButton from '../../../components/RoundButton/RoundButton';
import SelectCustom from '../../../components/SelectInput/SelectCustom';

const iconPlus = require('../../../images/plus.svg');
const iconDelete = require('../../../images/delete.svg');
const roundAddIcon = require('../../../images/round-add.svg');

interface Props {
  authenticatedUser: User;
  page: number;
  duplicated: boolean;
  study: StudyModel;
  studies: StudiesModel;
  studySites: StudySitesModel;
  location: any;
  model: Questionnaire;
  modelName: string;
  showError: boolean;
  validationErrors: any;
  hasMoreStudies?: boolean;
  loadMoreStudies?: () => any;
  hasMoreStudySites?: boolean;
  loadMoreStudySites?: () => any;
  onStudyChange: (study: StudyModel) => any;
  updateModel: (questionnaire) => any;
  navigate: (url: string, silent?: boolean) => any;
  t: (key, params?) => any;
}

interface State {
  pageQuestions: List<Question>;
}

type LanguageOption = SelectOption<string>;

enum FormFields {
  study = 'study',
  studySites = 'studySites',
  status = 'status',
  languages = 'languages',
  title = 'title',
  welcome = 'welcome',
  description = 'description',
  info = 'info',
  questions = 'questions'
}

const fieldInputs = enumToValues(FormFields);

class QuestionnaireForm extends PureComponent<Props, State> {

  private static readonly fieldsWithPlaceholder = [
    'welcome',
    'description',
    'info'
  ];

  constructor(props) {
    super(props);

    const {model, page} = props;
    this.state = {pageQuestions: model.getPageQuestions(page)};
  }

  componentDidUpdate(prevProps, prevState) {

    const {model, page} = this.props;
    const {pageQuestions} = this.state;

    if (prevState.model !== model || prevProps.page !== page) {
      const latestPageQuestions = model.getPageQuestions(page);

      if (!pageQuestions.equals(latestPageQuestions)) {
        this.setState({pageQuestions: latestPageQuestions});
      }
    }
  }

  updateModel = (model) => this.props.updateModel(model);

  getLanguageOption = (languageCode: string): LanguageOption => {
    const {t} = this.props;
    return {
      label: t(`language.${languageCode}`),
      value: languageCode
    };
  };

  getStudyLanguageOptions = () => {
    const {study} = this.props;

    return study.languages.toArray().map(this.getLanguageOption);
  };

  getStudyOptions = () => {

    const {studies} = this.props;

    const options = studies.list
      .map(({id, studyName, studyNumber}) => ({
        label: `${studyName} - ${studyNumber}`,
        value: id
      }))
      .toArray();

    return options;
  };

  getStudySiteOptions = () => {

    const {studySites} = this.props;

    const options = studySites.list
      .map(({id, siteName, siteNumber}) => ({
        label: `${siteName} - ${siteNumber}`,
        value: id
      }))
      .toArray();

    return options;
  };

  onStudyChangeCallback = (studyOption) => {

    const {model, studies, onStudyChange} = this.props;
    const {value: studyId} = studyOption;
    const selectedStudy = studies.list.find(study => study.id === studyId);

    if (selectedStudy) {
      this.updateModel(model.set('studyId', studyId));
      onStudyChange(selectedStudy);
    }
  };

  onStudySiteChangeCallback = (studySiteOptions) => {

    const {model, studySites} = this.props;
    const studySiteIds = studySiteOptions && studySiteOptions.map(option => option.value).filter((studySiteId) => {
      return studySites.list.find(studySite => studySite.id === studySiteId);
    }) || [];

    this.updateModel(model.set('studySiteIds', studySiteIds));
  };

  onFieldChangeCallback = (field, value) => {
    const {model} = this.props;
    const language = model.getDefaultLanguageCode();

    this.updateModel(model.setField(field, value, language));
  };

  getRowsForField = field => field === 'info' ? 3 : undefined;

  renderDefaultLanguageSelectInput = (
    writeAccess: boolean,
    onChange: (option) => any,
    multi?: boolean,
    placeholder?: string
  ) => {
    const {t, model, validationErrors, showError} = this.props;
    const field = 'languages';
    const fieldError = getFieldError(
      field,
      validationErrors,
      model.error
    );
    const options = this.getStudyLanguageOptions();
    const defaultLanguage = model.getDefaultLanguage();
    const languages = model.getLanguages().toArray();

    const unselected = options.filter(option => languages.length > 0 && !languages.find(l => l.language === option.value));

    if (options.length > 1 && unselected.length > 0) {
      unselected.map(item => {
        item.disabled = true;
        item.message = t('questionnaire.form.disabledOptionMessage');
      });
    }

    const value = defaultLanguage && options.find((option) => option.value === defaultLanguage.language);
    const formGroupClasses = classNames({
      'row': true,
      'default-language': true,
      'form-group': true,
      'has-error': showError && !!fieldError
    });

    const getLabelClassName = (fieldError) => {
      return classNames(
        'col-5 col-sm-4',
        'form-label',
        'select-input__label',
        {'select-input__label--error': showError && !!fieldError}
      );
    };

    return (
      <div key='select-default-language' className={formGroupClasses}>
        <label className={getLabelClassName(fieldError)} htmlFor='select-default-language'>
          {t('questionnaire.form.defaultLanguage')}
        </label>
        <div key='select-default-language-wrapper' id='select-default-language' className='col-7 col-sm-8'>
          <SelectCustom
            key={`select-${field}`}
            isDisabled={!writeAccess}
            name={field}
            value={_.isEmpty(value) ? null : value}
            isMulti={!!multi}
            options={options}
            onChange={onChange}
            placeholder={placeholder}
            hasError={showError && !!fieldError}
            t={t}
          />
          <span className='help-block'>
            {fieldError && showError && (<div className='error'>{t(fieldError)}</div>)}
          </span>
        </div>
      </div>
    );
  };

  renderStudySelectInput = () => {
    const {t, model: {id, studyId}, hasMoreStudies, loadMoreStudies} = this.props;
    const field = 'study';
    const isDisabled = !!id; // studySiteId can't be changed after questionnaire is created
    const options = this.getStudyOptions();
    const value = studyId
      ? options.find(option => option.value === studyId)
      : null;

    return (
      <div key='select-study' className='form-group row'>
        <div className='col-5 col-sm-4'>
          <label className='questionnaire-form__assign-study-site-label'>
            {t('questionnaire.form.assignStudy')}
          </label>
          <SelectCustom
            key={`select-${field}`}
            isDisabled={isDisabled}
            name={field}
            value={value}
            options={options}
            onChange={this.onStudyChangeCallback}
            hasMore={hasMoreStudies}
            loadMore={loadMoreStudies}
            t={t}
          />
        </div>
      </div>
    );
  };

  renderStudySiteSelectInput = () => {
    const {t, model: {studySiteIds}, hasMoreStudySites, loadMoreStudySites} = this.props;
    const field = 'studySite';
    const options = this.getStudySiteOptions();
    const value = studySiteIds
      ? options.filter(option => (studySiteIds || []).indexOf(option.value) !== -1)
      : null;

    return (
      <div key='select-study-site' className='form-group row'>
        <div className='col-5 col-sm-4'>
          <label className='questionnaire-form__assign-study-site-label'>
            {t('questionnaire.form.assignStudySites')}
          </label>
          <SelectCustom
            key={`select-${field}`}
            isMulti={true}
            name={field}
            value={value}
            options={options}
            onChange={this.onStudySiteChangeCallback}
            hasMore={hasMoreStudySites}
            loadMore={loadMoreStudySites}
            t={t}
          />
        </div>
      </div>
    );
  };

  renderTogglePublish = () => {

    const {t, model} = this.props;

    return (
      <div key='toggle-publish' className='row form-group'>
        <label className='col-5 col-sm-4' htmlFor='toggle_publish'>
          {t('questionnaire.publish')}
        </label>
        <div className='col-7 col-sm-8'>
          <Toggle
            id='toggle_publish'
            key={'toggle_publish'}
            checked={model.isPublished()}
            onChange={this.onStatusChange}
          />
        </div>
      </div>
    );
  };

  renderInputField = (field, writeAccess, language, onChange) => {

    const {t, model, validationErrors, showError} = this.props;
    const fieldError = getFieldError(
      language ? `${field}.${language}` : field,
      validationErrors,
      model.error
    );
    const wrapperClassName = classNames(['col-7 col-sm-8', field]);

    return (
      <Input
        groupClassName='row'
        key={`input_${field}`}
        disabled={!writeAccess}
        label={t(`questionnaire.form.${field}`)}
        labelClassName='col-5 col-sm-4'
        error={fieldError}
        hasError={showError && !!fieldError}
        onChange={onChange}
        value={model.getField(field, language)}
        wrapperClassName={wrapperClassName}
        type='text'
        t={t}
      />
    );
  };

  renderTextareaField = (field, writeAccess, language, onChange) => {
    const {t, model, validationErrors, showError} = this.props;
    const fieldError = getFieldError(
      language ? `${field}.${language}` : field,
      validationErrors,
      model.error
    );

    const wrapperClassName = classNames(['col-7 col-sm-8', field]);

    return (
      <Input
        groupClassName='row'
        key={`input_${field}`}
        disabled={!writeAccess}
        label={t(`questionnaire.form.${field}`)}
        labelClassName='col-5 col-sm-4'
        error={fieldError}
        hasError={showError && !!fieldError}
        onChange={onChange}
        value={model.getField(field, language)}
        wrapperClassName={wrapperClassName}
        type='textarea'
        t={t}
        rows={this.getRowsForField(field)}
      />
    );
  };


  renderPageButton = (page: number) => {
    const {t, location, navigate, page: currentPage} = this.props;

    const onClick = () => {
      const updatedQuery = stringify(_.merge({}, location.query, {page}));
      navigate(`${location.pathname}?${updatedQuery}`);
    };

    return (
      <RoundButton
        key={`page-${page}-btn`}
        className='page-btn'
        size='small'
        active={page === currentPage}
        onClick={onClick}>
        {`${t('questionnaire.form.page')} ${page}`}
      </RoundButton>
    );
  };

  addPage = () => {
    const {model, location, navigate} = this.props;
    const language = model.getDefaultLanguageCode();
    const newQuestionnaire = model.addPage(language);
    this.updateModel(newQuestionnaire);

    const updatedQuery = stringify(
      _.merge({}, location.query, {page: newQuestionnaire.getLastPage()})
    );
    navigate(`${location.pathname}?${updatedQuery}`);
  };

  renderAddPageButton = () => {

    const {t} = this.props;

    return (
      <RoundButton
        tooltip={t('questionnaire.form.addPage')}
        shape='circle'
        key={'add-page'}
        iconSrc={iconPlus}
        onClick={this.addPage}/>
    );
  };

  deletePage = () => {
    const {model, page, location, navigate} = this.props;
    this.updateModel(model.deletePage(page).ensureRulesIntegrity());

    if (page !== 1) {
      const updatedQuery = stringify(
        _.merge({}, location.query, {page: page - 1})
      );
      navigate(`${location.pathname}?${updatedQuery}`);
    }
  };

  renderDeletePageButton = () => {

    const {t} = this.props;

    return (
      <RoundButton
        tooltip={t('questionnaire.form.deletePage')}
        shape='circle'
        key={'delete-page-button'}
        iconSrc={iconDelete}
        onClick={this.deletePage}
      />
    );
  };

  renderPageButtons = () => {
    const {model} = this.props;
    const pages = model.getPages();
    const buttons = pages.map(this.renderPageButton);

    buttons.push(this.renderAddPageButton());

    if (pages.length > 0) {
      buttons.push(this.renderDeletePageButton());
    }

    return <div className='page-buttons'>{buttons}</div>;
  };

  onQuestionChange = (question) => {

    const {model} = this.props;

    this.updateModel(
      model
        .updateQuestion(question)
        .ensureRulesIntegrity()
    );
  };

  onQuestionOrderChange = (srcOrder, dstOrder) => {
    const {model, page} = this.props;

    this.updateModel(
      model
        .reOrderQuestions(page, srcOrder, dstOrder)
        .ensureRulesIntegrity()
    );
  };

  onDeleteQuestion = (question: Question) => {

    const {model} = this.props;

    this.updateModel(
      model
        .deleteQuestion(question)
        .ensureRulesIntegrity()
    );
  };

  onDuplicateQuestion = (question: Question) => {

    const {model} = this.props;

    this.updateModel(
      model
        .duplicateQuestion(question)
        .ensureRulesIntegrity()
    );
  };

  addQuestion = () => {

    const {model, page} = this.props;
    const language = model.getDefaultLanguageCode();

    this.updateModel(model.addQuestion(language, page));
  };

  addQuestionButton = (t) => {

    const questions = this.props.model.getQuestions();

    if (!questions.isEmpty()) {

      return (
        <div className='add-question-button-container'>
          <div onClick={this.addQuestion} className='add-button'>
            <img className='add-button__icon' src={roundAddIcon} alt='Add question icon'/>
            {t('questionnaire.form.addQuestion')}
          </div>
        </div>
      );
    }
  };

  renderQuestions = (field, language) => {

    const {pageQuestions} = this.state;
    const {t, validationErrors, model, showError} = this.props;

    const fieldError = getFieldError(
      field,
      validationErrors,
      model.error
    );

    return (
      <div key='questions-container' className='questions'>
        <div>
          <h1 className='questions-label'>{t('questionnaire.form.questions')}</h1>
        </div>
        <div>
          {this.renderPageButtons()}
        </div>
        <div>
          <span className='help-block'>
            {fieldError && showError && (<div className='no-question-error-msg'>{t(fieldError)}</div>)}
          </span>
          <QuestionPage
            key='questions-page'
            questions={pageQuestions}
            language={language}
            onQuestionOrderChange={this.onQuestionOrderChange}
            onQuestionChange={this.onQuestionChange}
            onDeleteQuestion={this.onDeleteQuestion}
            onDuplicateQuestion={this.onDuplicateQuestion}
            hasError={showError && !!validationErrors}
            t={t}
          />
        </div>
        {this.addQuestionButton(t)}
      </div>
    );
  };

  onDefaultLanguageChange = ({value}) => {
    const {model, t} = this.props;

    const tParam = {lng: value};

    let updateModel = model.setDefaultLanguage(value);

    QuestionnaireForm.fieldsWithPlaceholder.forEach(f => {

      const shouldSetPlaceHolderValue = !model.getDefaultLanguageCode() || !model.getField(f, value);

      if (shouldSetPlaceHolderValue) {

        updateModel = updateModel.setField(f, t(`questionnaire.form.${f}Placeholder`, tParam), value);
      }
    });

    this.updateModel(updateModel
      .initializeDefaultLanguageTranslations(t('questionnaire.form.missingTranslation', tParam)));
  };

  onStatusChange = (event) => this.onFieldChangeCallback('status',
    event.target.checked ? QuestionnaireStatus.published : QuestionnaireStatus.disabled);

  getChangeHandler = field => value => this.onFieldChangeCallback(field, value);

  getFieldInputs = (field) => {

    const {model} = this.props;
    const defaultLanguage = model.getDefaultLanguageCode();
    const hasDefaultLanguage = !!defaultLanguage;
    const writeAccess = true;

    if (field === FormFields.study) {

      return this.renderStudySelectInput();
    }

    if (!model.studyId) {

      return null;
    }

    switch (field) {

      case FormFields.studySites:
        return this.renderStudySiteSelectInput();
      case FormFields.status:
        return this.renderTogglePublish();
      case FormFields.languages:
        return this.renderDefaultLanguageSelectInput(
          writeAccess,
          this.onDefaultLanguageChange
        );
      case FormFields.title:
        return this.renderInputField(
          field,
          writeAccess && hasDefaultLanguage,
          defaultLanguage,
          this.getChangeHandler(field)
        );
      case FormFields.welcome:
      case FormFields.description:
      case FormFields.info:
        return this.renderTextareaField(
          field,
          writeAccess && hasDefaultLanguage,
          defaultLanguage,
          this.getChangeHandler(field)
        );
      case FormFields.questions:
        return this.renderQuestions(field, defaultLanguage);
      default:
        throw new Error(`Unsupported field ${field}`);
    }
  };

  render() {

    const inputs = fieldInputs.map(this.getFieldInputs);

    return (
      <div className='questionnaire-form'>
        {inputs}
      </div>
    );
  }
}

export default QuestionnaireForm;
