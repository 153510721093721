import * as React from 'react';
import {Component} from 'react';
import UsersView from '../../../components/UsersView/UsersView';

interface Props {
  location: string;
}

interface State {}

export default class Personnel extends Component<Props, State> {
  render() {
    return <UsersView key='personnel-view' location={this.props.location} />;
  }
}
