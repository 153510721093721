import '@babel/polyfill';
import 'whatwg-fetch';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {browserHistory} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';
import createStore from './store/createStore';
import AppContainer from './containers/AppContainer';
import NotificationsContainer from './containers/NotificationsContainer';
import State from './models/State';
import {I18nextProvider} from 'react-i18next';
import i18n from './services/I18n';
import Routes from './routes/index';
import './styles/styles.less';

// ========================================================
// Store Instantiation
// ========================================================
const initialState = State();
const store = createStore(initialState, browserHistory);
// const history = syncHistoryWithStore(browserHistory, store);
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState(state: any) {
    return state.get('routing').toJS();
  }
});

const MOUNT_NODE: any = document.getElementById('app');
const MOUNT_NOTIFICATIONS_NODE: any = document.getElementById('notifications');

const render = () => {
  const routes = Routes(store);

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <AppContainer store={store} routes={routes} history={history} />
    </I18nextProvider>,
    MOUNT_NODE
  );

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <NotificationsContainer store={store} />
    </I18nextProvider>,
    MOUNT_NOTIFICATIONS_NODE
  );
};

render();
