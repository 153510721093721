import {enumValues} from '../util';

export enum Language {
  fi = 'fi',
  en = 'en',
  pt = 'pt',
  de = 'de',
  nl = 'nl',
  pl = 'pl',
  es = 'es'
}

export namespace Language {

  const values = enumValues(Language);

  export function getValues() {

    return values;
  }

  export function getAdminValues() {

    return values.filter((val) => val === Language.en || val === Language.fi);
  }
}
