import * as _ from 'lodash';
import ModelsModule from './ModelsModule';
import {PaginationContext} from '../models/Pagination';
import StudySites from '../models/StudySites';
import StudySite from './StudySite';
import {bind} from '../util';

const initialState = new StudySites();

export class StudySitesModule extends ModelsModule {
  constructor() {
    super('study-site', initialState, PaginationContext.STUDY_SITE);
    bind(this, this.fetchStudySites);
  }

  public fetchStudySites(ids: number[]) {
    let queryParams;

    if (!_.isEmpty(ids)) {
      queryParams = {};

      const result = ids.join(',');

      if (result !== '') {
        queryParams.studySiteId = result;
      }
    }

    return this.getModels(queryParams, true);
  }

  protected getAdditionalActionHandlers() {
    return {
      [StudySite.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [StudySite.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [StudySite.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    };
  }
}

export default new StudySitesModule();