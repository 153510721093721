import * as _ from 'lodash';

import Config from '../config';
import i18n from 'i18next';
import moment from 'moment';
import * as XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {registerCustomValidators} from './validators';
import {logDebug} from '../util';

const resources = require('../locales/index.js');

export function reload(lng) {
  const res = _.cloneDeep(resources);

  if (res[lng]) {
    Object.keys(res[lng]).forEach(ns => {
      i18n.addResourceBundle(lng, ns, res[lng][ns]);
    });

    onLanguageChanged();
  }
}

export const DEFAULT_LANGUAGE = 'en';

function onLanguageChanged() {
  logDebug('On language changed');
  moment.locale(i18n.language);
  registerCustomValidators(i18n);
}

export function init() {

  i18n.on('initialized', onLanguageChanged);
  i18n.on('languageChanged', onLanguageChanged);

  i18n
    .use(XHR)
    .use(LanguageDetector)
    .init(
      {
        fallbackLng: DEFAULT_LANGUAGE,

        ns: ['common', 'admin', 'subject'],
        defaultNS: 'admin',

        debug: Config.env.toString() === 'development',

        resources: _.cloneDeep(resources),

        interpolation: {
          escapeValue: false, // not needed for react!!
          formatSeparator: ',',
          prefix: '__',
          suffix: '__',
          format: (value, format) => {
            if (format === 'uppercase') {
              return value.toUpperCase();
            }

            return value;
          }
        }
      }
    );
}

init();

export default i18n;
