import * as React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';
import {Component} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import {Form} from 'react-bootstrap';
import {fromJS} from 'immutable';
import {ComponentProps, ComponentState, getError} from '../Question';

const compactObject = object => _.pickBy(object, _.negate(val => _.isNull(val) || val === ''));
const compactRecord = record => fromJS(compactObject(record.toJS()));

interface TextProps extends ComponentProps {
  componentClass?: any;
}

interface TextState extends ComponentState {
  answerHash: number;
  valueHash: number;
}

export default class Text extends Component<TextProps, TextState> {

  onChange = event => {

    const value = event.target.value;
    const {component, answer} = this.props;
    const {field} = component;

    const updatedValue = answer.value.set(field, value);
    this.props.onChange(answer.set('value', compactRecord(updatedValue)));
  };

  renderInput = (value, onChange, disabled) => {

    const {componentClass, fieldError} = this.props;

    const inputClassName = classNames(
      'form-control',
      {'text-input--error': !!fieldError}
    );

    if (componentClass === 'textarea') {
      return (
        <TextareaAutosize
          disabled={disabled}
          className={inputClassName}
          value={value}
          onChange={onChange}
        />
      );
    }

    return (
      <Form.Control
        disabled={disabled}
        bsPrefix={inputClassName}
        as={componentClass}
        value={value || ''}
        onChange={onChange}
      />
    );
  };

  getAnswerValue = () => {

    const {component, answer} = this.props;
    const {field} = component;

    return answer.getFieldValue(field);
  };

  renderAnswerInput = () => {

    const {disabled} = this.props;
    const value = this.getAnswerValue();

    return this.renderInput(value, this.onChange, disabled);
  };

  renderLabel = () => {
    const {component, language} = this.props;
    const title = component.getLanguageLocalization(language);

    if (title) {
      return (
        <Form.Label>{title}</Form.Label>
      );
    }
  };

  render() {
    const {fieldError} = this.props;

    return (
      <Form.Group className='text-input'>
        {this.renderLabel()}
        {this.renderAnswerInput()}
        {getError(fieldError)}
      </Form.Group>
    );
  }
}
