import {Record} from 'immutable';
import {validate} from 'validate.js';
import {phoneRegex} from '../util';

const baseConstraints = {
  selectedStudySiteId: {
    presence: {allowEmpty: false}
  },
  name: {
    presence: {allowEmpty: false}
  },
  phoneNumber: {
    presence: {allowEmpty: false},
    format: {
      pattern: phoneRegex
    }
  },
  email: {
    presence: {allowEmpty: false},
    email: true
  }
};

const contactConstraints = (mandatoryAddress?: boolean) => {

  return mandatoryAddress
    ? {
      ...baseConstraints,
      address: {
        presence: {allowEmpty: !mandatoryAddress}
      }
    }
    : baseConstraints;
};

export interface IContactDetails {
  selectedStudySiteId: number;
  name?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;
}

const defaultValues = {
  selectedStudySiteId: undefined,
  name: undefined,
  address: undefined,
  phoneNumber: undefined,
  email: undefined
};

class ContactDetails extends Record(defaultValues) implements IContactDetails {
  selectedStudySiteId: number;
  name?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;

  validate(mandatoryAddress?: boolean) {
    return validate(this, contactConstraints(mandatoryAddress));
  }

  isValid(mandatoryAddress?: boolean) {
    return !this.validate(mandatoryAddress);
  }
}

export default ContactDetails;
