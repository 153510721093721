import BaseModel from './BaseModel';
import {LANGUAGE_STATUS} from '../config/constants';

const constraints = {
  language: {
    presence: {allowEmpty: false}
  },
  default: {
    presence: {allowEmpty: false}
  },
  status: {
    presence: {allowEmpty: false}
  }
};

const equalsKeys = [
  'language'
];

const defaultValues = {
  language: undefined,
  default: false,
  status: undefined
};

export default class QuestionnaireLanguage
  extends BaseModel(defaultValues, equalsKeys, constraints)<QuestionnaireLanguage> {
  language: string;
  default: boolean;
  status: LANGUAGE_STATUS;

  constructor(js?: any) {
    super(js);
  }

  fromJS(js: any): QuestionnaireLanguage {
    return new QuestionnaireLanguage(js);
  }

  setDefault(isDefaultLanguage: boolean) {
    return this.set('default', isDefaultLanguage) as QuestionnaireLanguage;
  }

  setStatus(status: LANGUAGE_STATUS) {
    return this.set('status', status) as QuestionnaireLanguage;
  }

  isPublished() {
    return this.status === LANGUAGE_STATUS.PUBLISHED;
  }
}
