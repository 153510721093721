import * as React from 'react';
import {PureComponent} from 'react';
import User from '../../../models/User';
import RoundButton from '../../../components/RoundButton/RoundButton';

const deleteIcon = require('../../../images/delete.svg');
const duplicateIcon = require('../../../images/duplicate.svg');

interface Props {
  user: User;
  rowData: any;
  deleteModel: (model) => any;
  onDuplicate: (rowData) => any;
}

interface State {}

export default class SiteResultsActionColumns extends PureComponent<Props, State> {

  deleteRow = event => {
    event.preventDefault();
    event.stopPropagation();
    const {deleteModel, rowData} = this.props;
    deleteModel(rowData._src);
  };

  duplicateRow = event => {
    event.preventDefault();
    event.stopPropagation();
    const {onDuplicate, rowData} = this.props;
    onDuplicate(rowData._src);
  };

  renderActionButtons = () => {

    const {user, rowData} = this.props;

    if (user.isAdmin()) {
      return (
        <>
          <RoundButton
            iconSrc={duplicateIcon}
            key={'duplicate' + rowData.id}
            shape='circle'
            className='duplicate-button'
            size='small'
            onClick={this.duplicateRow}
          />
          <RoundButton
            iconSrc={deleteIcon}
            key={'delete' + rowData.id}
            shape='circle'
            className='delete-button'
            size='small'
            confirm={true}
            onClick={this.deleteRow}
          />
        </>
      );
    }

    return null;
  };

  render() {

    return (
      <div className='actions-wrapper'>
        {this.renderActionButtons()}
      </div>
    );
  }
}
