import * as React from 'react';
import {Component} from 'react';
import Loader from 'react-loader';
import {Router} from 'react-router';
import I18n from '../services/I18n';
import {connect, Provider} from 'react-redux';
import {initialize, teardown} from '../modules/App';
import App from '../models/App';
import User from '../models/User';
import ReactGA from 'react-ga';
import Config from '../config';
import CookieConsent from 'react-cookie-consent';
import {withNamespaces} from 'react-i18next';
import Study from '../models/Study';
import {i18OptionsSubject} from '../util';

export interface Props {
  history: any;
  routes: any;
  store: any;

  app: App;
  authenticatedUser: User;
  study: Study;
  initialize: () => any;
  teardown: () => any;
  t: (key) => string;
}

interface State {
  languageChanged: boolean;
}

const AppComponent = ({t, history, routes, study}) => {

  const onAccept = () => {
    ReactGA.initialize(Config.GA_ID, {debug: Config.development});
    ReactGA.set({anonymizeIp: true});
  };

  const renderCookieConsent = () => {

    // Only show cookie consent when we are in questionnaires and have a study
    if (!study.id || !history.getCurrentLocation().pathname.startsWith('/questionnaires')) {
      return undefined;
    }

    return (
      <CookieConsent
        location="bottom"
        buttonText={t('cookieConsent.acceptButton', i18OptionsSubject())}
        cookieName="cliniScoutRecruitConsent"
        style={{background: '#2B373B'}}
        buttonStyle={{background: '#0a55a3', color: '#fff', fontSize: '13px'}}
        expires={Config.development ? 180 : 0}
        onAccept={onAccept}
      >
        {t('cookieConsent.text', i18OptionsSubject()) + ' '}
        <a
          href={`/study/${study.id}/privacy-policy`}
          rel="noreferrer"
          target="_blank">{t('cookieConsent.linkText', i18OptionsSubject())}</a>
      </CookieConsent>
    );
  };

  return (
    <>
      <Router history={history} children={routes}/>
      {renderCookieConsent()}
    </>
  );
};

class AppContainer extends Component<Props, State> {

  unListenHistory;

  constructor(props: Props) {
    super(props);
    this.state = {
      languageChanged: false
    };
  }

  componentDidMount() {
    I18n.on('languageChanged', this.onLanguageChanged);
    this.props.initialize();
    let previousPath;

    this.unListenHistory = this.props.history.listen(({pathname, search}) => {
      const path = pathname + search;
      const isAdminRoute = /\/admin.*/.test(path);

      if (!isAdminRoute && path !== previousPath) {
        previousPath = path;
        ReactGA.pageview(path);
      }
    });
  }

  componentWillUnmount() {
    this.props.teardown();

    if (this.unListenHistory) {
      this.unListenHistory();
    }
  }

  componentDidUpdate() {
    const {app} = this.props;

    if (!app.initialized && !app.initializing) {
      this.props.initialize();
    }
  }

  onLanguageChanged = _lng => this.setState({languageChanged: true});

  render() {
    const {app, history, routes, store, study, t} = this.props;
    const isLoading = !app.initialized && app.initializing;

    if (isLoading) {
      return (
        <div style={{height: '100%'}}>
          <Loader loaded={false} width={12} radius={35}/>
        </div>
      );
    }

    return (
      <Provider store={store}>
        <AppComponent t={t} history={history} routes={routes} study={study}/>
      </Provider>
    );
  }
}

const mapActionCreators = {
  initialize,
  teardown
};

const mapStateToProps = ({authenticatedUser, app, study}, _ownProps) => {
  return {authenticatedUser, app, study};
};

export default withNamespaces(['admin'], {wait: true})(connect(
  mapStateToProps,
  mapActionCreators
)(AppContainer));
