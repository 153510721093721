import * as React from 'react';
import {Component} from 'react';
import Input from '../../../components/Input/Input';
import classNames from 'classnames';
import * as _ from 'lodash';

interface Props {
  onChange: (value: string) => any;
  value: string;
  tabIndex?: number;
  showError?: boolean;
}

interface State {
  value: string;
  editing: boolean;
}

export default class TranslationInput extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      editing: false
    };
  }

  componentDidUpdate(prevProps) {

    const {value} = this.props;

    if (!this.state.editing && value !== prevProps.value) {
      this.setState({value});
    }
  }

  onStartEditing = () => this.setState({editing: true});

  onStopEditing = () => this.setState({editing: false}, () => this.props.onChange(this.state.value));

  onChange = (value) => this.setState({value});

  getContent() {

    const {value, editing} = this.state;

    if (editing) {
      return (
        <Input
          tabIndex={this.props.tabIndex}
          value={value}
          onChange={this.onChange}
          onBlur={this.onStopEditing}
          autoFocus={true}/>
      );
    } else {
      return value;
    }
  }

  render() {

    const {editing, value} = this.state;
    const {tabIndex, showError} = this.props;

    const classes = classNames({
      'translations-column ': true,
      editing,
      'has-error': _.isEmpty(value) && showError
    });

    return (
      <div className={classes}
        tabIndex={editing ? undefined : tabIndex}
        onFocus={this.onStartEditing}
        onClick={this.onStartEditing}>
        {this.getContent()}
      </div>
    );
  }
}
