/**
 * Created by Mauritz Untamala on 30/05/2017.
 */

export const storeToken = (token) => {
  localStorage.setItem('token', token);
};

export const retrieveToken = () => {
  return localStorage.getItem('token');
};

export const destroyToken = () => {
  localStorage.removeItem('token');
};
