/**
 * Created by Mauritz Untamala on 20/02/16.
 */
import * as React from 'react';
import {Component} from 'react';
import StatusColumn from './StatusColumn';
import TableComponent from './TableComponent';
import {getRole} from './CommonFilterValueFunctions';

import * as _ from 'lodash';

import {connect} from 'react-redux';
import {navigate} from '../modules/Location';
import App from '../models/App';
import Users from '../models/Users';
import Criteria from '../models/Criteria';

interface ActionsProps {
  app: App;
  rowData: any;
  t: (key, params?) => any;
}

interface ActionsState {
}

class ActionsColumn extends Component<ActionsProps, ActionsState> {

  getActions = () => undefined;

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>;
  }
}

const mapActionCreators = {
  navigate
};

const mapStateToProps = ({app}, ownProps) => {
  const userKeys = ownProps.users.getUserKeys();

  return {
    userKeys,
    app
  };
};

interface Props {
  app: App;
  criteria: Criteria;
  users: Users;
  userKeys: any[];
  onCriteriaChange: (criteria) => any;
  onRowClick: (rowData) => any;
  navigate: (url: string, silent?: boolean) => any;
  t: (key, params?) => any;
  hasMore?: boolean;
  loading?: boolean;
  loadMore?: () => any;
}

interface State {
}

export class UsersTable extends Component<Props, State> {

  getResults = () => this.props.users.list.map(user => _.extend(user.toJS(), {_src: user})).toArray();

  getCommonColumns = () => {

    const {t} = this.props;

    return [
      {
        columnName: 'status',
        displayName: t('column.status'),
        cssClassName: 'status-column',
        customComponent: props => <StatusColumn {...this.props} {...props} />,
        sortable: false
      },
      {
        columnName: 'actions',
        displayName: t('column.actions'),
        cssClassName: 'actions-column',
        customComponent: props => <ActionsColumn {...this.props} {...props} />,
        sortable: false
      }
    ];
  };

  personnelOnlyColumns = (): Array<any> => {

    const {t} = this.props;

    const roleColumn = ({field, rowData, data}) => {
      return (
        <div key={field + '_readonly_' + rowData._id} data-tip={t('tooltip.clickToEdit')}>
          {getRole(data, rowData, field, t)}
        </div>
      );
    };

    const roleValue = (value, rowData, columnName) => getRole(value, rowData, columnName, t);

    return [
      {
        columnName: 'firstName',
        displayName: t('firstName'),
        cssClassName: 'firstName-column'
      },
      {
        columnName: 'lastName',
        displayName: t('lastName'),
        cssClassName: 'lastName-column'
      },
      {
        columnName: 'email',
        displayName: t('email'),
        cssClassName: 'email-column'
      },
      {
        columnName: 'phoneNumber',
        displayName: t('phoneNumber'),
        cssClassName: 'phoneNumber-column'
      },
      {
        columnName: 'role',
        displayName: t('column.role'),
        cssClassName: 'role-column',
        customComponent: roleColumn,
        filterValue: roleValue,
        sortValue: roleValue
      }
    ];
  };

  getColumnMetadata = () => {

    const {users, userKeys} = this.props;

    if (users.list.isEmpty()) {
      return [];
    }

    const columns = this.personnelOnlyColumns().concat(this.getCommonColumns());

    return _.reduce(
      columns,
      (memo, column) => {
        if (
          /^(status|actions|receivedInvitation)$/.test(column.columnName) ||
          _.includes(userKeys, column.columnName)
        ) {
          column.order = memo.length;
          memo.push(column);
        }
        return memo;
      },
      []
    );
  };

  onTabCriteriaChanged = tabCriteria => {
    if (this.props.criteria) {
      this.props.onCriteriaChange(
        this.props.criteria
          .set('columnName', tabCriteria ? tabCriteria.columnName : undefined)
          .set('sort', tabCriteria ? tabCriteria.sort : undefined)
          .set('filter', tabCriteria ? tabCriteria.filter : undefined)
      );
    }
  };

  render() {

    const columnMeta = this.getColumnMetadata();
    const {criteria, onRowClick, t, hasMore, loadMore, loading} = this.props;

    return (
      <div className='table-panel'>
        <TableComponent
          key='results'
          data={this.getResults()}
          showFilter={true}
          rowKey={'id'}
          columns={columnMeta}
          onRowClick={onRowClick}
          initialFilter={criteria ? criteria.filter : undefined}
          initialSortColumn={criteria ? criteria.columnName : undefined}
          initialSortState={criteria ? criteria.sort : undefined}
          useFixedHeader={true}
          onCriteriaChanged={this.onTabCriteriaChanged}
          t={t}
          hasMore={hasMore}
          loadMore={loadMore}
          loading={loading}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapActionCreators
)(UsersTable);
