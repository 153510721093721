import * as React from 'react';
import {PureComponent} from 'react';
import * as _ from 'lodash';
import {List} from 'immutable';
import Criteria from '../../../models/Criteria';
import StudySite from '../../../models/StudySite';
import User from '../../../models/User';
import RoundButton from '../../../components/RoundButton/RoundButton';
import TableComponent from '../../../components/TableComponent';
import SiteResultsActionColumns from './StudySiteResultsActionColumns';

interface Props {
  user: User;
  studySites: List<StudySite>;
  criteria: Criteria;
  onCriteriaChange: (criteria) => any;
  deleteModel: (model) => any;
  navigate: (url: string, silent?: boolean) => any;
  ref?: any;
  t: (key, params?) => any;
  hasMore?: boolean;
  loading?: boolean;
  loadMore?: () => any;
}

interface State {}

export default class StudySiteResults extends PureComponent<Props, State> {

  getResults = () =>
    this.props.studySites.map(studySite => _.extend(studySite.toJS(), {_src: studySite})).toArray();

  getColumnMetadata = () => {

    const {t} = this.props;

    return [
      {
        columnName: 'studyName',
        displayName: t('column.studyName'),
        order: 1,
        locked: false,
        visible: true
      },
      {
        columnName: 'siteNumber',
        displayName: t('column.siteNumber'),
        order: 2,
        locked: false,
        visible: true
      },
      {
        columnName: 'siteName',
        displayName: t('column.siteName'),
        order: 3,
        locked: false,
        visible: true
      },
      {
        columnName: 'email',
        displayName: t('column.email'),
        order: 4,
        locked: false,
        visible: true
      },
      {
        columnName: 'actions',
        displayName: t('column.actions'),
        cssClassName: 'actions-column',
        customComponent: props =>
          (<SiteResultsActionColumns
            {...this.props}
            {...props}
            onDuplicate={this.duplicateStudySite}
          />),
        sortable: false
      }
    ];
  };

  showStudySite = rowData =>
    this.props.navigate(`/admin/study-sites/${rowData.id}`);

  addStudySite = () =>
    this.props.navigate('/admin/study-sites/new');

  duplicateStudySite = rowData =>
    this.props.navigate(`/admin/study-sites/new?duplicate=true&id=${rowData.id}`);

  onCriteriaChanged = tableCriteria => {

    const {criteria, onCriteriaChange} = this.props;

    onCriteriaChange(
      criteria
        .set('columnName', tableCriteria ? tableCriteria.columnName : undefined)
        .set('sort', tableCriteria ? tableCriteria.sort : undefined)
        .set('filter', tableCriteria ? tableCriteria.filter : undefined)
    );
  };

  renderButton() {

    const {user} = this.props;

    if (user.isAdmin()) {
      return (
        <div className='study-site-button-container'>
          <RoundButton
            type='clear'
            onClick={this.addStudySite}>
            {this.props.t('button.addSiteStudy')}
          </RoundButton>
        </div>
      );
    }

    return null;
  }

  render() {

    const {criteria, t, hasMore, loadMore, loading} = this.props;

    return (
      <div className='table-panel'>
        <TableComponent
          key='results'
          ref='results'
          data={this.getResults()}
          showFilter={true}
          rowKey={'id'}
          columns={this.getColumnMetadata()}
          useFixedHeader={true}
          initialFilter={criteria ? criteria.filter : undefined}
          initialSortColumn={criteria ? criteria.columnName : undefined}
          initialSortState={criteria ? criteria.sort : undefined}
          onRowClick={this.showStudySite}
          onCriteriaChanged={this.onCriteriaChanged}
          t={t}
          hasMore={hasMore}
          loadMore={loadMore}
          loading={loading}
        />
        {this.renderButton()}
      </div>
    );
  }
}