import * as React from 'react';
import {PureComponent} from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot
} from 'react-beautiful-dnd';
import QuestionView from './Question';
import {List} from 'immutable';

import Question from '../../../models/Question';

interface Props {
  questions: List<Question>;
  language: string;
  onQuestionOrderChange: (src, dst) => void;
  onQuestionChange: (question) => any;
  onDeleteQuestion: (question) => any;
  onDuplicateQuestion: (question) => any;
  hasError?: boolean;
  t: (key, params?) => any;
}

interface State {
}

const draggingItemBackground = '#85bcd3';
const itemBackground = '#feffff';
const draggingListBackground = '#f0f0f0';
const listBackground = 'transparent';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0 0 15px 0',
  background: isDragging ? draggingItemBackground : itemBackground,
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? draggingListBackground : listBackground,
  marginTop: '15px'
});

interface DraggableQuestionProps {
  question: Question;
  language: string;
  onQuestionChange: (question) => any;
  onDeleteQuestion: (question) => any;
  onDuplicateQuestion: (question) => any;
  t: (key, params?) => any;
  index: number;
  hasError?: boolean;
}

interface DraggableQuestionState {
}

class DraggableQuestion extends PureComponent<DraggableQuestionProps, DraggableQuestionState> {

  render() {

    const {question: {order}, t, index, hasError} = this.props;
    const questionId = `question-${order}`;

    return (
      <Draggable
        draggableId={questionId}
        index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {

          const {
            question,
            onQuestionChange,
            onDeleteQuestion,
            onDuplicateQuestion,
            language
          } = this.props;

          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}>
              <QuestionView
                question={question}
                language={language}
                onChange={onQuestionChange}
                onDeleteQuestion={onDeleteQuestion}
                onDuplicateQuestion={onDuplicateQuestion}
                hasError={hasError}
                t={t}
              />
            </div>
          );
        }}
      </Draggable>
    );
  }
}

export default class QuestionPage extends PureComponent<Props, State> {

  renderQuestion = (question, index) => {

    const {onQuestionChange, onDeleteQuestion, onDuplicateQuestion, language, t, hasError} = this.props;

    return (
      <DraggableQuestion
        index={index}
        key={`draggable-question-${question.order}`}
        question={question}
        onQuestionChange={onQuestionChange}
        onDeleteQuestion={onDeleteQuestion}
        onDuplicateQuestion={onDuplicateQuestion}
        language={language}
        hasError={hasError}
        t={t}
      />
    );
  };

  renderQuestions = () => {

    const {questions} = this.props;

    return (
      <Droppable key='question-droppable' droppableId='questionDrop'>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {questions.map((q, index) => this.renderQuestion(q, index))}
          </div>
        )}
      </Droppable>
    );
  };

  onDragEnd = (result) => {

    if (!result.destination) {
      return;
    }

    const {onQuestionOrderChange, questions} = this.props;
    const srcIdx = result.source.index;
    const dstIdx = result.destination.index;
    const srcOrder = questions.get(srcIdx).order;
    const dstOrder = questions.get(dstIdx).order;

    onQuestionOrderChange(srcOrder, dstOrder);
  };

  render() {

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {this.renderQuestions()}
      </DragDropContext>
    );
  }
}
