/**
 * Created by Mauritz Untamala on 25/02/16.
 */
import * as React from 'react';
import {Component} from 'react';
import Input from './Input/Input';
import SelectInput from './SelectInput/SelectInput';
import classNames from 'classnames';
import {generateSelectOptions, getFieldError} from '../util';
import {PaginationContext} from '../models/Pagination';
import {List} from 'immutable';
import {connect} from 'react-redux';
import Studies from '../modules/Studies';
import {warning} from '../modules/Notifications';
import User from '../models/User';
import StudiesModel from '../models/Studies';

interface Props {
  user: User;
  isProfile: boolean;
  type: string;
  availableLanguages: List<any>;
  studies: StudiesModel;
  minimalDetails: boolean;
  fields: any;
  availableRoles: List<any>;
  pagination: any;
  showError?: boolean;
  onChange: (model) => any;
  getStudies: (queryParams?, reset?) => any;
  warning: (message) => any;
  t: (key, params?) => any;
}

export class UserDetails extends Component<Props, any> {

  private static readonly formFields = [
    'firstName',
    'lastName',
    'email',
    'role',
    'language',
    'studyIds'
  ];

  componentDidMount() {
    this.props.getStudies();
  }

  loadMore = () => {
    const {getStudies, studies, pagination} = this.props;
    if (!studies.isLoading && pagination.hasMore) {
      getStudies();
    }
  };

  render() {
    const {user} = this.props;

    if (!user) {
      return null;
    }

    return <div>{this.getInputs()}</div>;
  }

  onFieldChangeCallback = field => value => {
    const {onChange, user} = this.props;

    onChange(user.set(field, value));
  };

  getInputField = (field) => {

    const {user, t, showError} = this.props;
    const value = user.get(field);
    const fieldError = getFieldError(field, user.validate(), user.error, value);
    const wrapperClassName = classNames(['col-12 col-sm-9', field]);

    return (
      <Input
        key={user._id + 'input_' + field}
        label={t(field)}
        labelClassName='col-12 col-sm-3'
        error={fieldError}
        hasError={showError && !!fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={value || ''}
        wrapperClassName={wrapperClassName}
        type='text'
        t={t}
      />
    );
  };

  getLanguageOptions = () => {
    const {availableLanguages, t} = this.props;
    const languageLabel = (key) => t('language.' + key);

    return generateSelectOptions(availableLanguages, languageLabel);
  };

  getRoleOptions = () => {
    const {availableRoles, t} = this.props;
    const roleLabel = (key) => t('userRole.' + key);

    return generateSelectOptions(availableRoles, roleLabel);
  };

  getStudyOptions = () => generateSelectOptions(this.props.studies.list, 'studyName', 'id');

  createLanguageInput = () => this.createSelectInput('language', this.getLanguageOptions());

  createRoleInput = () => this.createSelectInput('role', this.getRoleOptions());

  createStudyInput = () => this.createSelectInput('studyIds', this.getStudyOptions(), true);

  createSelectInput = (field, options, multi?, placeholder?, onChange?) => {

    const {user, t, showError, pagination} = this.props;
    const value = user.get(field);
    const fieldError = getFieldError(field, user.validate(), user.error, value);

    return (
      <SelectInput
        key={'select-' + field}
        model={user}
        modelName='user'
        field={field}
        options={options}
        multi={multi}
        placeholder={placeholder}
        onChange={onChange || this.props.onChange}
        t={t}
        hasError={showError && !!fieldError}
        hasMore={pagination.hasMore}
        loadMore={this.loadMore}
      />
    );
  };

  getInputs = () => {

    return UserDetails.formFields.map(field => {

      switch (field) {
        case 'role':
          return this.createRoleInput();
        case 'studyIds':
          return this.createStudyInput();
        case 'language':
          return this.createLanguageInput();
        default:
          return this.getInputField(field);
      }
    });
  };
}

const mapActionCreators = {
  getStudies: Studies.getModels,
  warning
} as any;

const mapStateToProps = (
  {app, studies, pagination, users},
  _ownProps
): any => {
  const availableRoles = app.availableRoles;
  const availableLanguages = app.availableAdminLanguages;

  return {
    model: users,
    availableRoles,
    availableLanguages,
    studies,
    pagination: pagination.getPagination(PaginationContext.STUDY)
  };
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(UserDetails);
