import * as React from 'react';
import {Component} from 'react';
import UserView from '../../../components/UserView';

import {connect} from 'react-redux';
import UserModule from '../../../modules/User';
import UserModel from '../../../models/User';
import {WithNamespaces, withNamespaces} from 'react-i18next';

interface Props extends WithNamespaces {
  userId: number;
  user: UserModel;
  getModel: (id, queryParams?) => any;
  saveModel: (model, pathComponents?, queryParams?) => any;
  resetModel: () => any;
}

interface State {
}

class User extends Component<Props, State> {
  render() {

    return <UserView key='user-view' {...this.props}/>;
  }
}

const mapStateToProps = ({user, users}, ownProps) => {
  const userId = ownProps.params.id ? parseInt(ownProps.params.id, 10) : undefined;

  const resolveUser = () => {

    if (user.id === userId) {
      return user;
    }

    const model = users.getModelById(userId);

    return model ? model : new UserModel();
  };

  return {
    userId,
    user: resolveUser()
  };
};

const mapActionToProps = {
  getModel: UserModule.getModel,
  saveModel: UserModule.saveModel,
  resetModel: UserModule.resetModel
};

export default withNamespaces(['admin'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(User));
