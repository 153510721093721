import * as React from 'react';
import {PureComponent} from 'react';
import * as _ from 'lodash';
import Questionnaire from '../../../models/Questionnaire';
import QuestionView, { QuestionContext } from '../../../components/Question';
import QuestionAnswer from '../../../models/QuestionAnswer';
import User from '../../../models/User';

interface Props {
  authenticatedUser: User;
  model: Questionnaire;
  onChange: (QuestionAnswer) => any;
  page: number;
  language: string;
  t: (key, params?) => any;
}

interface State {}

export default class QuestionnaireFormRulesPage extends PureComponent<Props, State> {

  renderQuestion = question => {

    const {authenticatedUser, model, onChange, t, language} = this.props;
    const {page, order} = question;
    const answer = model.mapQuestionAnswerFromRules(page, order);
    if(question && question.getTitle(language)) {
      return (
        <QuestionView
          key={`question-${order}-${page}`}
          isPersonnel={authenticatedUser.isPersonnel()}
          from='formRules'
          question={question}
          questionContext={QuestionContext.Rule}
          answer={answer}
          showError={false}
          onChange={onChange}
          language={language}
          t={t}
        />
      );
    }
  };

  renderPageQuestions = () => {

    const { page, model: { questions }} = this.props;

    return questions.filter(q => q.page === page)
      .sortBy(q => q.order)
      .map(this.renderQuestion)
      .toArray();
  };

  render() {
    return (
      <>
        {this.renderPageQuestions()}
      </>
    );
  }
}
