import * as React from 'react';
import classnames from 'classnames';
import pathToRegexp from 'path-to-regexp';
import {connect} from 'react-redux';
import i18n from '../../services/I18n';
import NavItem from './NavItem/NavItem';

const iconTutkimus = require('../../images/tutkimus.svg');
const iconEsitiedot = require('../../images/esitiedot.svg');
const iconLisatiedot = require('../../images/lisatiedot.svg');
const iconStudies = require('../../images/studies.svg');
const iconStudySites = require('../../images/study-sites.svg');
const iconMyAccount = require('../../images/account.svg');
const iconPersonnel = require('../../images/personnel.svg');
const iconQuestionnaires = require('../../images/questionnaires.svg');

const iconStudiesActive = require('../../images/studies-active.svg');
const iconStudySitesActive = require('../../images/study-sites-active.svg');
const iconMyAccountActive = require('../../images/account-active.svg');
const iconPersonnelActive = require('../../images/personnel-active.svg');
const iconQuestionnairesActive = require('../../images/questionnaires-active.svg');

const iconTutkimusActive = require('../../images/tutkimus-active.svg');
const iconEsitiedotActive = require('../../images/esitiedot-active.svg');
const iconLisatiedotActive = require('../../images/lisatiedot-active.svg');

import Study from '../../models/Study';

import './NavItems.less';

interface OwnProps {
  className?: string;
  hideIcons?: boolean;
  type?: 'static' | 'interactive';
  onToggleDrawer: () => any;
}

interface StateProps {
  isPersonnel: boolean;
  currentPage: string;
  study: Study;
}

type Props = StateProps & OwnProps;

interface MenuItem {
  iconSrc: string;
  iconActiveSrc?: string;
  name: string;
  path: string;
}

const subjectRouteRegex = pathToRegexp('/questionnaires/:studyName/:studyNumber/:qid(\\d+)/:answer?/:info?');

const NavItems: React.FunctionComponent<Props> = (props) => {

  const {className, hideIcons, type, isPersonnel, currentPage, onToggleDrawer} = props;

  const isActiveMenuItem = (menuItem: MenuItem) => {

    return isPersonnel ? currentPage.includes(menuItem.path) : currentPage === menuItem.path;
  };

  const getIcon = (menuItem: MenuItem) => {

    if (hideIcons) {
      return null;
    }

    const {iconActiveSrc, iconSrc} = menuItem;

    return isActiveMenuItem(menuItem) ? iconActiveSrc : iconSrc;
  };

  const getNavItemTranslation = navItemName => {
    return i18n.t(`navigation.${navItemName}`, {ns: navItemName.split('.')[0]});
  };

  const getPersonnelNavItems = (): MenuItem[] => [
    {
      name: getNavItemTranslation('admin.studies'),
      path: '/admin/studies',
      iconSrc: iconStudies,
      iconActiveSrc: iconStudiesActive
    },
    {
      name: getNavItemTranslation('admin.studySites'),
      path: '/admin/study-sites',
      iconSrc: iconStudySites,
      iconActiveSrc: iconStudySitesActive
    },
    {
      name: getNavItemTranslation('admin.questionnaires'),
      path: '/admin/questionnaires', iconSrc:
      iconQuestionnaires,
      iconActiveSrc: iconQuestionnairesActive
    },
    {
      name: getNavItemTranslation('admin.personnel'),
      path: '/admin/users',
      iconSrc: iconPersonnel,
      iconActiveSrc: iconPersonnelActive
    },
    {
      name: getNavItemTranslation('admin.profile'),
      path: '/admin/profile',
      iconSrc: iconMyAccount,
      iconActiveSrc: iconMyAccountActive
    }
  ];

  const getSubjectNavItems = (): MenuItem[] => {

    const {currentPage, study} = props;

    if (!subjectRouteRegex.test(currentPage)) {
      return [];
    }

    const match = currentPage.match(subjectRouteRegex);
    const baseUrl = `/questionnaires/${match[1]}/${match[2]}/${match[3]}`;

    const navItems = [
      {
        name: getNavItemTranslation('subject.study'),
        path: baseUrl,
        iconSrc: iconTutkimus,
        iconActiveSrc: iconTutkimusActive
      },
      {
        name: getNavItemTranslation('subject.prerequisites'),
        path: `${baseUrl}/answer`,
        iconSrc: iconEsitiedot,
        iconActiveSrc: iconEsitiedotActive
      }
    ];

    if (!!study.extraInfo) {

      navItems.push({
        name: getNavItemTranslation('subject.additionalStudyInfo'),
        path: `${baseUrl}/extra-info`,
        iconSrc: iconLisatiedot,
        iconActiveSrc: iconLisatiedotActive
      });
    }

    return navItems;
  };

  const getNavItem = () => {
    return isPersonnel ? getPersonnelNavItems() : getSubjectNavItems();
  };

  return (
    <nav>
      <ul className={classnames(
        'navItems',
        {'navItems--subject': !isPersonnel},
        {'navItems--personnel': isPersonnel},
        className
      )}>
        {
          getNavItem().map(navItem => (
            <li key={navItem.path}>
              <NavItem type={type}
                active={isActiveMenuItem(navItem)}
                iconSrc={getIcon(navItem)}
                name={navItem.name}
                path={navItem.path}
                onClick={onToggleDrawer}/>
            </li>
          ))
        }
      </ul>
    </nav>
  );
};

NavItems.defaultProps = {
  type: 'static'
};

const mapStateToProps = ({routing, study}) => {
  const routingJS = routing.toJS();
  const currentPage = routingJS
      && routingJS.locationBeforeTransitions
      && routingJS.locationBeforeTransitions.pathname
      || '/';

  const adminSiteRegex = /\/admin/i;

  return {
    isPersonnel: adminSiteRegex.test(currentPage),
    currentPage,
    study
  };
};

export default connect<StateProps, {}, OwnProps>(
  mapStateToProps,
  null
)(NavItems);
