import * as React from 'react';
import {Component} from 'react';
import classNames from 'classnames';
import Input from '../Input/Input';
import {getFieldError} from '../../util';
import {ComponentProps, ComponentState} from '../Question';
import RoundButton from '../RoundButton/RoundButton';

const deleteIcon = require('../../images/delete.svg');

interface FormRulesNumberProps extends ComponentProps {
}

interface FormRulesNumberState extends ComponentState {
}

enum CapType {
  MIN = 'min',
  MAX = 'max'
}

export default class FormRulesNumber extends Component<FormRulesNumberProps, FormRulesNumberState> {
  getValues() {
    const {component} = this.props;
    const values = this.props.answer.getIn(['value', component.field]);

    return values ? values.toObject() : {min: null, max: null};
  }

  handleChange = (value, capType: CapType) => {
    const {answer, onChange} = this.props;
    const values = this.getValues();
    const newValues = {...values, [capType]: value};
    const newAnswerValue = answer.value.set(this.props.component.field, newValues);
    const newAnswer = answer.set('value', newAnswerValue);
    onChange(newAnswer);
  };

  handleBlur = (capType: CapType) => {
    const limitValue = this.props.component.getOptions()[capType];
    const values = this.getValues();
    const value = values[capType];

    const valueIsLowerThanDefaultMin = capType === CapType.MIN && value < limitValue && value !== null;
    const valueIsHigherThanDefaultMax = capType === CapType.MAX && value > limitValue && value !== null;

    if (valueIsLowerThanDefaultMin || valueIsHigherThanDefaultMax) {
      return this.handleChange(limitValue, capType);
    }

    const minValueIsNullTheOtherNot = capType === CapType.MIN && values[CapType.MAX] !== null && values[CapType.MIN] === null;
    if (minValueIsNullTheOtherNot) {
      return this.handleChange(limitValue, capType);
    }

    const maxValueIsNullTheOtherNot = capType === CapType.MAX && values[CapType.MAX] === null && values[CapType.MIN] !== null;
    if (maxValueIsNullTheOtherNot) {
      return this.handleChange(limitValue, capType);
    }

    const maxValueIsLowerThanOtherInput = capType === CapType.MAX && values[CapType.MIN] !== null && values[CapType.MAX] !== null && value < values[CapType.MIN];
    if (maxValueIsLowerThanOtherInput) {
      return this.handleChange(values[CapType.MIN], capType);
    }

    const minValueIsHigherThanOtherInput = capType === CapType.MIN && values[CapType.MAX] !== null && values[CapType.MIN] !== null && value > values[CapType.MAX];
    if (minValueIsHigherThanOtherInput) {
      return this.handleChange(values[CapType.MAX], capType);
    }
  };

  renderComponentOption = (
    capType: CapType,
    type,
    value,
    component,
    validationErrors,
    classes = ['col-4', 'col-sm-2']
  ) => {
    const {t, disabled} = this.props;
    const fieldError = getFieldError(`options.${capType}`, validationErrors, component.error);
    const groupClassName = classes ? classNames(classes) : undefined;
    const handleOptionChange = (value) => this.handleChange(value, capType);
    const handleBlur = () => this.handleBlur(capType);

    const {min, max} = this.props.component.getOptions();

    return (
      <Input
        rowLayout={false}
        className={`question-component-option-${capType}`}
        groupClassName={groupClassName}
        key={`input-question-component-option-${capType}`}
        error={fieldError}
        disabled={disabled}
        onChange={handleOptionChange}
        onBlur={handleBlur}
        value={value}
        type={type}
        min={min}
        max={max}
        label={t(`questionnaire.form.componentOption.${capType}`)}
        t={t}
      />
    );
  };

  renderOption = (capType: CapType, type) => {
    const {language, component} = this.props;
    const value = this.getValues()[capType];

    const validationErrors = component.validateByLanguage(language);
    return this.renderComponentOption(capType, type, value, component, validationErrors);
  };

  onClearRule = () => {
    const {answer, onChange, component} = this.props;
    const newAnswerValue = answer.value.set(component.field, {min: null, max: null});
    const newAnswer = answer.set('value', newAnswerValue);
    onChange(newAnswer);
  };

  render() {
    const {component, language, t} = this.props;
    const {min, max} = component.getOptions();
    const languageLocalization = component.getLanguageLocalization(language)
                                 || {label: '', unit: ''};
    const {label, unit} = languageLocalization;

    const getUnit = () => {
      if (unit) {
        return (<span>({unit})</span>);
      }
    };

    return (
      <div className='field-container form-rule'>
        {label} {getUnit()}
        <div className='number-input-field-container'>
          <div className='number-label-field'>
            <div className='range-label'>{t('questionnaire.form.setRange')}</div>
            <div className='range-number'>
              <div>{t('questionnaire.form.componentOption.min')}</div>
              <div>{min}</div>
            </div>
            <div className='range-number'>
              <div>{t('questionnaire.form.componentOption.max')}</div>
              <div>{max}</div>
            </div>
          </div>
          <div className='number-input-field'>
            <div className='range-label'>{t('questionnaire.form.eligibilityRange')}</div>
            {this.renderOption(CapType.MIN, 'number')}
            {this.renderOption(CapType.MAX, 'number')}
            <RoundButton
              className='button-delete-rule'
              iconSrc={deleteIcon}
              shape='circle'
              size='small'
              onClick={this.onClearRule}
            />
          </div>
        </div>
      </div>
    );
  };
}
