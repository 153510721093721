import QuestionComponent from '../../../../models/QuestionComponent';
import * as React from 'react';
import {PureComponent} from 'react';
import SelectOptionField from './SelectOptionField';
import * as _ from 'lodash';


interface SelectOptionFieldsProps {
  language: string;
  component: QuestionComponent;
  onChange: (question: QuestionComponent) => any;
  hasError?: boolean;
  t: (key, params?) => any;
}

interface SelectOptionFieldsState {}

export default class SelectOptionFields extends PureComponent<SelectOptionFieldsProps, SelectOptionFieldsState> {

  renderOption = (option, idx) => {
    const {language, t, onChange, component, hasError} = this.props;
    return (
      <SelectOptionField
        key={`select-option-field-${option ? option : `option${idx}`}`}
        option={option}
        language={language}
        component={component}
        idx={idx}
        onComponentChange={onChange}
        hasError={hasError}
        t={t}/>
    );
  };

  render() {
    const {component, t} = this.props;
    const options = component.getOptions() ? component.getOptions().slice() : [];
    options.push(''); // new option
    return (
      <div className='form-group'>
        <label className='form-label'>{t('questionnaire.form.selectOptions')}</label>
        {options.map(this.renderOption)}
      </div>
    );
  }
}