import ModelModule from './ModelModule';
import Questionnaire from '../models/Questionnaire';
import i18n from '../services/I18n';

const initialState = new Questionnaire();

export class QuestionnaireModule extends ModelModule<Questionnaire> {
  constructor() {
    super('questionnaire', initialState);
  }

  /**
   * Overridden to check and change application language if applicable.
   *
   * @param id
   * @param queryParams
   *
   * @override
   */
  public getModel(id, queryParams?) {
    return dispatch => {
      return dispatch(super.getModel(id, queryParams)).then(() =>
        dispatch(this.checkAndChangeAppLanguageIfApplicable())
      );
    };
  }

  private checkAndChangeAppLanguageIfApplicable() {

    return (_dispatch, getState) => {

      const {
        app: {availableLanguages},
        authenticatedUser: {authenticated},
        questionnaire
      } = getState();

      if (authenticated || !questionnaire.id) {

        return;
      }

      const language = questionnaire.getLanguageByPreference();

      const shouldChangeAppLanguage = availableLanguages.includes(language)
        && i18n.language !== language;

      if (shouldChangeAppLanguage) {

        i18n.changeLanguage(language);
      }
    };
  }
}

export default new QuestionnaireModule();
