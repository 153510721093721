import * as React from 'react';
import {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import './DateTimePicker.less';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const DateTimePicker = ({onChange, disabled, value}) => {

  const {width} = useWindowDimensions();
  const mobileWitdth = 400;
  return (
    <DatePicker onChange={onChange}
      popperPlacement='top-start'
      popperModifiers={{
        flip: {
          behavior: ['top'] // don't allow it to flip to be bottom
        },
        preventOverflow: {
          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
        },
        hide: {
          enabled: false // turn off since needs preventOverflow to be enabled
        }
      }}
      selected={value}
      disabled={disabled}
      showTimeSelect={true}
      withPortal={width < mobileWitdth}
      className='form-control'
      dateFormat='MM/dd/yyyy HH:mm'
      timeFormat='HH:mm'/>
  );
};

export default DateTimePicker;
