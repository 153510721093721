import {createAction, handleActions} from 'redux-actions';
import Download from '../models/Download';
import {get} from './ActionsCommon';
import Config from '../config';

const initialState = new Download();

const downloadPrefix = 'Download';

export const DOWNLOAD_REQUEST = `${downloadPrefix}.POLL_REQUEST`;
export const DOWNLOAD_SUCCESS = `${downloadPrefix}.POLL_SUCCESS`;
export const DOWNLOAD_FAILED = `${downloadPrefix}.POLL_FAILED`;

export const downloadRequest = createAction(DOWNLOAD_REQUEST);
export const downloadSuccess = createAction(DOWNLOAD_SUCCESS);
export const downloadFailed = createAction(DOWNLOAD_FAILED);

export function pollDownload(id) {
  return (dispatch) => {
    dispatch(downloadRequest());

    return dispatch(get('download', id, {status: true}))
      .then((response) => dispatch(downloadSuccess(response)))
      .catch((error) => dispatch(this.handleError(downloadFailed, error)));
  };
}

export function downloadResource(download) {
  return (_dispatch, getState) => {

    const {authenticatedUser: {accessToken}} = getState();
    const url = `${Config.API_URL}/download/${download.id}?access_token=${accessToken}`;

    window.location.replace(url);
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [DOWNLOAD_REQUEST]: (state) => state.startOfLoading(),
  [DOWNLOAD_SUCCESS]: (state, {payload}) => state.fromJS(payload),
  [DOWNLOAD_FAILED]: (state, {payload}) => state.endOfLoading(payload)
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
