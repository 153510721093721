import * as React from 'react';
import {Component} from 'react';
import {WithNamespaces, withNamespaces} from 'react-i18next';

const logo = require('../../images/logo.svg');
const pageNotFoundImg = require('../../images/Default-404.jpg');

interface Props extends WithNamespaces{
  t: (key, params?) => any;
  questionnaireNotFound?: boolean;
}

interface State {}

class PageNotFound extends Component<Props, State> {
  render() {
    const {t, questionnaireNotFound} = this.props;
    return (
      <div className='navigation-layout'>
        <div className='header notFoundHeader'>
          <img className='header__logo' src={logo} alt='CliniScout Recruit logo'/>
        </div>
        <div className='navigation-layout__body'>
          <div className='navigation-layout__content'>
            <img className='study-view__image' src={pageNotFoundImg}/>
            <div className='study-view__title'> { questionnaireNotFound ? t('questionnaire.form.questionnaireNotFoundTitle') : t('questionnaire.form.studyNotFoundTitle')} </div>
            <div className='study-view__content'><p>{ questionnaireNotFound ? t('questionnaire.form.questionnaireNotFoundDesc') : t('questionnaire.form.studyNotFoundDesc')}</p></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces(['subject'], {wait: true})((PageNotFound));
