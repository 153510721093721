/**
 * Created by Mauritz Untamala on 16/02/16.
 */
import * as React from 'react';
import {PureComponent} from 'react';

import * as changeCase from 'change-case';

import ModelInterface from '../models/ModelInterface';
import {bind} from '../util';

export interface BaseProps<M> {
  wrapperClass: string;
  title: boolean | string | object;

  modelId: number;
  model: M;
  modelName: string;
  getModel: (id) => any;
  resetModel: () => any;
  saveModel: (model) => any;

  editUrl: string;
  backHref: string;
  navigate: (url: string, silent?: boolean) => any;
  navigateBackOnSaved?: boolean;
  router: any;
  route: any;
  confirmUnsavedEntries: boolean;
  t: (key, params?) => any;
}

export interface BaseState<M> {
  model: M;
  modelWithoutChanges: M;
  backButtonDisabled: boolean;
}

export default abstract class ModelView<M extends ModelInterface<M>,
  P extends BaseProps<M>,
  S extends BaseState<M>> extends PureComponent<P, S> {

  protected constructor(props: P) {
    super(props);

    this.state = {
      model: props.model,
      modelWithoutChanges: props.model,
      backButtonDisabled: false
    } as S;

    bind(this, this.onModelSaved);
  }

  componentDidMount() {
    const {modelId, router, route, confirmUnsavedEntries} = this.props;

    const setupConfirm = router && route && confirmUnsavedEntries;
    if (setupConfirm) {

      router.setRouteLeaveHook(route, this.getConfirmUnsaved);
    }

    return this.fetchOrCreateModel(modelId);
  }

  componentDidUpdate(prevProps, _prevState) {
    const {modelId, model} = this.props;

    if (prevProps.modelId !== modelId) {
      return this.fetchOrCreateModel(modelId);
    }

    // Update state if id changes or if user is updated but it's not saving
    // (we want to avoid the jitter of model when updating
    // as the isSaving is set to true while saving)
    if (prevProps.model.id !== model.id || prevProps.model !== model) {
      let updatedModel = model;

      if (model.error) {
        updatedModel = this.state.model.endOfSaving(model.error);
      } else if (model.isSaving) {
        updatedModel = this.state.model.startOfSaving();
      }

      this.setState({model: updatedModel, modelWithoutChanges: updatedModel});
    }
  }

  fetchOrCreateModel = modelId => {
    if (modelId) {
      return this.props.getModel(modelId);
    } else {
      return this.props.resetModel();
    }
  };

  getTitle() {

    const {t, modelName} = this.props;

    return <h1 className='view-title'>{t(`${modelName}View.title`)}</h1>;
  }

  getRequiredFieldsMessage() {
    const {t, modelName} = this.props;
    if(modelName !== 'questionnaireAnswer') {
      return <h1 className='view-message'>{t('requiredFieldsMessage')}</h1>;
    }
  }

  content = () => {
    const {model, t, wrapperClass, modelName} = this.props;

    if (!model) {
      return <div key='model-loading'>{t('loading')}</div>;
    }

    const viewName = modelName + 'View';
    const className = wrapperClass ? wrapperClass : changeCase.paramCase(viewName);

    return (
      <div className={className}>
        {this.getTitle()}
        {this.getRequiredFieldsMessage()}
        {this.getContent()}
      </div>
    );
  };

  updateModel = (model, resetFormDirty = false) => resetFormDirty
    ? this.setState({model, modelWithoutChanges: model})
    : this.setState({model});

  save = () => this.props.saveModel(this.state.model);

  abstract getContent();

  isFormDirty = () => {
    if (this.state.modelWithoutChanges !== this.state.model) {
      return true;
    }

    return false;
  };

  getConfirmUnsaved = () => {

    const {t} = this.props;

    return this.isFormDirty() ? t('confirm.unsavedEntries') : null;
  };

  onModelSaved() {
    const {modelId, navigate, editUrl, navigateBackOnSaved, backHref} = this.props;
    const {model} = this.state;

    if(model.error && model.error['errorCode'] === 'validationFailed') {
      return;
    } else if (navigateBackOnSaved) {
      navigate(backHref);
      return;
    }

    if (!modelId && model.id && editUrl) {
      navigate(`${editUrl}/${model.id}`);
    }
  }

  render() {
    return this.content();
  }
}
