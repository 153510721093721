import ModelModule from './ModelModule';
import StudySite from '../models/StudySite';

const initialState = new StudySite();

export class StudySiteModule extends ModelModule<StudySite> {
  constructor() {
    super('study-site', initialState, 'studySite');
  }

  /**
   * @override
   */
  protected deleteSuccessMessageProps({siteName}) {
    return {name: siteName};
  }
}

export default new StudySiteModule();
