import {routerActions} from 'react-router-redux';
import {connectedRouterRedirect} from 'redux-auth-wrapper/history3/redirect';
import {LOGIN_URL} from './constants';

export const UserIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => !state.authenticatedUser.authenticated,
  authenticatingSelector: (state) => state.authenticatedUser.isLoading,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsNotAuthenticated'
});

export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: LOGIN_URL,
  authenticatedSelector: (state) => state.authenticatedUser.authenticated,
  authenticatingSelector: (state) => state.authenticatedUser.isLoading,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated'
});
