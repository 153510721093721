import ModelModule from './ModelModule';
import User from '../models/User';
import {add} from './ActionsCommon';
import {bind} from '../util';
import {createAction} from 'redux-actions';
import i18n from '../services/I18n';
import {info} from './Notifications';

const initialState = new User();

export class UserModule extends ModelModule<User> {
  private sendResetPasswordRequestType: string;
  private sendResetPasswordSuccessType: string;
  private sendResetPasswordFailedType: string;
  private sendResetPasswordRequestAction: any;
  private sendResetPasswordSuccessAction: any;
  private sendResetPasswordFailedAction: any;

  constructor() {
    super('user', initialState);
    bind(this, this.sendResetPassword);
  }

  public sendResetPassword(user) {
    return dispatch => {
      dispatch(this.sendResetPasswordRequestAction());

      return dispatch(add(this.path, [user.id, 'reset'], null, user))
        .then(response => {
          dispatch(this.sendResetPasswordSuccessAction(response));
          dispatch(info(i18n.t('users.resetPasswordWasSent')));

          return dispatch(this.getModel(user.id));
        })
        .catch(error => dispatch(this.handleError(this.sendResetPasswordFailedAction, error)));
    };
  }

  protected getAdditionalActionHandlers() {
    return {
      [this.sendResetPasswordRequestType]: state => state.startOfLoading(),
      [this.sendResetPasswordSuccessType]: state => state.endOfLoading(),
      [this.sendResetPasswordFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.resetModelType]: (_state, {payload}) => new User(payload)
    };
  }

  protected initializeTypes() {
    super.initializeTypes();

    this.sendResetPasswordRequestType = `${this.name}.SEND_RESET_PASSWORD_REQUEST`;
    this.sendResetPasswordSuccessType = `${this.name}.SEND_RESET_PASSWORD_SUCCESS`;
    this.sendResetPasswordFailedType = `${this.name}.SEND_RESET_PASSWORD_FAILED`;
  }

  protected initializeActions() {
    super.initializeActions();

    this.sendResetPasswordRequestAction = createAction(this.sendResetPasswordRequestType);
    this.sendResetPasswordSuccessAction = createAction(this.sendResetPasswordSuccessType);
    this.sendResetPasswordFailedAction = createAction(this.sendResetPasswordFailedType);
  }
}

export default new UserModule();
