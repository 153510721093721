import {List} from 'immutable';
import * as _ from 'lodash';
import i18n from '../services/I18n';
import BaseModel from './BaseModel';
import {Language} from './Language';
import {UserRole} from './UserRole';
import {UserStatus} from './UserStatus';

const forgotPasswordConstraints = {
  email: {
    email: true,
    presence: {allowEmpty: false}
  }
};

const personnelConstraints = {
  firstName: {
    presence: {allowEmpty: false}
  },
  lastName: {
    presence: {allowEmpty: false}
  },
  email: {
    presence: {allowEmpty: false},
    email: true
  },
  role: {
    presence: true,
    inclusion: UserRole.getValues()
  },
  password: {
    length: {
      minimum: 6,
      message: i18n.t('passwordValidation.tooShortPassword', {min: 6})
    }
  },
  password2: {
    presence(value, object) {
      return !value && object.password;
    },
    equality: {
      attribute: 'password',
      message: 'passwordMatchFailed'
    }
  }
};

const interestedKeys = [
  'username',
  'firstName',
  'lastName',
  'email',
  'role',
  'studyIds',
  'language',
  'accessToken'
];

const defaultValues = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,

  studyIds: List(),
  role: undefined,
  status: undefined,
  selectedStudyId: undefined,
  password: undefined,
  password2: undefined,

  passwordResetToken: undefined,
  passwordChanged: undefined,
  isValidatingToken: false,

  accessToken: undefined,
  authenticated: false,

  language: Language.en,

  account: undefined,
  forgotPasswordSuccess: undefined
};

export default class User extends BaseModel(defaultValues, interestedKeys, personnelConstraints)<User> {
  username: string;
  firstName: string;
  lastName: string;
  email: string;

  studyIds: List<number>;

  phone: string;
  role: UserRole;
  status: UserStatus;
  selectedStudyId: number;
  password: string;
  password2: string;

  passwordResetToken: String;
  passwordChanged: String;
  isValidatingToken: boolean;

  authenticated: boolean;
  accessToken: string;

  language: Language;

  forgotPasswordSuccess: boolean;

  constructor(js?) {
    super(js);

    return this.setListArray(['studyIds'], js) as User;
  }

  fromJS(js): User {
    return new User(js);
  }

  getDisplayName() {
    return `${this.firstName} ${this.lastName}`;
  }

  isPersonnel() {
    return true;
  }

  isAdmin() {
    return this.hasRole(UserRole.admin);
  }

  isUser() {
    return this.hasRole(UserRole.user);
  }

  hasRole(role: UserRole) {
    return this.role === role;
  }

  validatePasswordReset() {
    const constraints = personnelConstraints;
    const passwordResetConstraints = {passwordResetToken: {
      presence: {allowEmpty: false}
    },
    ..._.pick(constraints, 'password', 'password2')
    };

    return this._validate(passwordResetConstraints);
  }

  validateForgotPassword() {
    return this._validate(forgotPasswordConstraints);
  }

  isAuthenticated() {
    return this.get('authenticated');
  }

  setAuthenticated(authenticated) {
    return this.set('authenticated', authenticated) as User;
  }

  getLanguage() {
    return this.language;
  }

  setLanguage(language) {
    return this.set('language', language) as User;
  }

  startOfValidatingToken() {
    return this.set('isValidatingToken', true).set('error', null);
  }

  endOfValidatingToken(error?) {
    return this.set('isValidatingToken', false).set('error', error) as User;
  }

  setPasswordResetToken(token) {
    return this.set('passwordResetToken', token) as User;
  }

  setAccount(account: string) {
    return this.set('account', account) as User;
  }

  setForgotPasswordSuccess(forgotPasswordSuccess: boolean) {
    return this.set('forgotPasswordSuccess', forgotPasswordSuccess) as User;
  }

  startOfForgotPassword(account: string) {
    return this.setAccount(account).startOfLoading();
  }

  endOfForgotPassword(error?) {
    return error ? this.endOfLoading(error) : this.setForgotPasswordSuccess(true).endOfLoading(undefined);
  }
}
