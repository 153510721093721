import * as React from 'react';
import {FunctionComponent} from 'react';

import './AuthLayout.less';

interface Props {
  children: any;
}

const AuthLayout: FunctionComponent<Props> = (props: Props) => <div className='auth-layout'>{props.children}</div>;

export default AuthLayout;
