import * as React from 'react';
import * as _ from 'lodash';
import {Component} from 'react';
import {Form} from 'react-bootstrap';
import CheckInput from '../CheckInput/CheckInput';
import QuestionAnswer from '../../models/QuestionAnswer';
import {List} from 'immutable';
import {generateUUID} from '../../util';
import {ComponentProps, ComponentState, getError} from '../Question';

interface SelectProps extends ComponentProps {
  resettable?: boolean;
  allowMultiple?: boolean;
}

interface SelectState extends ComponentState {
  value: any;
}

export default class Select extends Component<SelectProps, SelectState> {

  constructor(props: SelectProps) {
    super(props);
    const {answer, component: {field}} = this.props;
    this.state = {
      value: answer.getFieldValue(field)
    };
  }

  render() {
    const {
      component,
      answer,
      fieldError,
      disabled,
      language,
      resettable,
      allowMultiple
    } = this.props;

    const {field, options} = component;
    const {value} = this.state;

    const renderOptions = () => {

      if (_.isEmpty(options)) {

        return null;
      }

      const localization = component.getLanguageLocalization(language);

      const update = option => {

        let updatedValue: any;

        if (allowMultiple) {

          if (value) {

            if (!value.includes(option)) {

              updatedValue = answer.value.set(field, value.push(option));
            } else {

              updatedValue = answer.value.set(field, value.delete(value.indexOf(option)));
            }
          } else {

            updatedValue = answer.value.set(field, List.of(option));
          }
        } else {

          updatedValue = answer.value.set(field, List.of(option));
        }

        const newAnswer = answer.set('value', updatedValue);

        this.props.onChange(newAnswer);

        this.setState({
          value: new QuestionAnswer(newAnswer).getFieldValue(field)
        });
      };

      return options.map(option => {

        const checkInputId = option + component._id;
        const name = allowMultiple ? `${field}--${generateUUID()}` : field;

        return (
          <CheckInput
            type='radio'
            disabled={disabled}
            key={option}
            id={checkInputId}
            name={name}
            value={option}
            checked={value ? value.includes(option) : false}
            hasError={!!fieldError}
            onChange={resettable ? () => { return; } : () => update(option)}
            onClick={resettable ? () => update(option) : undefined}
            label={localization[option]}
          />
        );
      });
    };

    return (
      <Form.Group className='select-input'>
        {renderOptions()}
        {getError(fieldError)}
      </Form.Group>
    );
  }
}